import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import "./FocusBarCandidate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import SidebarBtn from "../ui/SidebarBtn";
import logo from "./../assets/logo.png";

const renderTooltip = (props, text) => (
  <Tooltip id="button-tooltip" {...props}>
    {text}
  </Tooltip>
);

const FocusBarCandidate = ({
  isNewChat = false,
  isSidebarOpen,
  isTemporySidebarOpen,
}) => {
  const isMobile = window.innerWidth <= 768;
  const tooltipPlacement = isMobile ? "bottom" : "right";
  const location = useLocation();

  return (
    <div className="sidebar-FocusBarCandidate compact-sidebar">
      <div className="h-100 d-flex flex-column align-items-center">
        <div className="position-relative h-100 w-100">
          <div className="d-flex flex-column gap-2 align-items-center position-absolute top-0">
            <div className="text-center mb-3">
              <Link to="/candidate/connect-to-professionals">
                <img
                  src="/CareerGPT_Brand_Mark_Green.png"
                  alt="logo"
                  style={{ height: "35px", width: "35px" }}
                />
              </Link>
            </div>

            <SidebarBtn
              renderTooltipTest="Automatically apply to jobs that match your profile"
              to="/candidate/auto-apply"
              text=""
              iconeFolder="candidateSidebar"
              iconeName="robot.png"
              isSidebarOpen={false}
              isTemparySidebarOpen={true}
            />
            <SidebarBtn
              renderTooltipTest="Try Out Our AI-Powered Career Coach."
              to="/candidate/career-coach"
              text=""
              iconeFolder="candidateSidebar"
              iconeName="goal.png"
              isSidebarOpen={false}
              isTemparySidebarOpen={true}
            />
            <SidebarBtn
              renderTooltipTest="Directly connect with hundreds of recruiters and coaches on the CareerGPT platform."
              to="/candidate/connect-to-professionals"
              text=""
              iconeFolder="candidateSidebar"
              iconeName="connetToProf.png"
              isSidebarOpen={false}
              isTemparySidebarOpen={true}
            />
            <SidebarBtn
              renderTooltipTest="Coming Soon"
              text=""
              to="#"
              iconeFolder="candidateSidebar"
              iconeName="invoice-accounting-documet-chart.png"
              isSidebarOpen={false}
              isTemparySidebarOpen={true}
            />
            <SidebarBtn
              renderTooltipTest="Coming Soon"
              text=""
              to="#"
              iconeFolder="candidateSidebar"
              iconeName="resum-review.png"
              isSidebarOpen={false}
              isTemparySidebarOpen={true}
            />
            <SidebarBtn
              renderTooltipTest="Coming Soon"
              to="#"
              text=""
              iconeFolder="candidateSidebar"
              iconeName="jobSearch.png"
              isSidebarOpen={false}
              isTemparySidebarOpen={true}
            />
          </div>
          
          <div className="position-absolute bottom-0 w-100">
            <SidebarBtn
              to="/candidate/setting/profile"
              text=""
              iconeFolder="candidateSidebar"
              iconeName="settings.png"
              isSidebarOpen={false}
              isTemparySidebarOpen={true}
            />
            <SidebarBtn
              to="/candidate/help-and-support"
              text=""
              iconeFolder="candidateSidebar"
              iconeName="help.png"
              isSidebarOpen={false}
              isTemparySidebarOpen={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FocusBarCandidate;
