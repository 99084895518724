import {
  faEye,
  faEyeSlash,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../services/api.js";
import AuthButton from "../components/AuthButton.js";
import { Link } from "../components/common/index.js";
import "./../css/SignIn.css";
import Accordion from "react-bootstrap/Accordion";
import MobileCarousel from "../ui/MobileCarousel.jsx";
import linkedInLogo from "./../assets/linkedin.png"; // Import LinkedIn logo
import LinkedInAuth from "../components/LinkedInAuth";

const SignIn = ({ setUserType }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const source = axios.CancelToken.source();

  useEffect(() => {
    return () => {
      source.cancel("Component unmounted");
    };
  }, []);

  const validateForm = () => {
    let isValid = true;

    // Email validation
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Password validation
    if (password.length < 8) {
      setPasswordError("Password should be at least 8 characters long");
      isValid = false;
    } else {
      setPasswordError("");
    }

    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsPending(true);

    if (validateForm()) {
      try {
        const response = await axios.post(
          `${baseUrl}/auth/login`,
          {
            email: email,
            password: password,
          },
          {
            cancelToken: source.token,
          },
        );
        console.log(response.data);
        const err = response.data.error;

        if (!err) {
          const { token } = response.data;
          const { userType, id, name, isPaid, email } = response.data.user;

          // Store token in session storage
          sessionStorage.setItem("authToken", token);

          // Store user data in local storage
          localStorage.setItem("userType", userType);
          localStorage.setItem("userId", id);
          localStorage.setItem("userName", name);
          localStorage.setItem("userObject", JSON.stringify(response.data));

          window.pendo?.identify({
            visitor: {
              id: email,
              email: email,
              firstName: name?.split(" ")[0] ?? "",
              lastName: name?.split(" ")[1] ?? "",
              role: userType,
              paidOrTrialUser: isPaid
              // id: myData?.current_user?.id || 'default-visitor-id',
              // email: myData?.current_user?.email || 'email@example.com',
              // firstName: myData?.current_user?.firstName || 'First Name',
              // lastName: myData?.current_user?.last_name || '',
              // userPermissions: myData?.user_permissions || '',
              // role: myData?.current_user.role || '',
              // department: myData?.department || '',
              // paidOrTrialUser: myData?.paid_or_trial_user || '',
           },
          })

          if (userType === "recruiter") {
            navigate("/recruiter/peoplesearch");
            setUserType(userType);
            localStorage.setItem("isPaid", isPaid);
          } else if (userType === "coach") {
            navigate("/coach/connect-to-professionals");
            setUserType(userType);
            localStorage.setItem("isPaid", isPaid);
          } else {
            navigate("/candidate/auto-apply");
            setUserType(userType);
            localStorage.setItem("isPaid", isPaid);
          }

          setWrongPassword(false);
        } else {
          setWrongPassword(true);
          setLoginError(err);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 404) {
            setWrongPassword(true);
            setLoginError("Incorrect Email or Password");
          } else {
            setLoginError(error.response.data.error);
          }
        } else if (error.request) {
          setLoginError("Server error. Please try again later");
        } else {
          setLoginError("Error in setting up the request");
        }
      } finally {
        setIsPending(false);
      }
    } else {
      setIsPending(false);
    }
  };

  const handleChangeEmail = (event) => {
    setEmail(event);
    setEmailError("");
    setLoginError("");
    setPasswordError("");
  };

  const handleChangePassword = (event) => {
    setPassword(event);
    setPasswordError("");
    setLoginError("");
    setPasswordError("");
  };

  return (
    <>
      <div className="signin-container">
        <div className="d-flex flex-column w-100 overflow-hidden">
          <div className="flex-shrink-1">
            <div className="pb-2">
              <h4>Log in to your account</h4>
              <p className="fs-sm">Welcome back! Please enter your details</p>
            </div>
            {loginError && <div className="text-danger">{loginError}</div>}

            <LinkedInAuth logo={linkedInLogo} />

            <div className="separator">OR</div>
            <div className="mb-2">
              <div className="d-flex justify-content-between">
                <label className="fs-xs fw-medium" htmlFor="email">
                  Email Address
                </label>
                {emailError && (
                  <div style={{ color: "#F04438" }} className="fs-xs">
                    {emailError}
                  </div>
                )}
              </div>
              <input
                type="email"
                id="email"
                className="p-2 rounded-4 fs-sm w-100 shadow-none"
                value={email}
                onChange={(e) => handleChangeEmail(e.target.value)}
                placeholder="Enter your email address"
                style={{
                  border: emailError ? "1px solid #F04438" : "1px solid #DADBDA",
                }}
                onFocus={(e) => (e.target.style.border = "1px solid #9D9E9D")}
                onBlur={(e) => (e.target.style.border = "1px solid #DADBDA")}
              />
            </div>
            <div className="mb-3">
              <div className="d-flex justify-content-between">
                <label className="fs-xs fw-medium" htmlFor="password">
                  Enter Password
                </label>
                {passwordError && (
                  <div style={{ color: "#F04438" }} className="fs-xs">
                    {passwordError}
                  </div>
                )}
                {wrongPassword && (
                  <div className="invalid-feedback fs-xs">
                    Incorrect password. Please try again.
                  </div>
                )}
              </div>
              <div className="input-group mb-3 input-password">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className={`p-2 w-100 fs-sm ${
                    wrongPassword ? "is-invalid" : ""
                  } rounded-4`}
                  value={password}
                  onChange={(e) => handleChangePassword(e.target.value)}
                  placeholder="Enter your password"
                  style={{
                    border: passwordError
                      ? "1px solid #F04438"
                      : "1px solid #DADBDA",
                  }}
                  onFocus={(e) => (e.target.style.border = "1px solid #9D9E9D")}
                  onBlur={(e) => (e.target.style.border = "1px solid #DADBDA")}
                />
                <div className="input-group-append">
                  <span
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      right: "10px",
                      top: "7px",
                    }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-start align-item-center fs-sm">
              <Link to="/forgotpassword" label={"Forgot Password?"} />
            </div>

            <div className="text-center mt-4">
              <AuthButton
                label="Sign In"
                isLoading={isPending}
                onClick={handleSubmit}
                className="mb-2 p-2 w-100 rounded-4 btn-lg fs-6 fw-medium"
                style={{
                  background:
                    email === "" || password === "" ? "#F8F8F8" : "#29B475",
                  color: email === "" || password === "" ? "#000000" : "#ffffff",
                  border: "none",
                }}
              />
            </div>

            <p className="text-center mb-0">
              Don't have an account? <Link to="/signup" label={"Sign Up"} />
            </p>
          </div>

          {/* <div className="scroll-indicator">

            <FontAwesomeIcon icon={faArrowDown} />
          </div> */}
          <MobileCarousel />
          <div className="mt-5 flex-shrink-1 accordion-section">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  What makes this product special?
                </Accordion.Header>
                <Accordion.Body>
                  We are using state-of-the-art agentic AI to let you "set it and forget it" when it comes to different areas of the HR pipeline.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Is this product free?</Accordion.Header>
                <Accordion.Body>
                  We will be offering several free agentic AI queries initially. The Pro subscription will allow you to have an unlimited number of queries.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
