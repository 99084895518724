// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubscriptionPlan_planCard__rrbki {
  margin: 20px;
  /* position: relative; */
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.SubscriptionPlan_planCard__rrbki:hover {
  transform: translateY(-10px);
}

.SubscriptionPlan_currentStatus__U4\\+kS {
  background-color: #02101f;
}

.SubscriptionPlan_planHeader__5QT9p {
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
}

.SubscriptionPlan_planType__ubk-I {
  font-size: 1.5rem;
  font-weight: bold;
  display: block;
}

.SubscriptionPlan_price__EKS8a {
  font-size: 2.5rem;
  color: #333;
  margin: 10px 0;
}

.SubscriptionPlan_listItem__u1426 {
  text-align: center;
  padding: 15px;
  font-size: 1rem;
}

.SubscriptionPlan_planButton__K2U8E {
  width: 100%;
  padding: 15px;
  background-color: #007bff;
  border: none;
  color: white;
  font-size: 1.2rem;
  border-radius: 0 0 10px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  -webkit-transition: background-color 0.3s ease-in-out;
  -moz-transition: background-color 0.3s ease-in-out;
  -ms-transition: background-color 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out;
}

.SubscriptionPlan_planButton__K2U8E:hover {
  background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/css/SubscriptionPlan.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,wBAAwB;EACxB,YAAY;EACZ,mBAAmB;EACnB,wCAAwC;EACxC,gBAAgB;EAChB,sCAAsC;AACxC;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,4BAA4B;EAC5B,eAAe;EACf,6CAA6C;EAC7C,qDAAqD;EACrD,kDAAkD;EAClD,iDAAiD;EACjD,gDAAgD;AAClD;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".planCard {\n  margin: 20px;\n  /* position: relative; */\n  border: none;\n  border-radius: 10px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  overflow: hidden;\n  transition: transform 0.3s ease-in-out;\n}\n\n.planCard:hover {\n  transform: translateY(-10px);\n}\n\n.currentStatus {\n  background-color: #02101f;\n}\n\n.planHeader {\n  background-color: #f8f9fa;\n  padding: 20px;\n  text-align: center;\n}\n\n.planType {\n  font-size: 1.5rem;\n  font-weight: bold;\n  display: block;\n}\n\n.price {\n  font-size: 2.5rem;\n  color: #333;\n  margin: 10px 0;\n}\n\n.listItem {\n  text-align: center;\n  padding: 15px;\n  font-size: 1rem;\n}\n\n.planButton {\n  width: 100%;\n  padding: 15px;\n  background-color: #007bff;\n  border: none;\n  color: white;\n  font-size: 1.2rem;\n  border-radius: 0 0 10px 10px;\n  cursor: pointer;\n  transition: background-color 0.3s ease-in-out;\n  -webkit-transition: background-color 0.3s ease-in-out;\n  -moz-transition: background-color 0.3s ease-in-out;\n  -ms-transition: background-color 0.3s ease-in-out;\n  -o-transition: background-color 0.3s ease-in-out;\n}\n\n.planButton:hover {\n  background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"planCard": `SubscriptionPlan_planCard__rrbki`,
	"currentStatus": `SubscriptionPlan_currentStatus__U4+kS`,
	"planHeader": `SubscriptionPlan_planHeader__5QT9p`,
	"planType": `SubscriptionPlan_planType__ubk-I`,
	"price": `SubscriptionPlan_price__EKS8a`,
	"listItem": `SubscriptionPlan_listItem__u1426`,
	"planButton": `SubscriptionPlan_planButton__K2U8E`
};
export default ___CSS_LOADER_EXPORT___;
