// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip .tooltip-inner {
  background-color: gray;
  /* Choose your custom color here */
}

.container-fluid {
  /* background-color: #f5f5f5; */
}

.btn.btn-primary {
  background-color: #5fcf80;
  border-color: #5fcf80;
  color: #fff;
}

.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary:active {
  background-color: #4eb56a;
  /* Slightly darker shade for interaction */
  color: #fff;
}


@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

/*   
  .focus-bar-item:hover {
    animation: pulse 1s infinite;
  } */`, "",{"version":3,"sources":["webpack://./src/components/FocusBarRecruiter.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kCAAkC;AACpC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,WAAW;AACb;;AAEA;;;EAGE,yBAAyB;EACzB,0CAA0C;EAC1C,WAAW;AACb;;;AAGA;EACE;IACE,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;EACrB;AACF;;AAEA;;;KAGK","sourcesContent":[".tooltip .tooltip-inner {\n  background-color: gray;\n  /* Choose your custom color here */\n}\n\n.container-fluid {\n  /* background-color: #f5f5f5; */\n}\n\n.btn.btn-primary {\n  background-color: #5fcf80;\n  border-color: #5fcf80;\n  color: #fff;\n}\n\n.btn.btn-primary:hover,\n.btn.btn-primary:focus,\n.btn.btn-primary:active {\n  background-color: #4eb56a;\n  /* Slightly darker shade for interaction */\n  color: #fff;\n}\n\n\n@keyframes pulse {\n  0% {\n    transform: scale(1);\n  }\n\n  50% {\n    transform: scale(1.05);\n  }\n\n  100% {\n    transform: scale(1);\n  }\n}\n\n/*   \n  .focus-bar-item:hover {\n    animation: pulse 1s infinite;\n  } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
