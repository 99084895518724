import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { CircularProgress, DialogActions, DialogContent, Box } from "@mui/material";
//import type { ContactInfo } from "../types/contact_info"

// export interface SimpleDialogProps {
//   open: boolean
//   contactInfo: ContactInfo | {}
//   onClose: () => void
//   isLoading: boolean
// }

export default function ContactInfoDialog({
   open,
   contactInfo,
   onClose,
   isLoading,
}) {
   const hasContactInfo = () => {
      if (!contactInfo) return false;
      return Object.values(contactInfo).some(value => {
         if (Array.isArray(value)) {
            return value.length > 0;
         }
         return value != null && value !== '';
      });
   };

   const renderContactItem = (label, value) => {
      if (!value || (Array.isArray(value) && value.length === 0)) {
         return null;
      }
      
      const displayValue = Array.isArray(value) 
         ? value.map(item => item.url).filter(Boolean).join(', ')
         : value.toString();
         
      if (!displayValue) return null;
      
      return (
         <ListItem 
            sx={{
               py: 1.5,
               borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
               '&:last-child': {
                  borderBottom: 'none'
               }
            }}
         >
            <Typography 
               component="span" 
               sx={{ 
                  fontWeight: 600,
                  width: '80px',
                  color: 'text.secondary',
                  textTransform: 'capitalize'
               }}
            >
               {label}:
            </Typography>
            <Typography 
               component="span"
               sx={{ 
                  ml: 2,
                  color: 'text.primary',
                  wordBreak: 'break-word'
               }}
            >
               {displayValue}
            </Typography>
         </ListItem>
      );
   };

   return (
      <Dialog 
         open={open}
         maxWidth="sm"
         fullWidth
         PaperProps={{
            sx: {
               borderRadius: 2
            }
         }}
      >
         <DialogTitle sx={{ 
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            px: 3,
            py: 2
         }}>
            Contact Information
         </DialogTitle>
         <DialogContent sx={{ px: 3, py: 2 }}>
            {isLoading ? (
               <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  py: 4
               }}>
                  <CircularProgress color="primary" />
               </Box>
            ) : hasContactInfo() ? (
               <List disablePadding>
                  {renderContactItem('email', contactInfo?.email_address)}
                  {renderContactItem('phone', contactInfo?.phone_numbers)}
                  {renderContactItem('website', contactInfo?.websites)}
                  {renderContactItem('twitter', contactInfo?.twitter)}
                  {renderContactItem('ims', contactInfo?.ims)}
               </List>
            ) : (
               <Box sx={{ 
                  textAlign: 'center', 
                  py: 4,
                  color: 'text.secondary'
               }}>
                  <Typography variant="body1">
                     No contact information available
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                     This profile has not shared any contact details
                  </Typography>
               </Box>
            )}
         </DialogContent>
         <DialogActions sx={{ 
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
            px: 3,
            py: 2
         }}>
            <Button 
               onClick={onClose}
               variant="contained"
               sx={{
                  textTransform: 'none',
                  minWidth: '100px'
               }}
            >
               Close
            </Button>
         </DialogActions>
      </Dialog>
   );
}
