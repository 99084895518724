import React from 'react';
import { Typography, Box } from '@mui/material';
import { keyframes } from '@mui/system';

const slideIn = keyframes`
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
`;

interface SectionHeadingProps {
    title: string;
    subtitle?: string;
}

export default function SectionHeading({ title, subtitle }: SectionHeadingProps) {
    return (
        <Box
            sx={{
                textAlign: 'center',
                py: 5,
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    display: 'inline-block',
                    // '&::after': {
                    //     content: '""',
                    //     position: 'absolute',
                    //     bottom: '-12px',
                    //     left: '50%',
                    //     transform: 'translateX(-50%)',
                    //     width: '60%',
                    //     height: '3px',
                    //     backgroundColor: '#5fcf80',
                    //     animation: `${slideIn} 0.6s ease-out forwards`,
                    //     transformOrigin: 'center',
                    // },
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        bottom: '-12px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '40%',
                        height: '3px',
                        backgroundColor: 'rgba(95, 207, 128, 0.3)',
                    }
                }}
            >
                <Typography
                    variant="h2"
                    sx={{
                        fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
                        fontWeight: 600,
                        color: '#2D3436',
                        marginBottom: subtitle ? 2 : 0,
                        fontSize: { xs: '1.75rem', sm: '2.25rem', md: '2.75rem' },
                        letterSpacing: '-0.02em',
                        position: 'relative',
                    }}
                >
                    {title}
                </Typography>
            </Box>
            {subtitle && (
                <Typography
                    variant="h5"
                    sx={{
                        fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
                        color: '#636E72',
                        maxWidth: '800px',
                        margin: '0 auto',
                        mt: 4,
                        fontWeight: 400,
                        fontSize: { xs: '1rem', sm: '1.15rem', md: '1.25rem' },
                        letterSpacing: '-0.01em',
                    }}
                >
                    {subtitle}
                </Typography>
            )}
        </Box>
    );
} 
