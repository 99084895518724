import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Card, Row, Col, Badge } from "react-bootstrap";
// import MobileSideBarButton from "./MobileSideBarButton";

import Style from "./../css/candidateNavBar.module.css";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { baseUrl } from "../services/api";
import axios from "axios";
import NotificationBtn from "./NotificationBtn";
import { getNotification } from "../services/notificationApi";
import { useQuery } from "@tanstack/react-query";
import { useOutsideClick } from "../hooks/useOutSideClick";
import {
   getAllCoach,
   getAllAllCoach,
   getAllRecruiter,
   getAllcandidate,
   getMe,
} from "../services/candidateApi";
import Spinner from "./Spinner";
import NotificationComponent from "./NotificationComponent";
import MobileSideBarButton from "../components/MobileSideBarButton";
import CustomizedSpinner from "./CustomizedSpinner";

import MobileNotification from "./MobileNotification";
import { useGetMe } from "../hooks/useGetMe";
import { useGetAllCandidate } from "../hooks/useGetAllCandidate";
import { useGetAllRecruiter } from "../hooks/useGetAllRecruiter";
import { useGetAllCoach } from "../hooks/useGetAllCoach";
import { useSocket } from "../context/SocketContext";
import { useUpdateFirstSignIn } from "../hooks/useUpdateFirstSignIn";
import { updateUserFirstSignIn } from "../services/userApi";
import { toast } from "react-toastify";
import { FaCoins, FaCheckCircle, FaRocket, FaGem, FaLock } from "react-icons/fa";
import { useGetRecruiterMe } from "../hooks/useGetRecruiterMe";
// import { useSocket } from "../../provider/Socket.context";
// import getSocketInit from '../socket';

const UserNavbar = ({
   setIsSidebarOpen,
   isSidebarOpen,
   isTemparySidebarOpen,
   setIsTemparySidebarOpen,
}) => {
   const changeFavicon = (faviconPath) => {
      const link =
         document.querySelector("link[rel*='icon']") ||
         document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = faviconPath;
      document.getElementsByTagName("head")[0].appendChild(link);
   };

   const [showModal, setShowModal] = React.useState(false);
   const { socket } = useSocket();

   const [notificationData, setNotificationData] = useState([]);
   const [hasNotification, setHasNotification] = useState(false);

   const [isCreditsOpen, setIsCreditsOpen] = useState(false);
   const creditsRef = useOutsideClick(() => setIsCreditsOpen(false));
   const [showCreditsModal, setShowCreditsModal] = useState(false);
   const [showPricingModal, setShowPricingModal] = useState(false);

   const { data: notification, isPending: isNotification } = useQuery({
      queryKey: ["notification"],
      queryFn: getNotification,
   });

   const userType = localStorage.getItem("userType");

   const { myData: candidateData, isPending: candidateDataLoading } = useGetMe();
   const { myData: recruiterData, isPending: recruiterDataLoading } = useGetRecruiterMe();

   const myData = userType === "recruiter" ? recruiterData : candidateData;
   const mydataLoading = userType === "recruiter" ? recruiterDataLoading : candidateDataLoading;

   useEffect(() => {
      if (notification) {
         setNotificationData(notification);

         if (
            notification &&
            Array.isArray(notification) &&
            notification?.length > 0
         ) {
            const isClickNotify = notification.find((notify) => {
               if (!notify.isClick) {
                  // console.log("Notification clicked");
                  // document.title = "Notification";

                  setHasNotification(true);
                  return notify;
               }
            });
            // console.log("Is click notify", isClickNotify);

            if (isClickNotify) {
               // console.log("why");
               changeFavicon("/notify-favIcon.png");
            } else {
               changeFavicon("/favicon.png");
            }
         }
      }
   }, [notification]);

   useEffect(() => {
      const handleNotification = (data) => {
         // console.log("Notification data", data);
         // console.log("My data", myData);
         // console.log(+data.userId, myData?.id);
         if (+data.userId == myData?.id) {
            setNotificationData((notify) => [...notify, data]);
            setHasNotification(true);
            console.log("why");
            changeFavicon("/notify-favIcon.png");
         }
      };

      socket.on("notofication", handleNotification);

      return () => {
         socket.off("notofication", handleNotification);
      };
   }, [socket]);

   const [isMenuOpen, setIsMenuOpen] = React.useState(false);
   const navigate = useNavigate();
   const pathName = window.location.pathname;

   const [isProfileOpen, setIsProfileOpen] = React.useState(false);
   const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
   const [isMobileNotificationOpen, setIsMobileNotificationOpen] =
      React.useState(false);
   const handleSidebar = () => {
      setIsSidebarOpen((prev) => !prev);
   };

   const closeNotification = () => setIsNotificationOpen(false);
   const notificationRef = useOutsideClick(closeNotification);

   const closeSidebar = () => setIsMenuOpen(false);
   const sidebarRef = useOutsideClick(closeSidebar);

   const close = () => setIsProfileOpen(false);
   const menuRef = useOutsideClick(close);

   const { allCandidate } = useGetAllCandidate();

   const { recruiter, isPending: loadingRecruiter } = useGetAllRecruiter();

   const { allCoach, isPending: loadingallCoach } = useGetAllCoach();

   useEffect(() => {
      if (!myData?.firstSignIn) {
         setShowModal(false);
      }
   }, [myData?.firstSignIn]);

   if (mydataLoading) {
      return <CustomizedSpinner />;
   }
   if (loadingRecruiter) {
      return <CustomizedSpinner />;
   }

   if (loadingallCoach) {
      return <CustomizedSpinner />;
   }

   if (!pathName.startsWith(`/${myData.userType}/chat`)) {
      setIsTemparySidebarOpen(true);
   } else {
      setIsTemparySidebarOpen(false);
   }

   function togaleNavbarBtn() {
      setIsMenuOpen(!isMenuOpen);
   }

   const handleLogout = async () => {
      const token = sessionStorage.getItem("authToken");

      axios
         .post(
            `${baseUrl}/auth/logout`,
            {},
            {
               headers: {
                  Authorization: `Bearer ${token}`, // Retrieve the token from session storage
               },
            }
         )
         .then((response) => {
            // Remove token from session storage
            sessionStorage.removeItem("authToken");
            sessionStorage.removeItem("linkedinCredentials");
            sessionStorage.removeItem("resultsCache");
            sessionStorage.removeItem("sessionID");

            // Clear user data from local storage
            localStorage.removeItem("userType");
            localStorage.removeItem("userId");
            localStorage.removeItem("userName");
            localStorage.removeItem("userObject");
            localStorage.removeItem("isPaid");

            if (window.socket) {
               window.socket.disconnect();
            }

            // Navigate to the sign-in page using direct URL change
            window.location.href = "/signin";
         })
         .catch((error) => {
            console.error("Logout error:", error);
            toast.error("Logout failed");
         });
   };

   const handleplane = () => {
      navigate(`/${myData.userType}/subscription-plan`);
      setIsProfileOpen(false);
   };

   const handleProfile = () => {
      navigate(`/${myData.userType}/profile`);
      setIsProfileOpen(false);
   };

   const handleNews = () => {
      navigate(`/${myData.userType}/news`);
   };

   const handleChat = () => {
      setIsTemparySidebarOpen(false);
      navigate(`/${myData.userType}/chat`);
   };

   const handleNotification = () => {
      setIsNotificationOpen((isNotificationOpen) => !isNotificationOpen);
   };

   const handleNotificationMobile = () => {
      setIsMobileNotificationOpen((isNotificationOpen) => !isNotificationOpen);
   };

   async function handleClosebtn() {
      setShowModal(false);
      const update = await updateUserFirstSignIn();
   }

   const handleSuccess = async () => {
      const update = await updateUserFirstSignIn();
      navigate(`/${myData.userType}/subscription-plan`);
      setShowModal(false);
   };

   const handlePurchase = (coins, price) => {
      // Implement your payment logic here
      console.log(`Purchasing ${coins} coins for $${price}`);
      setShowPricingModal(false);
      // Add actual payment integration (Stripe, PayPal, etc.)
   };

   if (showModal) {
      return (
         <div className="text-center p-4">
            <h5>Welcome!</h5>
            <h5>Congratulations on signing in to your account!</h5>
            <p>You have a 7-day free trial to explore all our features.</p>
            <p>Upgrade to our Pro package for our premium features.</p>
            <Button
               variant="primary"
               onClick={handleSuccess}
               className="mt-3"
            >
               View Subscription Plans
            </Button>
         </div>
      );
   }

   return (
      <>
         <div
            className="d-flex justify-content-between align-items-center py-2 px-3"
            style={{
               backgroundColor: "#fff",
               borderRadius: "10px",
            }}
            ref={menuRef}
         >
            <button
               onClick={handleSidebar}
               className="border-0 d-lg-block d-none "
               style={{ backgroundColor: "#fff" }}
            >
               {isSidebarOpen && isTemparySidebarOpen ? (
                  <img
                     src={`/icons/candidateLayoutNav/window-resize-left.png`}
                     alt="menu collapse"
                     style={{ height: "24px", width: "24px" }}
                  />
               ) : (
                  <img
                     src={`/icons/candidateLayoutNav/Group.png`}
                     alt="menu expand"
                     style={{ height: "24px", width: "24px" }}
                  />
               )}
            </button>
            <div className="d-md-none gap-2 d-flex align-items-center">
               <button
                  onClick={togaleNavbarBtn}
                  className="border-0"
                  style={{ backgroundColor: "#fff" }}
               >
                  {" "}
                  <img
                     className="mb-2"
                     src="/icons/candidateLayoutNav/mobileResponsive.png"
                     alt="logo"
                     style={{ height: "12px", width: "18px" }}
                  />
               </button>
               <div>
                  <img
                     className="mb-2"
                     src="/CareerGPT_Brand_Mark_Green.png"
                     alt="logo"
                     style={{ height: "28px" }}
                  />
               </div>
            </div>
            <div className="d-flex gap-2">
               <div className="d-flex gap-2">
                  <div className="d-flex align-items-center gap-1">
                     <button
                        onClick={() => setShowPricingModal(true)}
                        className="d-flex align-items-center gap-1 border-0 bg-transparent"
                        style={{
                           padding: "8px 12px",
                           borderRadius: "8px",
                           transition: "all 0.3s ease"
                        }}
                     >
                        {/* Check if the user is a recruiter with a pro plan */}
                        {myData?.userType === 'recruiter' &&
                           (myData?.subscriptionPlan === 'pro' || myData?.isPaid === true || myData?.appsumo_lifetime_access === true) ? (
                           <>
                              <span style={{
                                 color: '#5fcf80', // Green color for unlimited
                                 fontWeight: '600',
                                 fontSize: '1.1rem',
                                 display: 'flex',
                                 alignItems: 'center',
                                 gap: '5px'
                              }}>
                                 <span style={{
                                    background: '#5fcf80',
                                    color: 'white',
                                    padding: '3px 8px',
                                    borderRadius: '12px',
                                    fontSize: '0.8rem',
                                    letterSpacing: '0.5px'
                                 }}>
                                    UNLIMITED
                                 </span>
                              </span>
                           </>
                        ) : (
                           <>
                              <FaCoins
                                 style={{
                                    color: '#FFD700',
                                    fontSize: '28px',
                                    filter: 'drop-shadow(0 0 8px rgba(255, 215, 0, 0.5))',
                                    transition: 'transform 0.3s ease'
                                 }}
                              />
                              <span style={{
                                 color: '#FFD700',
                                 fontWeight: '600',
                                 fontSize: '1.1rem',
                                 textShadow: '0 0 8px rgba(255, 215, 0, 0.3)'
                              }}>
                                 {myData?.userType === 'recruiter'
                                    ? (myData?.remainingSearchTokens !== undefined
                                       ? myData.remainingSearchTokens
                                       : 3)
                                    : (myData?.num_agentic_queries || 0)}
                              </span>
                           </>
                        )}
                     </button>
                  </div>
               </div>

               <div>
                  {pathName == `/${myData.userType}/chat` ? (
                     <button
                        onClick={handleChat}
                        className="border-0"
                        style={{ backgroundColor: "#fff" }}
                     >
                        <img
                           src={`/chatClick.png`}
                           alt="chat icon"
                           style={{
                              height: "24px",
                              width: "24px",
                              backgroundColor: "#fff",
                           }}
                        />
                     </button>
                  ) : (
                     <button
                        onClick={handleChat}
                        className="border-0"
                        style={{ backgroundColor: "#fff" }}
                     >
                        <img
                           src={`/icons/candidateLayoutNav/mailbox-line.png`}
                           alt="chat icon"
                           style={{
                              height: "24px",
                              width: "24px",
                              backgroundColor: "#fff",
                           }}
                        />
                     </button>
                  )}
               </div>

               <div>
                  {pathName == `/${myData.userType}/news` ? (
                     <button
                        onClick={handleNews}
                        className="border-0"
                        style={{ backgroundColor: "#fff" }}
                     >
                        <img
                           src={`/clickNews.png`}
                           alt="news icon"
                           style={{ height: "24px", width: "24px" }}
                        />
                     </button>
                  ) : (
                     <button
                        onClick={handleNews}
                        className="border-0"
                        style={{ backgroundColor: "#fff" }}
                     >
                        <img
                           src={`/icons/candidateLayoutNav/suitcase-work-job-document.png`}
                           alt="news icon"
                           style={{ height: "24px", width: "24px" }}
                        />
                     </button>
                  )}
               </div>

               <div ref={notificationRef} className="position-relative">
                  {isNotificationOpen ? (
                     <button
                        onClick={handleNotification}
                        className="border-0 d-md-block d-none"
                        style={{ backgroundColor: "#fff" }}
                     >
                        <img
                           src="/icons/candidateLayoutNav/app-clicknotification.png"
                           alt="notification icon"
                           style={{ height: "24px", width: "24px" }}
                        />
                     </button>
                  ) : (
                     <button
                        onClick={handleNotification}
                        className="border-0 d-md-block d-none"
                        style={{ backgroundColor: "#fff" }}
                     >
                        {hasNotification ? (
                           <img
                              src="/icons/candidateLayoutNav/app-has-notification.png"
                              alt="notification icon"
                              style={{ height: "24px", width: "24px" }}
                           />
                        ) : (
                           <img
                              src="/icons/candidateLayoutNav/app-notification.png"
                              alt="notification icon"
                              style={{ height: "24px", width: "24px" }}
                           />
                        )}
                     </button>
                  )}
                  
                  {isMobileNotificationOpen ? (
                     <button
                        onClick={handleNotificationMobile}
                        className="border-0 d-md-none d-block"
                        style={{ backgroundColor: "#fff" }}
                     >
                        <img
                           src="/icons/candidateLayoutNav/app-clicknotification.png"
                           alt="notification icon"
                           style={{ height: "24px", width: "24px" }}
                        />
                     </button>
                  ) : (
                     <button
                        onClick={handleNotificationMobile}
                        className="border-0 d-md-none d-block"
                        style={{ backgroundColor: "#fff" }}
                     >
                        <img
                           src="/icons/candidateLayoutNav/app-notification.png"
                           alt="notification icon"
                           style={{ height: "24px", width: "24px" }}
                        />
                     </button>
                  )}
                  
                  {isNotificationOpen && (
                     <NotificationComponent
                        notificationData={notificationData}
                        setNotificationData={setNotificationData}
                        setHasNotification={setHasNotification}
                     />
                  )}
                  {isMobileNotificationOpen && (
                     <MobileNotification
                        notificationData={notificationData}
                        setNotificationData={setNotificationData}
                        setHasNotification={setHasNotification}
                     />
                  )}
               </div>

               <div className="position-relative">
                  <button
                     style={{
                        backgroundColor: "#fff",
                        border: "none",
                        padding: "0",
                     }}
                     onClick={() => setIsProfileOpen(!isProfileOpen)}
                  >
                     <CandidateProfile image={myData.image} />
                  </button>
                  
                  {isProfileOpen && (
                     <div
                        style={{
                           position: "absolute",
                           top: "100%",
                           right: "0",
                           zIndex: "1000",
                           minWidth: "10rem",
                           padding: "0.5rem 0",
                           backgroundColor: "#fff",
                           borderRadius: "0.25rem",
                           boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
                        }}
                     >
                        <Dropdown.Item onClick={handleProfile}>
                           My Profile
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => navigate(`/${myData.userType}/subscription-plan`)}>
                           Subscription Tier
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                     </div>
                  )}
               </div>
            </div>
         </div>

         <div
            ref={sidebarRef}
            style={{
               backgroundColor: "#f5f4f4",
               animation: `${isMenuOpen ? Style.slideIn : Style.slideOut
                  } 0.5s forwards`,
            }}
            className={
               isMenuOpen
                  ? "z-3 position-fixed   right-0 top-0 w-75 d-md-none d-block h-100  p-2  "
                  : "position-fixed left-[100%] d-none top-0 p-10 "
            }
         >
            <button
               style={{
                  backgroundColor: "#f5f4f4",
                  color: "#F04438",
               }}
               className="border-0 mb-4"
               onClick={togaleNavbarBtn}
            >
               close
            </button>
            <div className="h-100 border-2 position-relative ">
               <div
                  style={{
                     justifyContent: "start",
                  }}
                  className="align-items-start  d-flex flex-column position-absolute top-0 "
               >
                  <div className="mobile-nav-container">
                     <div className="-content-center">
                        <Link to={`/${myData?.userType}/connect-to-professionals`}>
                           <img
                              className="mb-2"
                              src={`/icons/candidateLayoutNav/Group.png`}
                              alt="logo"
                              style={{ height: "28px" }}
                           />
                        </Link>
                     </div>

                     <MobileSideBarButton
                        renderTooltipTest="Automatically apply to jobs that match your profile"
                        to="/candidate/auto-apply"
                        text="Agentic AI Auto Apply"
                        iconeName="robot.png"
                        iconeFolder="candidateSidebar"
                        handleSidebar={togaleNavbarBtn}
                        isActive={pathName === "/candidate/auto-apply"}
                     />

                     <MobileSideBarButton
                        renderTooltipTest="Try Out Our AI-Powered Career Coach"
                        to="/candidate/career-coach"
                        text="AI-Powered Career Coach"
                        iconeName="goal.png"
                        iconeFolder="candidateSidebar"
                        handleSidebar={togaleNavbarBtn}
                        isActive={pathName === "/candidate/career-coach"}
                     />

                     <MobileSideBarButton
                        renderTooltipTest="Directly connect with hundreds of recruiters and coaches on the CareerGPT platform"
                        to="/candidate/connect-to-professionals"
                        text="Connect to Professionals"
                        iconeName="connetToProf.png"
                        iconeFolder="candidateSidebar"
                        handleSidebar={togaleNavbarBtn}
                        isActive={pathName === "/candidate/connect-to-professionals"}
                     />

                     <MobileSideBarButton
                        renderTooltipTest="Coming Soon"
                        to="#"
                        text="Salary Sensei"
                        iconeName="invoice-accounting-documet-chart.png"
                        iconeFolder="candidateSidebar"
                        handleSidebar={togaleNavbarBtn}
                        isActive={false}
                     />

                     <MobileSideBarButton
                        renderTooltipTest="Coming Soon"
                        to="#"
                        text="Resume Review"
                        iconeName="resum-review.png"
                        iconeFolder="candidateSidebar"
                        handleSidebar={togaleNavbarBtn}
                        isActive={false}
                     />

                     <MobileSideBarButton
                        renderTooltipTest="Coming Soon"
                        to="#"
                        text="AI Job Search"
                        iconeName="jobSearch.png"
                        iconeFolder="candidateSidebar"
                        handleSidebar={togaleNavbarBtn}
                        isActive={false}
                     />
                  </div>
               </div>

               <div className="position-absolute bottom-0 mb-5">
                  <div>
                     <MobileSideBarButton
                        renderTooltipTest="Profile!"
                        to={`/${myData.userType}/setting/profile`}
                        text="Settings"
                        iconeName="settings.png"
                        iconeFolder="candidateSidebar"
                        handleSidebar={togaleNavbarBtn}
                     />
                     <MobileSideBarButton
                        to={`/${myData.userType}/help-and-support`}
                        text="Help & Support"
                        iconeName="help.png"
                        iconeFolder="candidateSidebar"
                        handleSidebar={togaleNavbarBtn}
                     />
                  </div>
               </div>
            </div>
         </div>

         <Modal
            show={showPricingModal}
            onHide={() => setShowPricingModal(false)}
            centered
            size="lg"
            className="rounded-4"
         >
            <Modal.Header closeButton className="border-0 pb-0">
               <Modal.Title className="fw-bold fs-3 text-primary">
                  Boost Your Career Power
                  <div className="fs-6 text-muted mt-1">Choose your advantage</div>
               </Modal.Title>
            </Modal.Header>

            <Modal.Body>
               <Row className="g-4">
                  <Col md={4}>
                     <Card
                        className="h-100 shadow-hover border-primary-subtle rounded-4"
                        onMouseEnter={(e) => e.currentTarget.style.transform = 'translateY(-5px)'}
                        onMouseLeave={(e) => e.currentTarget.style.transform = 'translateY(0)'}
                        style={{ transition: 'all 0.3s ease' }}
                     >
                        <Card.Body className="text-center py-4">
                           <div className="mb-4">
                              <span className="h2 text-primary">Starter</span>
                              <div className="text-muted mt-2">Perfect for beginners</div>
                           </div>

                           <div className="display-4 fw-bold text-primary mb-3">$9</div>

                           <ul className="list-unstyled mb-4">
                              <li className="mb-3 d-flex align-items-center gap-2">
                                 <FaCheckCircle className="text-success" />
                                 <span>100 Career Coins</span>
                              </li>
                              <li className="mb-3 d-flex align-items-center gap-2">
                                 <FaCheckCircle className="text-success" />
                                 <span>Basic AI Features</span>
                              </li>
                              <li className="mb-3 d-flex align-items-center gap-2">
                                 <FaCheckCircle className="text-success" />
                                 <span>Email Support</span>
                              </li>
                           </ul>

                           <Button
                              variant="outline-primary"
                              size="lg"
                              className="w-100 rounded-pill fw-bold"
                              onClick={() => handlePurchase(100, 9)}
                           >
                              Get Started
                           </Button>
                        </Card.Body>
                     </Card>
                  </Col>

                  <Col md={4}>
                     <Card
                        className="h-100 shadow-lg border-warning rounded-4 position-relative"
                        style={{
                           borderWidth: '2px',
                           transform: 'scale(1.05)',
                           zIndex: 1,
                           transition: 'all 0.3s ease'
                        }}
                     >
                        <Badge
                           bg="warning"
                           className="position-absolute top-0 start-50 translate-middle rounded-pill px-3 py-2"
                           style={{ transform: 'translate(-50%, -50%)' }}
                        >
                           Most Popular
                        </Badge>

                        <Card.Body className="text-center py-4">
                           <div className="mb-4">
                              <span className="h2 text-warning">Career Pro</span>
                              <div className="text-muted mt-2">For serious job seekers</div>
                           </div>

                           <div className="display-4 fw-bold text-warning mb-3">$29</div>

                           <ul className="list-unstyled mb-4">
                              <li className="mb-3 d-flex align-items-center gap-2">
                                 <FaRocket className="text-warning" />
                                 <span>500 Career Coins</span>
                              </li>
                              <li className="mb-3 d-flex align-items-center gap-2">
                                 <FaRocket className="text-warning" />
                                 <span>Premium AI Tools</span>
                              </li>
                              <li className="mb-3 d-flex align-items-center gap-2">
                                 <FaRocket className="text-warning" />
                                 <span>Priority Support</span>
                              </li>
                              <li className="mb-3 d-flex align-items-center gap-2">
                                 <FaRocket className="text-warning" />
                                 <span>Resume Analytics</span>
                              </li>
                           </ul>

                           <Button
                              variant="warning"
                              size="lg"
                              className="w-100 rounded-pill fw-bold text-white"
                              onClick={() => handlePurchase(500, 29)}
                           >
                              Go Pro
                           </Button>
                        </Card.Body>
                     </Card>
                  </Col>

                  <Col md={4}>
                     <Card
                        className="h-100 shadow-hover border-success-subtle rounded-4"
                        onMouseEnter={(e) => e.currentTarget.style.transform = 'translateY(-5px)'}
                        onMouseLeave={(e) => e.currentTarget.style.transform = 'translateY(0)'}
                        style={{ transition: 'all 0.3s ease' }}
                     >
                        <Card.Body className="text-center py-4">
                           <div className="mb-4">
                              <span className="h2 text-success">Enterprise</span>
                              <div className="text-muted mt-2">For power users</div>
                           </div>

                           <div className="display-4 fw-bold text-success mb-3">$99</div>

                           <ul className="list-unstyled mb-4">
                              <li className="mb-3 d-flex align-items-center gap-2">
                                 <FaGem className="text-success" />
                                 <span>2000 Career Coins</span>
                              </li>
                              <li className="mb-3 d-flex align-items-center gap-2">
                                 <FaGem className="text-success" />
                                 <span>VIP Support</span>
                              </li>
                              <li className="mb-3 d-flex align-items-center gap-2">
                                 <FaGem className="text-success" />
                                 <span>Custom Solutions</span>
                              </li>
                              <li className="mb-3 d-flex align-items-center gap-2">
                                 <FaGem className="text-success" />
                                 <span>Dedicated Manager</span>
                              </li>
                           </ul>

                           <Button
                              variant="outline-success"
                              size="lg"
                              className="w-100 rounded-pill fw-bold"
                              onClick={() => handlePurchase(2000, 99)}
                           >
                              Contact Sales
                           </Button>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>

               <div className="text-center mt-4 text-muted">
                  <FaLock className="me-2" /> Secure payment processing
               </div>
            </Modal.Body>
         </Modal>
      </>
   );
};

export default UserNavbar;

function CandidateNavBarMiddleContent({ number, text, theme }) {
   const color =
      theme === "primary"
         ? "#29b475"
         : theme === "secondary"
            ? "#2E90FA"
            : theme === "success"
               ? "#F79009"
               : theme === "danger"
                  ? "#F04438"
                  : theme === "warning"
                     ? "#9E77ED"
                     : theme === "light"
                        ? "#F63D68"
                        : "#3B3D3C";
   return (
      <div
         className="d-flex   align-items-center gap-2"
      >
         <div
            className={`rounded-2 px-2  bg-${theme}-subtle `}
            style={{
               border: `2px solid ${color}`,

               color: color,
               borderColor: color,
            }}
         >
            {number}
         </div>
         <div className="fw-bold fs-6">{text}</div>
      </div>
   );
}

function CandidateProfile({ image }) {
   return (
      <button
         className="border-0 position-relative "
         style={{ backgroundColor: "#fff" }}
      >
         <img
            className="rounded-circle"
            src={image ? image : `/icons/candidateLayoutNav/user.png`}
            alt="salary information icon"
            style={{ height: "30px", width: "30px" }}
         />
         <div
            className="rounded-circle position-absolute bottom-0 end-0 "
            style={{ width: "16px", height: "16px", backgroundColor: "#fff" }}
         >
            <div
               className={`rounded-circle bg-primary`}
               style={{
                  width: "12px",
                  height: "12px",
                  margin: "2px",
               }}
            ></div>
         </div>
      </button>
   );
}
