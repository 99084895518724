import { SHA256, AES, lib, pad, enc } from "crypto-js";
import { COOKIE_HASH_KEY } from "../services/api";

export function formatTimeAgo(timestamp) {
  const timeDiff = Date.now() - new Date(timestamp).getTime();
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);

  if (months > 0) {
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
  }
}

export async function getToken() {
  let token = sessionStorage.getItem("authToken");

  for (let attempt = 0; attempt < 3 && !token; attempt++) {
    await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
    token = sessionStorage.getItem("authToken");
  }

  if (!token) return null;
  return token;
}



export async function encryptAndEncodePassword(password) {
   const secretKey = COOKIE_HASH_KEY // Ideally, derive a key securely

   // Generate a random 16-byte IV for encryption
   const iv = lib.WordArray.random(16);

   // Encrypt the password using AES-CBC mode with IV and PKCS7 padding
   const hashedKey = SHA256(secretKey); // Hash the key
   const encryptedPassword = AES.encrypt(password, hashedKey, {
      iv: iv,
      padding: pad.Pkcs7,
   });

   // Convert the IV and ciphertext to base64 strings separately
   const ivBase64 = iv.toString(enc.Base64);
   const encryptedPasswordBase64 = encryptedPassword.toString();

   const passObject = { iv: ivBase64, encryptedValue: encryptedPasswordBase64 }

   //encoding the object to base64
   const base64EncodedObject = btoa(JSON.stringify(passObject))
   
   return base64EncodedObject;
}
