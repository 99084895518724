import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Paper,
    Button,
    Chip,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Collapse,
    Grid2 as Grid,
    Divider,
    Tooltip,
    TextField,
    InputAdornment,
    Card,
    CardContent,
    CardActions,
    Link,
    Stack,
    Avatar,
    Alert,
    Snackbar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import {
    Add,
    Refresh,
    KeyboardArrowDown,
    KeyboardArrowUp,
    Visibility,
    Search,
    CalendarToday,
    LocationOn,
    Work,
    Business,
    AccessTime,
    Description,
    CheckCircle,
    Cancel,
    OpenInNew,
    ThumbUp,
    Today,
    InfoOutlined,
    Flag,
    ReportProblem
} from '@mui/icons-material';
import { format } from 'date-fns';
import { reportAutoApplyJobIncorrect } from '../../services/autoApply';

const statusColors = {
    completed: '#4caf50',
    in_progress: '#ff9800',
    failed: '#f44336',
    pending: '#2196f3'
};

const JobRow = ({
    job,
    onViewLiveClick,
    expanded,
    onToggleExpand,
    isLoading,
    onApiError,
    onSuccess
}) => {
    const [isReportLoading, setIsReportLoading] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [reportSuccess, setReportSuccess] = useState(false);

    // Get status color based on job status
    const getStatusColor = () => {
        if (job.status === true) return statusColors.completed;
        // if (job.status === false && job.session_id) return statusColors.in_progress;
        // return statusColors.pending;
        return statusColors.in_progress;
    };

    // Format creation date
    const formatDate = (dateString) => {
        try {
            return format(new Date(dateString), 'MMM dd, yyyy HH:mm');
        } catch (e) {
            return 'Invalid date';
        }
    };

    // Open confirmation dialog
    const handleOpenReportDialog = () => {
        setConfirmDialogOpen(true);
    };

    // Close confirmation dialog
    const handleCloseReportDialog = () => {
        setConfirmDialogOpen(false);
    };

    // Handler for reporting a job as incorrect
    const handleReportJobIncorrect = async () => {
        if (!job.report_id) return;
        
        setIsReportLoading(true);
        setConfirmDialogOpen(false);
        
        try {
            const result = await reportAutoApplyJobIncorrect(job.report_id);
            
            if (result.success) {
                setReportSuccess(true);
                onSuccess(result.message);
            } else {
                onApiError({ message: result.message });
            }
            
        } catch (error) {
            onApiError(error);
        } finally {
            setIsReportLoading(false);
        }
    };

    return (
        <>
            <TableRow
                sx={{
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                    borderLeft: `4px solid ${getStatusColor()}`,
                }}
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={onToggleExpand}
                    >
                        {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Typography variant="body2" fontWeight={500} sx={{ mb: 0.5 }}>
                        {job.search_query || 'N/A'}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        ID: {typeof job.report_id === 'string' ? job.report_id.substring(0, 8) : (job.report_id || 'N/A')}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <LocationOn fontSize="small" sx={{ mr: 0.5, color: 'text.secondary' }} />
                        <Typography variant="body2">
                            {job.search_location || 'N/A'}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell>
                    <Chip
                        label={job.status === true ? 'Completed' : 'In Progress'}
                        size="small"
                        sx={{
                            backgroundColor: getStatusColor(),
                            color: 'white',
                            fontWeight: 500
                        }}
                    />
                </TableCell>
                <TableCell>
                    <Typography variant="body2">
                        {formatDate(job.report_creation_date)}
                    </Typography>
                </TableCell>
                <TableCell align="right">
                    <Stack direction="row" spacing={1} justifyContent="flex-end">
                        <Tooltip title="Report job">
                            <IconButton
                                size="small"
                                color="warning"
                                onClick={handleOpenReportDialog}
                                disabled={isReportLoading || !job.report_id || reportSuccess}
                                sx={{ 
                                    border: '1px solid #ed6c02',
                                    borderRadius: '50%',
                                    p: '4px',
                                    opacity: reportSuccess ? 0.5 : 1
                                }}
                            >
                                {isReportLoading ? <CircularProgress size={20} /> : <Flag />}
                            </IconButton>
                        </Tooltip>
                        <Button
                            variant="outlined"
                            size="small"
                            startIcon={<Visibility />}
                            onClick={() => onViewLiveClick(job)}
                            disabled={(job.status && !job.session_id) || isLoading}
                            sx={{ borderRadius: '20px' }}
                        >
                            {isLoading ? 'Loading...' : 'View Details'}
                        </Button>
                    </Stack>
                </TableCell>
            </TableRow>

            {/* Expanded details row */}
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <Box sx={{ m: 2 }}>
                            <Typography variant="h6" gutterBottom component="div" sx={{ fontSize: '1rem', fontWeight: 500 }}>
                                Auto Apply Job Details
                            </Typography>

                            {/* Auto Apply Parameters - First Row */}
                            <Paper elevation={0} sx={{ p: 2, backgroundColor: '#f5f9ff', mb: 2 }}>
                                <Typography variant="subtitle2" sx={{ mb: 2, color: 'text.secondary' }}>
                                    Auto Apply Parameters
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Box>
                                            <Typography variant="caption" color="text.secondary">Job Apply Threshold:</Typography>
                                            <Typography variant="body2" fontWeight={500}>{job.job_apply_threshold.toString() ? `${job.job_apply_threshold}%` : 'N/A'}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Box>
                                            <Typography variant="caption" color="text.secondary">Jobs to Apply:</Typography>
                                            <Typography variant="body2" fontWeight={500}>{job.jobs_to_apply.toString() ? job.jobs_to_apply : 'N/A'}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Box>
                                            <Typography variant="caption" color="text.secondary">Location Type:</Typography>
                                            <Typography variant="body2" fontWeight={500}>
                                                {job.location_type?.join(', ') || 'N/A'}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Box>
                                            <Typography variant="caption" color="text.secondary">Resume Optimization:</Typography>
                                            <Typography variant="body2" fontWeight={500}>
                                                {job.resume_optimization ? 'Enabled' : 'Disabled'}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={6} md={4}>
                                        <Box>
                                            <Typography variant="caption" color="text.secondary">Created:</Typography>
                                            <Typography variant="body2" fontWeight={500}>{formatDate(job.report_creation_date)}</Typography>
                                        </Box>
                                    </Grid> */}
                                    {/* <Grid item xs={12} sm={6} md={4}>
                                        <Box>
                                            <Typography variant="caption" color="text.secondary">Last Updated:</Typography>
                                            <Typography variant="body2" fontWeight={500}>{formatDate(job.report_updation_date)}</Typography>
                                        </Box>
                                    </Grid> */}
                                </Grid>
                            </Paper>

                            {/* Job Application Stats - Second Row */}
                            <Paper elevation={0} sx={{ p: 2, backgroundColor: '#f5fff8' }}>
                                <Typography variant="subtitle2" sx={{ mb: 2, color: 'text.secondary' }}>
                                    Job Application Stats
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 1 }}>
                                            <Typography variant="caption" color="text.secondary">Total Jobs</Typography>
                                            <Typography variant="h6" fontWeight={500} sx={{ mt: 1 }}>
                                                {job.jobs?.length || 0}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 1 }}>
                                            <Typography variant="caption" color="text.secondary">Applied Jobs</Typography>
                                            <Typography variant="h6" fontWeight={500} color="success.main" sx={{ mt: 1 }}>
                                                {job.jobs?.filter(j => j.applied === true)?.length || 0}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 1 }}>
                                            <Typography variant="caption" color="text.secondary">Skipped Jobs</Typography>
                                            <Typography variant="h6" fontWeight={500} color="warning.main" sx={{ mt: 1 }}>
                                                {job.jobs?.filter(j => j.applied === false)?.length || 0}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>

                            {/* Information about where to find individual job applications */}
                            <Box sx={{ mt: 3, p: 2, backgroundColor: '#e3f2fd', borderRadius: 1 }}>
                                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                                    <InfoOutlined sx={{ mr: 1, color: 'primary.main' }} />
                                    To view individual job applications, please click the "View Details" button.
                                </Typography>
                            </Box>

                            {job.error_message && (
                                <Paper variant="outlined" sx={{ mt: 3, p: 3, backgroundColor: '#f5f5f5' }}>
                                    <Typography variant="h6" color="error" sx={{ mb: 2, fontWeight: 600 }}>
                                        Error Message
                                    </Typography>
                                    <Typography variant="body1" color="text.secondary">
                                        {job.error_message}
                                    </Typography>
                                </Paper>
                            )}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

            {/* Confirmation Dialog */}
            <Dialog
                open={confirmDialogOpen}
                onClose={handleCloseReportDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Report this job as incorrect?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to report this job as incorrect? This will help us improve our auto-apply system.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseReportDialog}>Cancel</Button>
                    <Button 
                        onClick={handleReportJobIncorrect} 
                        color="warning" 
                        autoFocus
                        startIcon={<ReportProblem />}
                    >
                        Report Job
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const ImprovedJobsGrid = ({
    reports = [],
    onCreateNewJob,
    onViewLiveJob,
    onRefresh,
    isLoading,
    lastUpdated
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [expandedRows, setExpandedRows] = useState({});
    const [apiError, setApiError] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

    useEffect(() => {
        // Show snackbar when an error occurs
        if (apiError) {
            setSnackbarOpen(true);
        }
    }, [apiError]);

    useEffect(() => {
        // Show success snackbar when a success message is set
        if (successMessage) {
            setSuccessSnackbarOpen(true);
        }
    }, [successMessage]);

    // Function to close the error snackbar
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    // Function to close the success snackbar
    const handleSuccessSnackbarClose = () => {
        setSuccessSnackbarOpen(false);
        setSuccessMessage(null);
    };

    // Function to set a success message
    const handleSuccess = (message) => {
        setSuccessMessage(message);
    };

    useEffect(() => {
        // Clear any api errors when component mounts or reports change
        setApiError(null);
    }, [reports]);

    // Function to handle API errors globally
    const handleApiError = (error) => {
        console.error('API Error:', error);
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            setApiError(`Error ${error.response.status}: ${error.response.data?.message || 'An error occurred'}`);
        } else if (error.request) {
            // The request was made but no response was received
            setApiError('No response received from server. Please check your connection.');
        } else {
            // Something happened in setting up the request that triggered an Error
            setApiError(`Error: ${error.message}`);
        }
    };

    const [expandedRow, setExpandedRow] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredReports, setFilteredReports] = useState(reports);

    // Update filtered reports when reports or search query changes
    useEffect(() => {
        if (searchQuery.trim() === '') {
            setFilteredReports(reports);
        } else {
            const query = searchQuery.toLowerCase();
            setFilteredReports(reports.filter(report =>
                (report.search_query?.toLowerCase().includes(query)) ||
                (report.search_location?.toLowerCase().includes(query)) ||
                (report.report_id?.toString().startsWith(query))
            ));
        }
    }, [reports, searchQuery]);

    const handleToggleExpand = (reportId) => {
        setExpandedRow(expandedRow === reportId ? null : reportId);
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            {/* Header */}
            <Box sx={{
                p: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#f8f8f8',
                borderBottom: '1px solid #e0e0e0'
            }}>
                <Typography variant="h6" sx={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                    <Work sx={{ mr: 1 }} />
                    Auto Apply Jobs
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {lastUpdated && (
                        <Tooltip title="Last updated time">
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mr: 2,
                                typography: 'body2',
                                color: 'text.secondary'
                            }}>
                                <CalendarToday fontSize="small" sx={{ mr: 0.5 }} />
                                {lastUpdated}
                            </Box>
                        </Tooltip>
                    )}

                    <TextField
                        size="small"
                        placeholder="Search jobs..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        sx={{ mr: 2, width: '200px' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search fontSize="small" />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Button
                        variant="outlined"
                        onClick={onRefresh}
                        startIcon={<Refresh />}
                        disabled={isLoading}
                        sx={{ mr: 1, borderRadius: '20px' }}
                    >
                        Refresh
                    </Button>

                    <Button
                        variant="contained"
                        onClick={onCreateNewJob}
                        startIcon={<Add />}
                        sx={{
                            borderRadius: '20px',
                            backgroundColor: '#5fcf80',
                            '&:hover': {
                                backgroundColor: '#56ba73',
                            }
                        }}
                    >
                        New Job
                    </Button>
                </Box>
            </Box>

            {/* Loading Indicator */}
            {isLoading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                    <CircularProgress size={40} sx={{ color: '#5fcf80' }} />
                </Box>
            )}

            {/* No data message */}
            {!isLoading && filteredReports.length === 0 && (
                <Box sx={{
                    py: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Typography variant="h6" sx={{ mb: 2, color: 'text.secondary' }}>
                        No auto apply jobs found
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={onCreateNewJob}
                        startIcon={<Add />}
                        sx={{
                            mt: 1,
                            borderRadius: '20px',
                            backgroundColor: '#5fcf80',
                            '&:hover': {
                                backgroundColor: '#56ba73',
                            }
                        }}
                    >
                        Create Your First Job
                    </Button>
                </Box>
            )}

            {/* Jobs table */}
            {!isLoading && filteredReports.length > 0 && (
                <TableContainer sx={{ maxHeight: 'calc(100vh - 200px)' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell width="50px" />
                                <TableCell>Job Search</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Created</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredReports.map((report) => (
                                <JobRow
                                    key={report.report_id}
                                    job={report}
                                    onViewLiveClick={() => onViewLiveJob(report)}
                                    expanded={expandedRow === report.report_id}
                                    onToggleExpand={() => handleToggleExpand(report.report_id)}
                                    isLoading={isLoading}
                                    onApiError={handleApiError}
                                    onSuccess={handleSuccess}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {/* Error Snackbar */}
            <Snackbar 
                open={snackbarOpen} 
                autoHideDuration={6000} 
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert 
                    onClose={handleSnackbarClose} 
                    severity="error" 
                    sx={{ width: '100%' }}
                >
                    {apiError}
                </Alert>
            </Snackbar>
            
            {/* Success Snackbar */}
            <Snackbar 
                open={successSnackbarOpen} 
                autoHideDuration={4000} 
                onClose={handleSuccessSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert 
                    onClose={handleSuccessSnackbarClose} 
                    severity="success" 
                    sx={{ width: '100%' }}
                >
                    {successMessage}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default ImprovedJobsGrid; 
