import { useState, useCallback } from "react";
import AuthButton from "../components/AuthButton";
import { Link } from "../components/common";
import { toast } from "react-toastify";
import { baseUrl } from "../services/api";
import axios from "axios";
import { sub } from "date-fns";



function SupportAndFeedback() {

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [feedback, setFeedback] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [feedbackError, setFeedbackError] = useState("");
  const [submitting, setSubmitting] = useState(false);



  const validateForm = useCallback(() => {
    let isValid = true;
    // Name validation
    if (name.trim() === "") {
      setNameError("Name cannot be empty");
      isValid = false;
    } else {
      setNameError("");
    }

    // Email validation
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (feedback == "") {
      setFeedbackError("Feedback cannot be empty");
      isValid = false;
      console.log(feedback);
    }
    else {
      setFeedbackError("");
    }



    return isValid;
  }, [name, email, feedback]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    const token = sessionStorage.getItem("authToken");

    if (validateForm()) {
      try {
        const res = await axios.post(`${baseUrl}/feedback`, {
          name: name,
          email: email,
          feedback: feedback
        },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });

        // Log the response to see its structure
        console.log("Feedback API response:", res);

        // Assume success if we get a response without throwing an error
        toast.success("Feedback submitted successfully");
        setEmail("");
        setName("");
        setFeedback("");
      }
      catch (err) {
        console.error("Error submitting feedback:", err);
        toast.error("Feedback submission failed");
      }
      finally {
        setSubmitting(false);
      }
    } else {
      setSubmitting(false);
    }
  };

  const handleEmailChange = (email) => {
    setEmail(email);
    setEmailError("");
  }
  const handleNameChange = (name) => {
    setName(name);
    setNameError("");
  }
  const handleFeedbackChange = (feedback) => {
    setFeedback(feedback);
    setFeedbackError("");
  }




  return (

    <div className="py-5  col-sm-8 "
    >

      <h3>We'll get back to you as soon as possible!</h3>
      {/* <p className="fs-sm">Start your 30-days free trial</p> */}
      <div className="mb-3">
        <div className="d-flex justify-content-between">
          <label
            style={{ marginBottom: "8px" }}
            className="fs-xs fw-medium"
            htmlFor="email"
          >
            Full Name
          </label>
          {nameError && <div className="text-danger fs-xs">{nameError}</div>}
        </div>
        <input
          type="text"
          id="name"
          className={` ${nameError ? "is-invalid" : ""
            } shadow-none w-100 p-2 rounded-4 fs-sm`}
          value={name}
          onChange={(e) => handleNameChange(e.target.value)}
          placeholder="Enter your full name"
          onFocus={(e) => e.target.style.border = '1px solid #9D9E9D'}
          onBlur={(e) => e.target.style.border = '1px solid #DADBDA'}
          style={nameError ? {
            outline: 'none',
            border: `1px solid #F04438`
          } : {
            outline: 'none',
            border: `1px solid #DADBDA`
          }}

        />

      </div>
      <div className="mb-3">
        <div className="d-flex justify-content-between">
          <label
            style={{ marginBottom: "8px" }}
            className="fs-xs fw-medium"
            htmlFor="email"
          >
            Email Address
          </label>
          {emailError && <div style={{ color: '#F04438' }} className="fs-xs">{emailError}</div>}
        </div>
        <input
          type="email"
          id="email"
          className={` ${emailError ? "is-invalid" : ""
            } shadow-none p-2 w-100 rounded-4 fs-sm`}
          value={email}
          onChange={(e) => handleEmailChange(e.target.value)}
          placeholder="Enter your email address"
          onFocus={(e) => e.target.style.border = '1px solid #9D9E9D'}
          onBlur={(e) => e.target.style.border = '1px solid #DADBDA'}

          style={emailError ? {
            outline: 'none',
            border: `1px solid #F04438`
          } : {
            outline: 'none',
            border: `1px solid #DADBDA`
          }}
        />

      </div>

      <div className="mb-3">
        <div className="d-flex justify-content-between">
          <label
            style={{ marginBottom: "8px" }}
            className="fs-xs fw-medium"
            htmlFor="email"
          >
            Question
          </label>
          {feedbackError && <div style={{ color: '#F04438' }} className="fs-xs">{feedbackError}</div>}
        </div>
        <textarea
          name="feedback"
          rows={5}
          cols={50}
          id="feedback"
          className={` ${feedbackError ? "is-invalid" : ""
            } shadow-none p-2 w-100 rounded-4 fs-sm`}
          value={feedback}
          onChange={(e) => handleFeedbackChange(e.target.value)}
          placeholder="Enter your question here "
          onFocus={(e) => e.target.style.border = '1px solid #9D9E9D'}
          onBlur={(e) => e.target.style.border = '1px solid #DADBDA'}

          style={feedbackError ? {
            outline: 'none',
            border: `1px solid #F04438`
          } : {
            outline: 'none',
            border: `1px solid #DADBDA`
          }}
        />

      </div>

      <div className="text-center">
        <AuthButton
          label="Submit question"
          onClick={handleSubmit}
          isLoading={submitting}
          className="btn btn-info mb-3 w-100 rounded-4 btn-lg fs-6 fw-medium"
          style={{

            background: email === "" || name === "" || feedback === "" ? "#F8F8F8" : "#29B475",
            color: email === "" || name === "" || feedback === "" ? "#000000" : "#ffffff",
            border: 'none',
          }}
        />
      </div>


      {/* Copyright information */}
    </div>
  )
}

export default SupportAndFeedback
