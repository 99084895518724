import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import "./FocusBarRecruiter.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "./../assets/logo.png";
import SidebarBtn from "../ui/SidebarBtn";

const renderTooltip = (props, text) => (
   <Tooltip id="button-tooltip" {...props}>
      {text}
   </Tooltip>
);

const FocusBarRecruiter = ({
   isNewChat = false,
   isSidebarOpen,
   isTemparySidebarOpen,
}) => {
   const isMobile = window.innerWidth <= 768; // Set your mobile breakpoint here
   const tooltipPlacement = isMobile ? "bottom" : "right";
   const location = useLocation();

   return (
      <div className="sidebar-FocusBarCandidate mx-xl-2 mx-lg-1">
         <div
            className={`h-100 align-items-md-center ${
               isSidebarOpen && isTemparySidebarOpen
                  ? "align-items-start align-items-lg-start align-items-md-center"
                  : "align-items-center"
            } d-flex flex-column`}
         >
            <div className="position-relative h-100 w-100">
               <div className="d-flex gap-2 flex-column align-items-center position-absolute top-0">
                  <div className="-content-center">
                     <Link to="/recruiter/connect-to-professionals">
                        <img
                           className="mb-2"
                           src={logo}
                           alt="logo"
                           style={{ height: "45px" }}
                        />
                     </Link>
                  </div>
                  <SidebarBtn
                     renderTooltipTest="Use the AI-based people search to find the right candidate for your job opening."
                     iconeFolder="recruiterSidebar"
                     to="/recruiter/peoplesearch"
                     text="People Search"
                     iconeName="peopleSearch.png"
                     isSidebarOpen={isSidebarOpen}
                     isTemparySidebarOpen={isTemparySidebarOpen}
                  />
                  <SidebarBtn
                     renderTooltipTest="Connect with other users on the CareerGPT platform."
                     to="/recruiter/connect-to-professionals"
                     text="Connect to Professionals"
                     iconeName="conectPro.png"
                     iconeFolder="recruiterSidebar"
                     isSidebarOpen={isSidebarOpen}
                     isTemparySidebarOpen={isTemparySidebarOpen}
                  />
                  <SidebarBtn
                     renderTooltipTest="Tell us about the position you are recruiting for and upload the candidate's resume. We'll analyze job market data and the candidate's skills to provide a salary estimate."
                     to="/recruiter/salary"
                     text="Salary Sensei"
                     iconeName="invoice-accounting-documet-chart.png"
                     iconeFolder="recruiterSidebar"
                     isSidebarOpen={isSidebarOpen}
                     isTemparySidebarOpen={isTemparySidebarOpen}
                  />
                  <SidebarBtn
                     renderTooltipTest="Returns the best matching resume for the job description you have input based on a similarity score."
                     to="/recruiter/resumeranking"
                     text="Resume Ranker"
                     iconeName="resumeRanking.png"
                     iconeFolder="recruiterSidebar"
                     isSidebarOpen={isSidebarOpen}
                     isTemparySidebarOpen={isTemparySidebarOpen}
                  />
                  <SidebarBtn
                     renderTooltipTest="Fine-tune a job description to include the best keywords to attract the best candidates."
                     to="/recruiter/jobposting"
                     text="Refine Job Description"
                     iconeName="jobPosting.png"
                     iconeFolder="recruiterSidebar"
                     isSidebarOpen={isSidebarOpen}
                     isTemparySidebarOpen={isTemparySidebarOpen}
                  />
                  <SidebarBtn
                     renderTooltipTest="Upload the candidate's resume and let our AI coach analyze his/her skills and experience to provide actionable interview guidance."
                     iconeFolder="recruiterSidebar"
                     to="/recruiter/interview"
                     text="Interview Preparation Assistance"
                     iconeName="interviewPrep.png"
                     isSidebarOpen={isSidebarOpen}
                     isTemparySidebarOpen={isTemparySidebarOpen}
                  />
               </div>
               <div className="position-absolute bottom-0">
                  <SidebarBtn
                     to="/recruiter/setting/profile"
                     text="Settings"
                     iconeName="settings.png"
                     iconeFolder="candidateSidebar"
                     isSidebarOpen={isSidebarOpen}
                     isTemparySidebarOpen={isTemparySidebarOpen}
                  />
                  <SidebarBtn
                     to="/recruiter/help-and-support"
                     text="Help & Support"
                     iconeName="help.png"
                     iconeFolder="candidateSidebar"
                     isSidebarOpen={isSidebarOpen}
                     isTemparySidebarOpen={isTemparySidebarOpen}
                  />
               </div>
            </div>
         </div>
      </div>
   );
};

export default FocusBarRecruiter;
