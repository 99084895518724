import React, { useState } from "react";
import { Grid2, Typography, Button, Stack, Box, Modal, TextField } from "@mui/material";
import { keyframes } from '@mui/system';
import { toast } from 'react-toastify';

// Form animation
const formFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export default function HeroSection() {
   const [openInterestForm, setOpenInterestForm] = useState(false);
   const [email, setEmail] = useState("");
   const [submitting, setSubmitting] = useState(false);
   const [submitted, setSubmitted] = useState(false);

   const handleOpenForm = () => setOpenInterestForm(true);
   const handleCloseForm = () => setOpenInterestForm(false);

   const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      if (!email || !email.includes('@')) {
         toast.error("Please enter a valid email address");
         return;
      }

      setSubmitting(true);

      try {
         // Using Formspree as the third-party form service
         // Replace YOUR_FORM_ID with the actual form ID from Formspree
         const response = await fetch("https://formspree.io/f/xdkaqwlo", {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
            },
            body: JSON.stringify({
               email,
               source: "CareerGPT Hero Section",
               date: new Date().toISOString(),
            }),
         });

         if (response.ok) {
            setSubmitted(true);
            toast.success("Thank you for your interest! We'll keep you updated.");
            setTimeout(() => {
               handleCloseForm();
               // Reset form after closing
               setTimeout(() => {
                  setSubmitted(false);
                  setEmail("");
               }, 300);
            }, 2000);
         } else {
            toast.error("There was a problem submitting your interest. Please try again.");
         }
      } catch (error) {
         console.error("Error submitting form:", error);
         toast.error("There was a problem submitting your interest. Please try again.");
      } finally {
         setSubmitting(false);
      }
   };

   return (
      <Grid2
         container
         sx={{
            position: "relative",
            minHeight: "70vh",
            py: 3,
            zIndex: 2,
            alignItems: "center",
            justifyContent: "center",
         }}
         data-section="hero"
      >
         <Stack
            spacing={4}
            alignItems="center"
            sx={{
               width: "100%",
               maxWidth: "800px",
               animation: `${fadeIn} 1s ease-out`,
               marginTop: "15vh"
            }}
         >
            <Typography
               variant="h1"
               component="h1"
               sx={{
                  color: "#effaf2",
                  textAlign: "center",
                  fontWeight: "800",
                  fontSize: { xs: "2.5rem", sm: "3.5rem", md: "4.5rem" },
                  lineHeight: 1.2,
                  marginBottom: 2,
                  textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
                  "& .highlight": {
                     background: "linear-gradient(90deg, #5fcf80, #3da757, #5fcf80)",
                     backgroundSize: "200% 100%",
                     animation: `${gradientAnimation} 3s ease infinite`,
                     WebkitBackgroundClip: "text",
                     WebkitTextFillColor: "transparent",
                     display: "inline-block",
                  }
               }}
            >
               Applying to Jobs{" "}
               <span className="highlight">Sucks.</span>
               <br />
               Let Us Do It For You
            </Typography>

            <Typography
               variant="h5"
               sx={{
                  color: "#effaf2",
                  textAlign: "center",
                  opacity: 0.9,
                  maxWidth: "600px",
                  mx: "auto",
                  mb: 4,
                  animation: `${fadeIn} 1s ease-out 0.5s both`,
               }}
            >
               (see demo below)
            </Typography>

            <Stack
               direction={{ xs: "column", sm: "row" }}
               spacing={2}
               sx={{
                  animation: `${fadeIn} 1s ease-out 1s both`,
               }}
               alignItems="center"
            >
               <Button
                  variant="contained"
                  onClick={handleOpenForm}
                  data-action="join-waiting-list"
                  sx={{
                     borderRadius: "50px",
                     padding: "12px 32px",
                     fontSize: "1.1rem",
                     fontWeight: "600",
                     backgroundColor: "#5fcf80",
                     boxShadow: "0 4px 14px 0 rgba(95, 207, 128, 0.4)",
                     "&:hover": {
                        backgroundColor: "#56ba73",
                        transform: "translateY(-2px)",
                        boxShadow: "0 6px 20px rgba(95, 207, 128, 0.4)",
                     },
                     transition: "all 0.3s ease",
                  }}
               >
                  Join Waiting List
               </Button>
            </Stack>
         </Stack>

         {/* Interest Form Modal */}
         <Modal
            open={openInterestForm}
            onClose={handleCloseForm}
            aria-labelledby="interest-form-modal"
            aria-describedby="express-interest-in-careergpt"
         >
            <Box
               sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: { xs: '90%', sm: '450px' },
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,
                  borderRadius: 2,
                  animation: `${formFadeIn} 0.3s ease-out`,
               }}
            >
               {!submitted ? (
                  <>
                     <Typography variant="h5" component="h2" sx={{ mb: 2, fontWeight: 600 }}>
                        Join Our Waiting List
                     </Typography>
                     <Typography variant="body1" sx={{ mb: 3 }}>
                        Enter your email to be notified when CareerGPT launches.
                     </Typography>
                     <form onSubmit={handleSubmit}>
                        <TextField
                           fullWidth
                           label="Email Address"
                           variant="outlined"
                           value={email}
                           onChange={(e) => setEmail(e.target.value)}
                           required
                           type="email"
                           sx={{ mb: 3 }}
                        />
                        <Button
                           type="submit"
                           variant="contained"
                           fullWidth
                           disabled={submitting}
                           sx={{
                              backgroundColor: "#5fcf80",
                              borderRadius: "50px",
                              padding: "10px 0",
                              '&:hover': {
                                 backgroundColor: "#56ba73",
                              }
                           }}
                        >
                           {submitting ? "Submitting..." : "Join Now"}
                        </Button>
                     </form>
                  </>
               ) : (
                  <Box textAlign="center">
                     <Typography variant="h5" component="h2" sx={{ mb: 2, fontWeight: 600 }}>
                        Thank You!
                     </Typography>
                     <Typography variant="body1">
                        We've added you to our waiting list. We'll notify you when CareerGPT launches.
                     </Typography>
                  </Box>
               )}
            </Box>
         </Modal>
      </Grid2>
   );
}
