// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resume-news-title-close-btn{
  text-decoration: underline;
  color: red;
  cursor: pointer;


}`, "",{"version":3,"sources":["webpack://./src/css/ResumeReview.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,UAAU;EACV,eAAe;;;AAGjB","sourcesContent":[".resume-news-title-close-btn{\n  text-decoration: underline;\n  color: red;\n  cursor: pointer;\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
