// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* html,
body,
#root,
.container-fluid,
button,
a,
input[type="submit"],
input[type="button"],
.btn,
.btn:active,
.btn:focus,
.btn:hover,
.dropdown-toggle,
.nav-link {
  cursor: url("./assets/mousebgremoved.png"), pointer;
} */

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;GAeG;;AAEH;EACE,sBAAsB;AACxB;;AAEA;;EAEE,SAAS;EACT,UAAU;EACV,WAAW;EACX,YAAY;AACd","sourcesContent":["/* html,\nbody,\n#root,\n.container-fluid,\nbutton,\na,\ninput[type=\"submit\"],\ninput[type=\"button\"],\n.btn,\n.btn:active,\n.btn:focus,\n.btn:hover,\n.dropdown-toggle,\n.nav-link {\n  cursor: url(\"./assets/mousebgremoved.png\"), pointer;\n} */\n\n* {\n  box-sizing: border-box;\n}\n\nhtml,\nbody {\n  margin: 0;\n  padding: 0;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
