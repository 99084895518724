import { Box, Typography } from "@mui/material";
import React, { useState, useRef } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const FileDropZone = ({ onFileUpload }) => {
   const [isDragging, setIsDragging] = useState(false);
   const fileInputRef = useRef(null);

   const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
   };

   const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
   };

   const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      onFileUpload(e.dataTransfer.files[0]);
   };

   const handleClick = () => {
      fileInputRef.current.click();
   };

   const handleFileChange = (e) => {
      onFileUpload(e.target.files[0]);
   };

   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: 100,
            border: "1px solid #eaeaf0",
            borderRadius: 1,
            boxShadow: isDragging ? "0px 4px 20px rgba(0, 0, 0, 0.1)" : "none",
            backgroundColor: isDragging ? "rgba(230, 227, 227, 0.5)" : "#fff",
            cursor: "pointer",
         }}
         className={`drop-zone ${isDragging ? "dragging" : ""}`}
         onDragEnter={handleDragEnter}
         onDragOver={(e) => e.preventDefault()}
         onDragLeave={handleDragLeave}
         onDrop={handleDrop}
         onClick={handleClick}
      >
         <input
            type="file"
            accept=".pdf"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
         />
         <UploadFileIcon sx={{ color: "#bbb", fontSize: "3rem" }} />
         <Typography variant="body2" sx={{ color: "#aaa" }}>
            Drag and drop your resume here or click to upload
         </Typography>
      </Box>
   );
};

export default FileDropZone;
