// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trend-page-title {
  margin-left: 10px;
  font-size: 25px;
}

.salary-news-title-close-btn {
  /* position: relative; */
  /* left:58%; */
  /* transform: translate(100%, 0); */
  text-decoration: underline;
  color: red;
  cursor: pointer;
}

@media (max-width: 768px) {
  .salary-news-title-close-btn {

  }
}
`, "",{"version":3,"sources":["webpack://./src/css/salary.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,wBAAwB;EACxB,cAAc;EACd,mCAAmC;EACnC,0BAA0B;EAC1B,UAAU;EACV,eAAe;AACjB;;AAEA;EACE;;EAEA;AACF","sourcesContent":[".trend-page-title {\n  margin-left: 10px;\n  font-size: 25px;\n}\n\n.salary-news-title-close-btn {\n  /* position: relative; */\n  /* left:58%; */\n  /* transform: translate(100%, 0); */\n  text-decoration: underline;\n  color: red;\n  cursor: pointer;\n}\n\n@media (max-width: 768px) {\n  .salary-news-title-close-btn {\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
