import React, { useState } from "react";
import SubscriptionPlanComponent from "../components/SubscriptionPlanList";
import axios from "axios";
import { baseUrl } from "../services/api";
import { useGetMe } from "../hooks/useGetMe";
import styles from "./../css/SubscriptionPlan.module.css";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { format, differenceInDays } from "date-fns";
// import { getFreePlan } from "../services/candidateApi";
import { useSetFreePlan } from "../hooks/useSetFreePlan";
import { useSetProPlan } from "../hooks/useSetProPlan";
import { Button } from "@mui/material";
import { Typography } from "@mui/material";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SubscriptionPlan = () => {
  const [clickFreePlan, setClickFreePlan] = useState(false);
  const [message, setMessage] = useState("");
  const { myData } = useGetMe();
  const token = sessionStorage.getItem("authToken");
  const navigate = useNavigate();

  const { getFreePlan, isPending } = useSetFreePlan();
  const { setProPlan, isPending: isSetProPlanUpdating } = useSetProPlan();

  // Updated free plan features
  const freePlanDetails = [
    "3 free people searches",
    "Limited support",
    "Max 1 user chat",
  ];

  // Updated pro plan features
  const proPlanDetails = [
    "Unlimited people searches",
    "Priority support",
    "Unlimited user chats",
    "Access to all premium features",
  ];

  // Determine if user is on free plan
  const isOnFreePlan = !myData?.isPaid &&
    (myData?.subscriptionPlan === "free" || !myData?.subscriptionPlan);

  // Determine if user is on pro plan
  const isOnProPlan = myData?.isPaid ||
    myData?.subscriptionPlan === "pro" ||
    myData?.appsumo_lifetime_access === true;

  const handleFreePlan = () => {
    if (isOnFreePlan) {
      toast.info("You are already on the free plan");
      return;
    } else {
      setClickFreePlan(true);

      const expireDate = new Date(myData.subscriptionPlanExpireDate);
      const todayDate = new Date();

      const remainingDays = differenceInDays(expireDate, todayDate);

      setMessage(
        `You have ${remainingDays} days remaining in your pro plan, do you want to switch to free plan?`
      );
    }
  };

  const handleFreePlanConfirmation = async () => {
    const freePlan = getFreePlan(
      {},
      {
        onSuccess: () => {
          toast.success("You have successfully switched to free plan");
          setClickFreePlan(false);
        },
      }
    );
  };

  const handlePayClick = async () => {
    const payBtn = document.querySelector(".payment-btn");
    payBtn.textContent = "Processing";
    payBtn.setAttribute("disabled", "disabled");

    try {
      const res = await axios.post(
        `${baseUrl}/stripe/create-checkout-session`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        window.location.href = res.data.url;
      } else if (res.status === 201) {
        alert("You already paid. Please wait for page to refresh");
        window.location.reload();
      } else {
        alert("Some error occurred. Please try again");
      }
    } catch (err) {
      console.log(err);
      alert("Server error occurred. Please try again");
      payBtn.textContent = "Get Pro Plan";
      payBtn.removeAttribute("disabled");
    }
  };

  return (
    <div className=" p-4">
      {/* <div className=" d-flex justify-content-center w-100"> */}
      <div className="d-flex flex-sm-row flex-column justify-content-center gap-5 ">
        <div className="">
          <SubscriptionPlanComponent
            planType="Free"
            price="$0"
            details={freePlanDetails}
            buttonText="Get Free Plan"
            isFree={true}
            icone={"/OIP (1).jpg"}
          >
            {isOnFreePlan ? (
              <div className="alert alert-info mt-3 w-75 mx-auto">
                <small>You are currently on the Free Plan</small>
              </div>
            ) : (
              <button
                onClick={handleFreePlan}
                style={{
                  backgroundColor: "#f00",
                  borderColor: "#f00",
                }}
                className={`btn w-75 ${styles.planButton} mt-3`}
              >
                Get Free Plan
              </button>
            )}
          </SubscriptionPlanComponent>
        </div>

        <div className="">
          <SubscriptionPlanComponent
            planType="Pro"
            price="$15"
            details={proPlanDetails}
            buttonText="Get Pro Plan"
            isFree={false}
            icone={"/OIP.jpg"}
          >
            {isOnProPlan ? (
              <div className="alert alert-success mt-3 w-75 mx-auto">
                <small>You are currently on the Pro Plan</small>
              </div>
            ) : (
              <button
                onClick={handlePayClick}
                style={{
                  backgroundColor: "#28a745",
                  borderColor: "#28a745",
                }}
                className={`btn w-75 ${styles.planButton} mt-3 payment-btn`}
              >
                Get Pro Plan
              </button>
            )}
          </SubscriptionPlanComponent>
        </div>
      </div>

      <Modal show={clickFreePlan} onHide={setClickFreePlan}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Free Plan?</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        {/* {deletingProfile ? (
          <div className="d-flex justify-content-center pb-4 pt-2">
            <Spinner />
          </div>
        ) : ( */}
        <Modal.Footer className="d-flex gap-3">
          <button
            className=" text-white fs-xs border-0 rounded-3 py-2 px-4 col-sm-auto  col-12 fixed-sm-bottom  mb-4  mx-auto mx-sm-0"
            style={{ backgroundColor: "#198754" }}
            onClick={handleFreePlanConfirmation}
          >
            Confirm
          </button>
          <button
            className=" text-white fs-xs border-0 rounded-3 py-2 px-4 col-sm-auto  col-12 fixed-sm-bottom  mb-4  mx-auto mx-sm-0"
            style={{ backgroundColor: "#dc3545" }}
            onClick={() => setClickFreePlan(false)}
          >
            Cancel
          </button>
        </Modal.Footer>
        {/* )} */}
      </Modal>
    </div>
  );
};

export default SubscriptionPlan;
