// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.squarePro {
  border: 1px solid #a5a4a4;
  /* background-color: #1168be; */
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  position: absolute;
  top: -30px;
  left: -95px;
}

.squareFree {
  border: 1px solid #a5a4a4;
  /* background-color: #1168be; */
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  position: absolute;
  top: -30px;
  left: -75px;
}
.squareText {
  font-size: 0.8rem;
  font-weight: bold;
  color: #333;
  display: flex;
  justify-content: end;
  /* position: absolute; */
  /* top: 50%; */
}
.main {
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/css/Star.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,+BAA+B;EAC/B,kBAAkB;EAClB,0BAA0B;EAC1B,uBAAuB;EACvB,sBAAsB;EACtB,qBAAqB;EACrB,kBAAkB;EAClB,UAAU;EACV,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,+BAA+B;EAC/B,kBAAkB;EAClB,0BAA0B;EAC1B,uBAAuB;EACvB,sBAAsB;EACtB,qBAAqB;EACrB,kBAAkB;EAClB,UAAU;EACV,WAAW;AACb;AACA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,WAAW;EACX,aAAa;EACb,oBAAoB;EACpB,wBAAwB;EACxB,cAAc;AAChB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".squarePro {\n  border: 1px solid #a5a4a4;\n  /* background-color: #1168be; */\n  border-radius: 50%;\n  -webkit-border-radius: 50%;\n  -moz-border-radius: 50%;\n  -ms-border-radius: 50%;\n  -o-border-radius: 50%;\n  position: absolute;\n  top: -30px;\n  left: -95px;\n}\n\n.squareFree {\n  border: 1px solid #a5a4a4;\n  /* background-color: #1168be; */\n  border-radius: 50%;\n  -webkit-border-radius: 50%;\n  -moz-border-radius: 50%;\n  -ms-border-radius: 50%;\n  -o-border-radius: 50%;\n  position: absolute;\n  top: -30px;\n  left: -75px;\n}\n.squareText {\n  font-size: 0.8rem;\n  font-weight: bold;\n  color: #333;\n  display: flex;\n  justify-content: end;\n  /* position: absolute; */\n  /* top: 50%; */\n}\n.main {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
