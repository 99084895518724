import { useState, useEffect } from "react";
import { getJobLiveView, getAutoApplyReports } from "../../services/autoApply";
import AutoApply from "./AutoApply copy";
import LiveView from "./LiveView";
import ImprovedJobsGrid from "./ImprovedJobsGrid";
import { Box, Button, ButtonGroup } from "@mui/material";
import ViewListIcon from '@mui/icons-material/ViewList';
import PreviewIcon from '@mui/icons-material/Preview';
import { useGetMe } from "../../hooks/useGetMe";

// View states
const VIEWS = {
   CREATE_JOB: 'create_job',
   LIVE_VIEW: 'live_view',
   ALL_JOBS: 'all_jobs'
};

export default function AutoApplyLayout() {
   const [currentView, setCurrentView] = useState(VIEWS.CREATE_JOB);
   const [reports, setReports] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [frontendUrls, setFrontendUrls] = useState({});
   const [lastUpdated, setLastUpdated] = useState('');
   const [currentSession, setCurrentSession] = useState(null);
   const { myData, isPending: isMyData } = useGetMe();
   const [maxAllowedJobs, setMaxAllowedJobs] = useState(0);
   // Fetch all auto apply reports
   async function handleGetApplyReports() {
      setIsLoading(true);
      try {
         const data = await getAutoApplyReports();
         // Ensure data is an array, if null or undefined, use empty array
         setReports(Array.isArray(data) ? data : []);
         // Update the timestamp
         setLastUpdated(new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
         }));
      } catch (error) {
         setReports([]);
      } finally {
         setIsLoading(false);
      }
   }

   // Get frontend URL for a specific session
   async function getFrontendUrl(sessionId) {
      if (!sessionId) return null;
      try {
         if (!frontendUrls[sessionId]) {
            const url = await getJobLiveView(sessionId);
            if (url) {
               setFrontendUrls((prev) => ({ ...prev, [sessionId]: url }));
               return url;
            }
         }
         return frontendUrls[sessionId];
      } catch (error) {
         console.error("Error fetching frontend URL:", error);
         return null;
      }
   }

   // Handle successful auto-apply form submission
   const handleAutoApplySuccess = (jobData) => {
      if (jobData && jobData.session_id) {
         setCurrentSession(jobData);
         setCurrentView(VIEWS.LIVE_VIEW);
      } else {
         setCurrentView(VIEWS.ALL_JOBS);
      }
      handleGetApplyReports();
   };

   // Handle view details job from the grid
   const handleViewLiveJob = (job) => {
      if (job) {
         // console.log('Viewing job details for:', job);
         // console.log('Job has applications data:', Boolean(job.jobs || job.applications || job.individual_jobs));

         setCurrentSession(job);
         // Always go to live view, the component will handle different display modes
         // based on the job's status
         setCurrentView(VIEWS.LIVE_VIEW);
      }
   };

   // Load data on mount
   useEffect(() => {
      handleGetApplyReports();
   }, []);

   useEffect(() => {
      if (myData) {
         setMaxAllowedJobs(myData.num_agentic_queries);
      }
   }, [myData]);

   // Render the appropriate view
   const renderView = () => {
      switch (currentView) {
         case VIEWS.CREATE_JOB:
            return (
               <Box>
                  <AutoApply 
                     onSubmitSuccess={handleAutoApplySuccess} 
                     viewAllJobs={() => setCurrentView(VIEWS.ALL_JOBS)}
                     maxAllowedJobs={maxAllowedJobs}
                  />
               </Box>
            );

         case VIEWS.LIVE_VIEW:
            return (
               <LiveView
                  gif_url={currentSession.gif_url}
                  sessionId={currentSession?.session_id}
                  frontendUrl={frontendUrls[currentSession?.session_id]}
                  getFrontendUrl={getFrontendUrl}
                  onNewJobClick={() => setCurrentView(VIEWS.CREATE_JOB)}
                  onViewAllJobsClick={() => setCurrentView(VIEWS.ALL_JOBS)}
                  jobDetails={currentSession}
                  onRefresh={handleGetApplyReports}
               />
            );

         case VIEWS.ALL_JOBS:
            return (
               <ImprovedJobsGrid
                  reports={reports}
                  onCreateNewJob={() => setCurrentView(VIEWS.CREATE_JOB)}
                  onViewLiveJob={handleViewLiveJob}
                  onRefresh={handleGetApplyReports}
                  isLoading={isLoading}
                  lastUpdated={lastUpdated}
               />
            );

         default:
            return <AutoApply onSubmitSuccess={handleAutoApplySuccess} />;
      }
   };

   return (
      <Box
         sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            flexGrow: "0",
            margin: {
               md: "1rem 0 0 0",
               sm: "1rem 0 0 0",
               xs: "1rem 0 0 0",
            },
            gap: "1rem",
         }}
      >
         {renderView()}
      </Box>
   );
}
