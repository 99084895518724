import axios from "axios";
import { baseUrl } from "./api";
import { toast } from "react-toastify";
import { encryptAndEncodePassword, getToken } from "../util/utilityFunction";

export const getLinkedinProfiles = async (query, sessionId, offset, linkedinCredentials) => {
   const token = await getToken();
   if (!token) return null;

   try {
      return await fetch(
         `${baseUrl}/linkedin/get_linkedin_profiles?query=${encodeURIComponent(query)}&session_id=${sessionId}&offset=${offset}`,
         {
            method: "POST",
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            body: JSON.stringify(linkedinCredentials),
         }
      );
   } catch (error) {
      return error;
   }
};
