/* global pendo */ // Declare pendo as a global variable

import React, { Suspense, lazy, useState, useEffect } from "react";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { persistQueryClient } from "@tanstack/query-persist-client-core";
import {
   Navigate,
   Route,
   BrowserRouter as Router,
   Routes,
} from "react-router-dom";
import AuthDialog from "./features/authDialog/AuthDialog.jsx";
import "./App.css";

import ChatSocketHandler from "./components/ChatSocketHandler";
import PrivateRoute from "./components/PrivateRoute";
import Success from "./components/Success";
import Cansel from "./components/Cansel";
import EmailVerification from "./ui/EmailVerification";
import HotjarTracker from "./components/HotjarTracker";

import CandidateLayout from "./layout/CandidateLayout";
import RecruiterLayout from "./layout/RecruiterLayout";
import AuthLayout from "./layout/AuthLayout";
import SettingLayout from "./layout/SettingLayout";
import CoachLayout from "./layout/CoachLayout";

import UserConversation from "./features/chat/UserConversation";
import NoClickChat from "./features/chat/NoClickChat";

import MessageNotification from "./components/MessageNotification";
import { SocketContextProvider, useSocket } from "./context/SocketContext";

import Lock from "./ui/Lock";

import RouteProtecter from "./services/RouteProtecter";
import CustomizedSpinner from "./ui/CustomizedSpinner";

import InterviewPrepRecruiter from "./pages/InterviewPrepRecruiter";
import FeedbackForm from "./pages/FeedbackForm";
import ForgotPassword from "./pages/ForgotPassword";
import InterviewPrep from "./pages/candidateInterviewPreparation/InterviewPrep";
import JobPosting from "./pages/JobPosting";
import JobSearch from "./pages/JobSearch";
import Progress from "./pages/Progress";
import ResumeReviewCandidate from "./pages/ResumeReviewCandidate";
import ResumeReviewRecruiter from "./pages/recruiterResumeRanking/ResumeReviewRecruiter";
import SalaryInsightsRecruiter from "./pages/SalaryInsightsRecruiter";
import SalaryInsightsCoach from "./pages/SalaryInsightsCoach";
import ResumeReviewCoach from "./pages/coach-summarize-experience/SummarizeExperience";
import SalaryInsightsCandidate from "./pages/SalaryInsightsCandidate";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import EmailOrLinkedIn from "./components/EmailOrLinkedIn";
import ResetPassword from "./pages/ResetPassword";
import CandidateProfile from "./pages/CandidateProfile";
import Profile from "./pages/settings/Profile";
import Password from "./pages/settings/Password";
import SocialProfile from "./pages/settings/SocialProfile";
import Resume from "./pages/settings/Resume";
import DeleteProfile from "./pages/settings/DeleteProfile";
import ConnectToProfessionals from "./pages/connectToProfessionals/ConnectToProfessionals";
import StartChat from "./pages/connectToProfessionals/StartChat";
import ResumeFormatting from "./pages/coach-resume-formatting/ResumeFormatting";
import EducationCertification from "./pages/coach-educationCertifications/EducationCertification";
import ReleventWork from "./pages/coach-relevant-work/ReleventWork";
import ResetPasswordOtp from "./pages/ResetPasswordOtp";
import News from "./pages/News";
import Chat from "./pages/Chat";
import RecruiterInterviewPreparation from "./pages/recruter-interview-preparation/RecruiterInterviewPreparation";
import CoachInterviewPreparation from "./pages/coach-interview-preparation/CoachInterviewPreparation";
import CoachSkillAssessment from "./pages/coach-skillAssessment/CoachSkillAssessment";
import CoachVolunteerWork from "./pages/coach-volunteerWork/CoachVolunteerWork";
import Theme from "./pages/settings/Theme";
import User from "./pages/User";
import HelpAndSupport from "./pages/HelpAndSupport";
import SubscriptionPlan from "./pages/SubscriptionPlan";
import CandidateRecruiterMatching from "./pages/CandidateRecruiterMatching";
import RecruiterCandidateMatching from "./pages/RecruiterCandidateMatching";
import NotFound from "./ui/NotFound";
import CareerCoach from "./pages/AICareerCoach/AICareerCoach";
import ErrorBoundary from "./components/ErrorBoundary";
import AutoApply from "./pages/Autoapply/AutoApply copy.js";
import HomePage from "./pages/HomePage.tsx";
import PublicRoutes from "./util/PublicRoutes.tsx";
import Query from "./pages/Query";
import PrivacyPolicy from "./pages/Legal/PrivacyPolicy.tsx";
import TermsOfService from "./pages/Legal/TermsOfService.tsx";
import AutoApplyLayout from "./pages/Autoapply/AutoApplyLayout.jsx";
import AppSumoRedeem from "./pages/AppSumoRedeem";

function App() {
   const [show, setShow] = useState(false);
   const [userType, setUserType] = useState("");
   const [messages, setMessages] = useState([]);
   const [selectedUserForChat, setSelectedUserForChat] = useState(null);
   const [onChatPage, setOnChatPage] = useState(false);
   const [isNewChat, setIsNewChat] = useState(false);

   const queryClient = new QueryClient({
      defaultOptions: {
         queries: {
            staleTime: 0,
         },
      },
   });

   const sessionStoragePersister = createSyncStoragePersister({
      storage: window.sessionStorage,
   });

   persistQueryClient({
      queryClient,
      persister: sessionStoragePersister,
      maxAge: 1,
   });

   useEffect(() => {
      if (userType) {
         if (typeof window.pendo !== 'undefined') {
            window.pendo.initialize({
               visitor: {
                  id: 'VISITOR-UNIQUE-ID'
                  // id: myData?.current_user?.id || 'default-visitor-id',
                  // email: myData?.current_user?.email || 'email@example.com',
                  // firstName: myData?.current_user?.firstName || 'First Name',
                  // lastName: myData?.current_user?.last_name || '',
                  // userPermissions: myData?.user_permissions || '',
                  // role: myData?.current_user.role || '',
                  // department: myData?.department || '',
                  // paidOrTrialUser: myData?.paid_or_trial_user || '',
               },
               // account: {
               //    id: myData?.current_account.id || '',
               //    accountName: myData?.current_account.name || '',
               //    payingStatus: myData?.paying_status || '',
               //    accountValue: myData?.account_value || '',
               //    industry: myData?.industry || '',
               //    marketSegment: myData?.market_segment || '',
               //    location: myData?.location || '',
               //    planPrice: myData?.plan_price || '',
               //    accountCreationDate: myData?.account_creation_date || '',
               //    contractStartDate: myData?.contract_start_date || '',
               //    renewalDate: myData?.renewal_date || '',
               //    businessTier: myData?.business_tier || '',
               //    accountManager: myData?.account_manager || '',
               // }
            });
         }
      }
   }, [userType]);

   useEffect(() => {
      const handleBeforeUnload = () => window.socket && window.socket.disconnect()
      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
   }, [])

   return (
      <QueryClientProvider
         client={queryClient}
         persistOptions={{ sessionStoragePersister }}
      >
         <ErrorBoundary>
            <React.Fragment>
               <SocketContextProvider>
                  <Router>
                     <HotjarTracker />
                     <div className="App">
                        <Routes>
                           {/* Public/standalone routes */}
                           <Route path="/" element={<HomePage />} />
                           <Route exact path="/linkedin" element={<LinkedInCallback />} />
                           <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                           <Route path="/terms-of-service" element={<TermsOfService />} />
                           <Route path="/appsumo-redeem" element={<AppSumoRedeem />} />

                           {/* Public auth routes */}
                           <Route element={<PublicRoutes />}>
                              <Route path="/signup" element={<EmailOrLinkedIn />} />
                              <Route path="/continue-with-email" element={<SignUp setMainUserType={setUserType} />} />
                              <Route path="/signin" element={<AuthLayout><SignIn setUserType={setUserType} /></AuthLayout>} />
                              <Route path="/forgotpassword" element={<ForgotPassword />} />
                              <Route path="/resetpassword" element={<ResetPassword />} />
                              <Route path="/resetpasswordotp" element={<ResetPasswordOtp />} />
                           </Route>

                           {/* Candidate routes */}
                           <Route
                              path="/candidate/*"
                              element={
                                 <RouteProtecter authorizedUserType="candidate">
                                    <CandidateLayout isNewChat={isNewChat}>
                                       <Routes>
                                          <Route
                                             path="career-coach"
                                             element={<CareerCoach />}
                                          />
                                          <Route
                                             path="setting/*"
                                             element={<SettingLayout />}
                                          >
                                             <Route index element={<Navigate to="profile" replace />} />
                                             <Route
                                                path="profile"
                                                element={<Profile />}
                                             />
                                             <Route
                                                path="password"
                                                element={<Password />}
                                             />
                                             <Route
                                                path="socialprofile"
                                                element={<SocialProfile />}
                                             />
                                             <Route
                                                path="resume"
                                                element={<Resume />}
                                             />
                                             <Route
                                                path="deleteprofile"
                                                element={<DeleteProfile />}
                                             />
                                             <Route
                                                path="changetheme"
                                                element={<Theme />}
                                             />
                                          </Route>
                                          <Route
                                             path="networking"
                                             element={
                                                <CandidateRecruiterMatching />
                                             }
                                          />
                                          <Route
                                             path="subscription-plan"
                                             element={<SubscriptionPlan />}
                                          />
                                          <Route
                                             path="profile"
                                             element={<CandidateProfile />}
                                          />
                                          <Route
                                             path="user/:id"
                                             element={<User />}
                                          />
                                          <Route
                                             path="interview"
                                             element={<InterviewPrep />}
                                          />
                                          <Route
                                             path="progress"
                                             element={<Progress />}
                                          />
                                          <Route
                                             path="help-and-support"
                                             element={<HelpAndSupport />}
                                          />
                                          <Route
                                             path="resumereview"
                                             element={<ResumeReviewCandidate />}
                                          />
                                          <Route
                                             path="salary"
                                             element={
                                                <SalaryInsightsCandidate />
                                             }
                                          />
                                          <Route
                                             path="news"
                                             element={<News />}
                                          />
                                          <Route
                                             path="jobsearch"
                                             element={<JobSearch />}
                                          />
                                          <Route
                                             path="chat"
                                             element={
                                                <Chat>
                                                   <NoClickChat text=" Select a chat to start messaging" />
                                                </Chat>
                                             }
                                          />
                                          <Route
                                             path="chat/:id"
                                             element={
                                                <Chat>
                                                   <UserConversation />
                                                </Chat>
                                             }
                                          />
                                          <Route
                                             path="connect-to-professionals"
                                             element={
                                                <ConnectToProfessionals />
                                             }
                                          />
                                          <Route
                                             path="connect-to-professionals/startChat/:id"
                                             element={<StartChat />}
                                          />
                                          <Route
                                             path="feedback"
                                             element={<FeedbackForm />}
                                          />
                                          <Route
                                             path="auto-apply"
                                             element={<AutoApplyLayout />}
                                          />
                                       </Routes>
                                    </CandidateLayout>
                                 </RouteProtecter>
                              }
                           />

                           {/* Recruiter routes */}
                           <Route
                              path="/recruiter/*"
                              element={
                                 <RouteProtecter authorizedUserType="recruiter">
                                    <RecruiterLayout isNewChat={isNewChat}>
                                       <Routes>
                                          <Route
                                             path="setting/*"
                                             element={<SettingLayout />}
                                          >
                                             <Route index element={<Navigate to="profile" replace />} />
                                             <Route
                                                path="profile"
                                                element={<Profile />}
                                             />
                                             <Route
                                                path="password"
                                                element={<Password />}
                                             />
                                             <Route
                                                path="socialprofile"
                                                element={<SocialProfile />}
                                             />
                                             <Route
                                                path="resume"
                                                element={<Resume />}
                                             />
                                             <Route
                                                path="deleteprofile"
                                                element={<DeleteProfile />}
                                             />
                                             <Route
                                                path="changetheme"
                                                element={<Theme />}
                                             />
                                          </Route>
                                          <Route
                                             path="chat"
                                             element={
                                                <Chat>
                                                   <NoClickChat text=" Select a chat to start messaging" />
                                                </Chat>
                                             }
                                          />
                                          <Route
                                             path="chat/:id"
                                             element={
                                                <Chat>
                                                   <UserConversation />
                                                </Chat>
                                             }
                                          />
                                          <Route
                                             path="news"
                                             element={<News />}
                                          />
                                          <Route
                                             path="subscription-plan"
                                             element={<SubscriptionPlan />}
                                          />
                                          <Route
                                             path="networking"
                                             element={
                                                <RecruiterCandidateMatching />
                                             }
                                          />
                                          <Route
                                             path="help-and-support"
                                             element={<HelpAndSupport />}
                                          />
                                          <Route
                                             path="user/:id"
                                             element={<User />}
                                          />
                                          <Route
                                             path="interview"
                                             element={
                                                <RecruiterInterviewPreparation />
                                             }
                                          />
                                          <Route
                                             path="profile"
                                             element={<CandidateProfile />}
                                          />
                                          <Route
                                             path="resumeranking"
                                             element={<ResumeReviewRecruiter />}
                                          />
                                          <Route
                                             path="progress"
                                             element={<Progress />}
                                          />
                                          <Route
                                             path="salary"
                                             element={
                                                <SalaryInsightsRecruiter />
                                             }
                                          />
                                          <Route
                                             path="jobposting"
                                             element={<JobPosting />}
                                          />
                                          <Route
                                             path="peoplesearch"
                                             element={<Query />}
                                          />
                                          <Route
                                             path="connect-to-professionals"
                                             element={
                                                <ConnectToProfessionals />
                                             }
                                          />
                                          <Route
                                             path="connect-to-professionals/startChat/:id"
                                             element={<StartChat />}
                                          />
                                          <Route
                                             path="feedback"
                                             element={<FeedbackForm />}
                                          />
                                       </Routes>
                                    </RecruiterLayout>
                                 </RouteProtecter>
                              }
                           />

                           {/* Coach routes */}
                           <Route
                              path="/coach/*"
                              element={
                                 <RouteProtecter authorizedUserType="coach">
                                    <CoachLayout isNewChat={isNewChat}>
                                       <Routes>
                                          <Route
                                             path="setting/*"
                                             element={<SettingLayout />}
                                          >
                                             <Route index element={<Navigate to="profile" replace />} />
                                             <Route
                                                path="profile"
                                                element={<Profile />}
                                             />
                                             <Route
                                                path="password"
                                                element={<Password />}
                                             />
                                             <Route
                                                path="socialprofile"
                                                element={<SocialProfile />}
                                             />
                                             <Route
                                                path="resume"
                                                element={<Resume />}
                                             />
                                             <Route
                                                path="deleteprofile"
                                                element={<DeleteProfile />}
                                             />
                                             <Route
                                                path="changetheme"
                                                element={<Theme />}
                                             />
                                          </Route>
                                          <Route
                                             path="news"
                                             element={<News />}
                                          />
                                          <Route
                                             path="subscription-plan"
                                             element={<SubscriptionPlan />}
                                          />
                                          <Route
                                             path="user/:id"
                                             element={<User />}
                                          />
                                          <Route
                                             path="tailored-skills"
                                             element={<CoachSkillAssessment />}
                                          />
                                          <Route
                                             path="help-and-support"
                                             element={<HelpAndSupport />}
                                          />
                                          <Route
                                             path="chat"
                                             element={
                                                <Chat>
                                                   <NoClickChat text=" Select a chat to start messaging" />
                                                </Chat>
                                             }
                                          />
                                          <Route
                                             path="chat/:id"
                                             element={
                                                <Chat>
                                                   <UserConversation />
                                                </Chat>
                                             }
                                          />
                                          <Route
                                             path="connect-to-professionals"
                                             element={
                                                <ConnectToProfessionals />
                                             }
                                          />
                                          <Route
                                             path="connect-to-professionals/startChat/:id"
                                             element={<StartChat />}
                                          />
                                          <Route
                                             path="resume-formatting"
                                             element={<ResumeFormatting />}
                                          />
                                          <Route
                                             path="salary"
                                             element={<SalaryInsightsCoach />}
                                          />
                                          <Route
                                             path="concise-summary"
                                             element={<ResumeReviewCoach />}
                                          />
                                          <Route
                                             path="profile"
                                             element={<CandidateProfile />}
                                          />
                                          <Route
                                             path="interview-prep"
                                             element={
                                                <CoachInterviewPreparation />
                                             }
                                          />
                                          <Route
                                             path="search-candidate"
                                             element={
                                                <RecruiterCandidateMatching />
                                             }
                                          />
                                          <Route
                                             path="education-certification"
                                             element={
                                                <EducationCertification />
                                             }
                                          />
                                          <Route
                                             path="relevant-work"
                                             element={<ReleventWork />}
                                          />
                                          <Route
                                             path="relevant-volunteer"
                                             element={<CoachVolunteerWork />}
                                          />
                                       </Routes>
                                    </CoachLayout>
                                 </RouteProtecter>
                              }
                           />

                           <Route
                              path="/payment/success"
                              element={<Success setShow={setShow} />}
                           />
                           <Route
                              path="/payment/cansel"
                              element={<Cansel setShow={setShow} />}
                           />
                           <Route
                              path="/emailVerification/:email"
                              element={<EmailVerification />}
                           />
                           <Route path="*" element={<NotFound />} />
                        </Routes>
                     </div>
                  </Router>
                  <AuthDialog />
                  <ChatSocketHandler
                     messages={messages}
                     setMessages={setMessages}
                     selectedUserForChat={selectedUserForChat}
                     userType={userType}
                     onChatPage={onChatPage}
                     setIsNewChat={setIsNewChat}
                  />
               </SocketContextProvider>
            </React.Fragment>
         </ErrorBoundary>
      </QueryClientProvider>
   );
}

export default App;
