import axios from "axios";
import { baseUrl } from "./api";
import { getToken } from "../util/utilityFunction";

export const getRecruiterMe = async () => {
  const token = await getToken();
  if (!token) return null;

  try {
    const response = await axios.get(`${baseUrl}/auth/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status >= 200 && response.status < 300) {
      const data = response.data;
      return data;
    }

    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getOneRecruiter = async ({ id }) => {
  try {
    const token = await getToken();
    if (!token) return null;

    let recruiterData;
    let conversationData;
    const response = await axios.get(
      `${baseUrl}/recruiters/oneRecruiter/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status >= 200 && response.status < 300 && response.data) {
      recruiterData = response.data;
    }

    const responsConversation = await axios.get(
      `${baseUrl}/user-conversation/getConversation/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (responsConversation.data) {
      conversationData = responsConversation.data;
      // console.log(conversationData);
    }

    if (recruiterData && conversationData) {
      const data = {
        recruiterData,
        conversationData,
      };
      return data;
    }

    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const newsData = async () => {
  try {
    const token = await getToken();
    if (!token) return null;

    const response = await axios.get(`${baseUrl}/news`, {
      params: {
        filter: "salary news",
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data && Array.isArray(response.data)) {
      const data = response.data;
      return data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

/**
 * Decrements a search token for the recruiter
 * @returns {Promise<Object|null>} The updated user data or null if the request fails
 */
export const useSearchToken = async () => {
  const token = await getToken();
  if (!token) return null;

  try {
    const response = await axios.post(
      `${baseUrl}/recruiters/use-search-token`,
      {},  // empty body
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.error("Failed to use search token on server:", error);
    return null;
  }
};
