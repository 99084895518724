import io from 'socket.io-client';
import { serverHost } from "./services/api.js";

export default function getSocketInit() {
    if (window.socket) {
        return window.socket;
    }
    
    // Parse the server host URL to get just the origin without port in production
    let socketHost = serverHost;
    
    // In production, ensure we're not using any port numbers
    if (socketHost.includes('app.careergpt.io')) {
        // Use URL constructor to parse the URL
        const url = new URL(socketHost);
        // Use only the origin (protocol + hostname) without port
        socketHost = `${url.protocol}//${url.hostname}`;
    }
    
    const socket = io(socketHost, {
        autoConnect: true,
        auth: {
            "userId": localStorage.getItem("userId"),
            "user": JSON.parse(localStorage.getItem("userObject")),
        },
        transports: ["websocket", "polling"],
        withCredentials: true
    });
    window.socket = socket;
    return socket;
}
