import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { changePassword } from "../../services/apiSetting";
import { toast } from "react-toastify";
import { getMe } from "../../services/candidateApi";
import { useQuery } from "@tanstack/react-query";
function Password() {
  const [tempOldPassword, setTempOldPassword] = useState("");
  const [tempNewPassword, setTempNewPassword] = useState("");
  // const id = localStorage.getItem("userId");

  const queryClient = useQueryClient();

  const { data: myData, isPending: isMyData } = useQuery({
    queryKey: ["getMe"],
    queryFn: getMe,
  });

  const { mutate: updatePassword, isPending: updatingPassword } = useMutation({
    mutationFn: ({ data }) => changePassword(data),
    onSuccess: (data) => {
      toast.success("Password changed successfully");
      // queryClient.invalidateQueries("getMe");
      setTempOldPassword("");
      setTempNewPassword("");
    },
    onError: (error) => {
      toast.error("Error during changing password : ", error);
    },
  });

  const savePassword = () => {
    if (tempOldPassword === "" || tempNewPassword === "") {
      toast.error("Please fill all the fields");
      return;
    }
    if (tempNewPassword.length < 8) {
      toast.error("Password should be atleast 8 characters long");
      return;
    }
    const data = {
      oldPassword: tempOldPassword,
      newPassword: tempNewPassword,
    };
    updatePassword({ data });
  };

  return (
    <div className="  ">
      <div className="mt-3 w-100 ">
        <div className=" fs-xs ">Old password</div>

        <input
          className="w-100  mt-1 py-2 px-2 fs-sm"
          type="password"
          value={tempOldPassword}
          style={{ border: "2px solid #DADBDA", borderRadius: "14px" }}
          onChange={(e) => setTempOldPassword(e.target.value)}
          onFocus={(e) => (e.target.style.border = "2px solid #525252")}
          onBlur={(e) => (e.target.style.border = "2px solid #DADBDA")}
        />
      </div>
      <div className="mt-3">
        <div className=" fs-xs ">Password</div>

        <input
          className="w-100  mt-1 py-2 px-2 fs-sm"
          type="password"
          value={tempNewPassword}
          style={{ border: "2px solid #DADBDA", borderRadius: "14px" }}
          onChange={(e) => setTempNewPassword(e.target.value)}
          onFocus={(e) => (e.target.style.border = "2px solid #525252")}
          onBlur={(e) => (e.target.style.border = "2px solid #DADBDA")}
        />
      </div>
      <div className=" d-flex justify-content-end  my-4  ">
        {updatingPassword ? (
          <button
            onClick={savePassword}
            className={`bg-${myData.theme} text-white fs-xs border-0 rounded-3 px-4 col-sm-auto  col-11 fixed-bottom   mb-4  mx-auto mx-sm-0 d-sm-none`}
          >
            <img
              src="/Rolling@1x-1.1s-200px-200px.svg"
              alt="loading.."
              style={{
                width: "20px",
                height: "20px",
              }}
            ></img>
          </button>
        ) : (
          <button
            onClick={savePassword}
            className={`bg-${myData.theme} text-white fs-xs border-0 rounded-3 py-2 px-4 col-sm-auto  col-11 fixed-bottom   mb-4  mx-auto mx-sm-0 d-sm-none`}
          >
            <div className="mx-1">Save</div>
          </button>
        )}

        {updatingPassword ? (
          <button
            onClick={savePassword}
            className={`bg-${myData.theme} text-white fs-xs border-0 rounded-3 py-2 px-4 col-sm-auto    mb-4  mx-auto mx-sm-0 d-none d-sm-block `}
          >
            <img
              src="/Rolling@1x-1.1s-200px-200px.svg"
              alt="loading.."
              style={{
                width: "20px",
                height: "20px",
              }}
            ></img>
          </button>
        ) : (
          <button
            onClick={savePassword}
            className={`bg-${myData.theme} text-white fs-xs border-0 rounded-3 py-2 px-4 col-sm-auto    mb-4  mx-auto mx-sm-0 d-none d-sm-block `}
          >
            <div className="mx-1">Save</div>
          </button>
        )}
      </div>
    </div>
  );
}

export default Password;
