import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  TextField,
  Grid2 as Grid,
  Paper,
  Divider,
  Card,
  CardContent,
  CardActions,
  Stepper,
  Step,
  StepLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormGroup,
  Slider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Chip,
  Tooltip,
  styled,
  Backdrop,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Alert,
  AlertTitle,
} from "@mui/material";
import { 
  InfoOutlined,
  CloudUpload,
  Delete,
  Description,
  Send,
  Check,
  ArrowForward,
  ArrowBack,
  FolderOpen,
  School,
  Work,
  EmojiEvents,
  CardMembership,
  Assignment,
  LocationOn,
  Search,
  Password,
  WorkOutline,
  ViewList as ViewListIcon
} from "@mui/icons-material";
import { toast } from "react-toastify";
import {
  getQuestions,
  getUserResumes,
  getUserQuestionnaire,
  updateUserQuestionnaire,
  startAutoApply,
} from "../../services/autoApply.js";

import FileDropZone from "../../components/FileDropZone.tsx";
import ResumeDownload from './ResumeDownload.jsx';
import Autoapply_multisel from "./Autoapply_multisel.jsx";

// Styled components
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const StyledStepConnector = styled('div')(({ theme }) => ({
  height: 2,
  backgroundColor: theme.palette.divider,
  flex: 1,
}));

const StepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: ownerState.active ? theme.palette.primary.main : theme.palette.grey[300],
  zIndex: 1,
  color: '#fff',
  width: 30,
  height: 30,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
}));

const sectionIcons = {
  "Education": <School />,
  "Experience & Skills": <Work />,
  "Certifications": <CardMembership />,
  "Achievements": <EmojiEvents />,
  "Other Activities": <Assignment />,
};

const AutoApply = ({ onSubmitSuccess, viewAllJobs, maxAllowedJobs }) => {
  // State management
  const [linkedinPassword, setLinkedinPassword] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [locationType, setLocationType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [sections, setSections] = useState([]);
  const [openQuestionnaire, setOpenQuestionnaire] = useState(false);
  const [questionnaireData, setQuestionnaireData] = useState([]);
  const [resume, setResume] = useState(null);
  const [resumeList, setResumeList] = useState([]);
  const [resumeOptimizationState, setResumeOptimizationState] = useState(false);
  const [threshold, setThreshold] = useState(85);

  // New state for resume confirmation
  const [showResumeConfirmation, setShowResumeConfirmation] = useState(false);
  const [pendingResumeFile, setPendingResumeFile] = useState(null);

  // Calculate the max jobs to apply (maximum of 5)
  const getMaxJobsAllowed = () => {
    if (!maxAllowedJobs) return 0; // Return 0 if no queries available
    
    // If maxAllowedJobs > 5, cap at 5 (don't use modulo)
    return maxAllowedJobs > 5 ? 5 : maxAllowedJobs;
  };

  // Add state for number of jobs to apply with max of 5
  const [numJobsToApply, setNumJobsToApply] = useState(getMaxJobsAllowed());

  // Add button-specific loading state
  const [resumeButtonLoading, setResumeButtonLoading] = useState(false);

  // Load initial data
  useEffect(() => {
    async function getResumes() {
      setLoading(true);
      const get_user_resumes = await getUserResumes();
      if (get_user_resumes) {
        setResumeList(get_user_resumes.reverse());
        const get_user_questionnaire = await getUserQuestionnaire();
        if (get_user_questionnaire) {
          setQuestionnaireData(get_user_questionnaire);
        }
      }
      setLoading(false);
    }
    getResumes();
  }, []);

  // Update numJobsToApply when maxAllowedJobs changes
  useEffect(() => {
    // Set numJobsToApply based on the calculated max allowed jobs
    setNumJobsToApply(getMaxJobsAllowed());
  }, [maxAllowedJobs]);

  // Form validation
  const checkSubmitDisabled = () => {
    return loading || !linkedinPassword || !searchQuery || !searchLocation || locationType.length === 0 || !numJobsToApply;
  };

  // Modified resume upload handler
  async function handleResumeUpload(file) {
    if (file != null) {
      setPendingResumeFile(file);
      setShowResumeConfirmation(true);
    }
  }

  // New function to handle resume confirmation
  const handleResumeConfirm = async () => {
    if (pendingResumeFile) {
      setResumeButtonLoading(true); // Set button loading state
      setLoading(true);
      setQuestionnaireData([]);
      setResume(pendingResumeFile);
      
      try {
        // Now call the API to get questions
        const question_data = await getQuestions(pendingResumeFile);
        if (question_data) {
          setQuestionnaireData(question_data);
          const get_user_resumes = await getUserResumes();
          if (get_user_resumes) setResumeList(get_user_resumes.reverse());
        }
      } catch (error) {
        console.error("Error processing resume:", error);
        toast.error("An error occurred while processing your resume.");
      } finally {
        setLoading(false);
        setResumeButtonLoading(false); // Reset button loading state
        setShowResumeConfirmation(false);
        setPendingResumeFile(null);
      }
    }
  };

  // New function to handle resume cancellation
  const handleResumeCancel = () => {
    setShowResumeConfirmation(false);
    setPendingResumeFile(null);
    toast.info("Resume upload cancelled");
  };

  const handleRemoveResume = () => {
    setResume(null);
    setQuestionnaireData([]);
    toast.info("Resume removed successfully");
  };

  const handleNextStep = async () => {
    if (step === questionnaireData.length - 1) {
      await updateUserQuestionnaire(questionnaireData);
      setStep(0);
      setOpenQuestionnaire(false);
      toast.success("Questionnaire completed successfully!");
    } else {
      const nextStep = Math.min(questionnaireData.length - 1, step + 1);
      setStep(nextStep);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const result = await startAutoApply(
        searchQuery,
        searchLocation,
        linkedinPassword,
        locationType,
        resumeOptimizationState,
        threshold,
        numJobsToApply
      );

      // Redirect to LiveView with session ID
      if (onSubmitSuccess) {
        if (result && result.session_id) {
          onSubmitSuccess({
            session_id: result.session_id,
            search_query: searchQuery,
            search_location: searchLocation,
            location_type: locationType,
            resume_optimization: resumeOptimizationState,
            job_apply_threshold: threshold,
            num_jobs_to_apply: numJobsToApply
          });
        } else {
          // If no session ID, just show the jobs list
          // toast.warning("No session ID returned. Showing all jobs.");
          onSubmitSuccess();
        }
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      toast.error("Error during form submission");
    } finally {
      setLoading(false);
    }
  };

  const handleRadioChange = (groupId, questionIndex, value) => {
    const tempQuestionnaire = [...questionnaireData];
    if (tempQuestionnaire[groupId] && tempQuestionnaire[groupId].questions[questionIndex]) {
      tempQuestionnaire[groupId].questions[questionIndex].response = value;
      setQuestionnaireData(tempQuestionnaire);
    }
  };

  const handleTextChange = (groupId, questionIndex, value) => {
    setQuestionnaireData((prevData) =>
      prevData.map((group, gIndex) =>
        gIndex === groupId
          ? {
              ...group,
              questions: group.questions?.map((question, qIndex) =>
                qIndex === questionIndex ? { ...question, response: value } : question
              ),
            }
          : group
      )
    );
  };

  // Custom render functions
  const renderRadioQuestions = (q, grp_id, q_id) => {
    let options = [];
    if (q.options) {
      options = q.options.split(", ");
    } else {
      options = ["Yes", "No"];
    }
    
    return (
      <FormControl component="fieldset" fullWidth sx={{ mt: 2, mb: 3 }}>
        <RadioGroup
          name={`question-${grp_id}-${q_id}`}
          value={q.response || ""}
          onChange={(e) => handleRadioChange(grp_id, q_id, e.target.value)}
          row
        >
          {options.map((o, idx) => (
            <FormControlLabel
              key={idx}
              value={o}
              control={<Radio color="primary" />}
              label={o}
              sx={{ mr: 3 }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  };

  const renderQuestions = (group_id, questions) => {
    return (
      <Box
        sx={{
          maxHeight: "50vh",
          overflowY: "auto",
          p: 3,
          borderRadius: 2,
          bgcolor: "background.paper",
          boxShadow: "inset 0 0 8px rgba(0,0,0,0.1)"
        }}
      >
        {questions.map((q, idx) => (
          <Box
            key={idx}
            sx={{
              mb: 4,
              p: 2,
              borderRadius: 2,
              border: "1px solid",
              borderColor: "divider",
              bgcolor: "background.paper",
            }}
          >
            <Typography variant="subtitle1" fontWeight={500} gutterBottom>
              {idx + 1}. {q.question}
            </Typography>
            
            {q.type === "radio" && renderRadioQuestions(q, group_id, idx)}

            {q.type === "text" && (
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={q.response || ""}
                onChange={(e) => handleTextChange(group_id, idx, e.target.value)}
                sx={{ mt: 2 }}
              />
            )}
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: { xs: 2, sm: 3, md: 4 }, borderRadius: 2, bgcolor: "#fbfcfe" }}>
        {/* Header with title and View All Jobs button */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 4,
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 2, sm: 0 }
        }}>
          <Typography 
            variant="h4" 
            component="h1" 
            sx={{ 
              fontWeight: 700, 
              color: "primary.main",
              textAlign: { xs: 'center', sm: 'left' }
            }}
          >
          Auto Apply Profile Setup
        </Typography>
          
          <Button
            variant="outlined"
            color="primary"
            onClick={viewAllJobs}
            startIcon={<ViewListIcon />}
            sx={{ 
              borderRadius: '20px', 
              fontSize: '0.8rem',
              textTransform: 'uppercase',
              fontWeight: 500,
              px: 2,
              whiteSpace: 'nowrap'
            }}
          >
            View All Jobs
          </Button>
        </Box>

        <Backdrop open={loading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress color="primary" />
        </Backdrop>

        {/* Main content section - horizontal layout with two cards */}
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' }, 
          gap: { xs: 3, sm: 4, md: 5 },
          mb: 4
        }}>
          {/* Resume Management - left card */}
          <Box sx={{ flex: { md: '1 1 40%' } }}>
            <Card 
              variant="outlined" 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
              }}
            >
              <CardContent sx={{ 
                height: '100%',
                display: 'flex', 
                flexDirection: 'column', 
                p: { xs: 2, sm: 3 },
                "&:last-child": {
                  pb: { xs: 2, sm: 3 }
                }
              }}>
                <Typography variant="h6" component="h2" sx={{ 
                  mb: 3,
                  display: 'flex', 
                  alignItems: 'center',
                  fontSize: { xs: '1.1rem', sm: '1.3rem' }
                }}>
                  <Description sx={{ mr: 1 }} /> Resume Management
                </Typography>
                
                <Box sx={{ 
                  p: { xs: 2, sm: 4 },
                  borderRadius: 2, 
                  bgcolor: '#f8f9fa', 
                  border: '1px dashed', 
                  borderColor: 'divider',
                  mb: 3,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: { xs: '150px', sm: '180px' }
                }}>
                  <FileDropZone onFileUpload={handleResumeUpload} />
                </Box>
                
                {resumeList && resumeList.length > 0 ? (
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    flex: 1,
                    minHeight: 0
                  }}>
                    <Typography variant="subtitle1" sx={{ 
                      mb: 2,
                      fontSize: { xs: '1rem', sm: '1.1rem' }
                    }}>
                      Your Saved Resumes
                    </Typography>
                    <Box sx={{ 
                      flex: 1,
                      maxHeight: { xs: '250px', sm: '300px', md: '350px' },
                      overflowY: 'auto',
                      border: '1px solid #e0e0e0',
                      borderRadius: '4px',
                      bgcolor: '#f9f9f9',
                      p: { xs: 1, sm: 2 },
                      position: 'relative',
                      '&::-webkit-scrollbar': {
                        width: '8px',
                      },
                      '&::-webkit-scrollbar-track': {
                        backgroundColor: '#f1f1f1',
                        borderRadius: '10px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#c1c1c1',
                        borderRadius: '10px',
                        '&:hover': {
                          backgroundColor: '#a8a8a8',
                        },
                      },
                      scrollbarWidth: 'thin',
                      scrollbarColor: '#c1c1c1 #f1f1f1',
                    }}>
                      <ResumeDownload resumes={resumeList} />
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ mt: 'auto' }}>
                    <Alert severity="info" sx={{ mb: 0 }}>
                      Please upload your resume to generate the questionnaire
                    </Alert>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Box>

          {/* Job Search Form - right card */}
          <Box sx={{ flex: { md: '1 1 60%' } }}>
            <Card 
              variant="outlined" 
              sx={{ 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
              }}
            >
              <CardContent sx={{ 
                height: '100%',
                display: 'flex', 
                flexDirection: 'column', 
                p: { xs: 2, sm: 3 },
                "&:last-child": {
                  pb: { xs: 2, sm: 3 }
                }
              }}>
                <Typography variant="h6" component="h2" sx={{ 
                  mb: 3, 
                  display: 'flex', 
                  alignItems: 'center',
                  fontSize: { xs: '1.1rem', sm: '1.3rem' }
                }}>
                  <Search sx={{ mr: 1 }} /> Job Search Criteria
                </Typography>
                
                <Box 
                  component="form" 
                  onSubmit={(e) => e.preventDefault()}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                  }}
                >
                  <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
                    {/* Job Role Search */}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Job Role Search"
                        placeholder="E.g., Software Engineer, Product Manager"
                        variant="outlined"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        required
                        InputProps={{
                          startAdornment: <Search color="action" sx={{ mr: 1 }} />,
                        }}
                      />
                    </Grid>
                    
                    {/* Location and LinkedIn Password */}
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Location"
                        placeholder="E.g., New York, Remote"
                        variant="outlined"
                        value={searchLocation}
                        onChange={(e) => setSearchLocation(e.target.value)}
                        required
                        InputProps={{
                          startAdornment: <LocationOn color="action" sx={{ mr: 1 }} />,
                        }}
                      />
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        type="password"
                        label="LinkedIn Password"
                        placeholder="Enter your LinkedIn password"
                        variant="outlined"
                        value={linkedinPassword}
                        onChange={(e) => setLinkedinPassword(e.target.value)}
                        required
                        InputProps={{
                          startAdornment: <Password color="action" sx={{ mr: 1 }} />,
                        }}
                      />
                    </Grid>
                    
                    {/* Job Type */}
                    <Grid item xs={12} md={6}>
                      <FormControl sx={{ minWidth: '250px' }}>
                        <Autoapply_multisel 
                          locationType={locationType} 
                          setLocationType={setLocationType} 
                        />
                      </FormControl>
                    </Grid>
                    
                    {/* Job Apply Threshold and Number of Jobs to Apply */}
                    <Grid item xs={12} md={6} sx={{ width: '100%', mb: 2 }}>
                      <Typography variant="body1" sx={{ mb: 1.5, display: 'flex', alignItems: 'center', height: '24px' }}>
                        Job Apply Threshold
                        <Tooltip title="Set a value between 0 and 85. This determines the minimum match score required for the system to apply to a job. Higher values mean stricter job filtering.">
                          <InfoOutlined fontSize="small" sx={{ ml: 1, color: 'primary.main' }} />
                        </Tooltip>
                      </Typography>
                      
                      <Box sx={{ px: 2, width: '100%', height: '60px', display: 'flex', alignItems: 'center' }}>
                        <Slider
                          value={threshold}
                          min={0}
                          max={85}
                          step={1}
                          onChange={(_, value) => setThreshold(value)}
                          valueLabelDisplay="auto"
                          marks={[
                            { value: 0, label: 'Any' },
                            { value: 60, label: 'Medium' },
                            { value: 85, label: 'Perfect' },
                          ]}
                          sx={{
                            width: '100%',
                            '& .MuiSlider-track': {
                              color: '#1976d2',
                            },
                            '& .MuiSlider-rail': {
                              color: '#e0e0e0',
                              opacity: 1
                            }
                          }}
                        />
                      </Box>
                      
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 1.5, textAlign: 'center', height: '20px' }}>
                        Current Value: {threshold}%
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={6} sx={{ width: '100%', mb: 2 }}>
                      <Typography variant="body1" sx={{ mb: 1.5, display: 'flex', alignItems: 'center', height: '24px' }}>
                        Number of Jobs to Apply
                        <Tooltip title="Set the number of successful applications to be sent (maximum 5). If you have more than 5 queries available, the system will still apply to a maximum of 5 jobs that meet your criteria.">
                          <InfoOutlined fontSize="small" sx={{ ml: 1, color: 'primary.main' }} />
                        </Tooltip>
                      </Typography>
                      
                      <Box sx={{ px: 2, width: '100%', height: '60px', display: 'flex', alignItems: 'center' }}>
                        <Slider
                          value={numJobsToApply}
                          min={0}
                          max={5}
                          step={1}
                          onChange={(_, value) => {
                            const maxJobs = getMaxJobsAllowed();
                            if (value <= maxJobs) {
                              setNumJobsToApply(value);
                            }
                          }}
                          valueLabelDisplay="auto"
                          marks={[
                            { value: 0, label: '0' },
                            { value: getMaxJobsAllowed(), label: `${getMaxJobsAllowed()}` },
                            { value: 5, label: '5' }
                          ]}
                          disabled={maxAllowedJobs === 0}
                          sx={{
                            width: '100%',
                            '& .MuiSlider-track': {
                              color: '#1976d2',
                            },
                            '& .MuiSlider-rail': {
                              color: '#e0e0e0',
                              opacity: 1
                            },
                            '& .MuiSlider-rail::after': {
                              content: '""',
                              position: 'absolute',
                              left: `${(getMaxJobsAllowed() / 5) * 100}%`,
                              width: `${100 - (getMaxJobsAllowed() / 5) * 100}%`,
                              height: '100%',
                              backgroundColor: '#f1f1f1',
                              backgroundImage: 'repeating-linear-gradient(45deg, #d9d9d9, #d9d9d9 5px, #e6e6e6 5px, #e6e6e6 10px)',
                              opacity: 0.7,
                              pointerEvents: 'none'
                            }
                          }}
                        />
                      </Box>
                      
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 1.5, textAlign: 'center', height: '20px' }}>
                        Current Value: {numJobsToApply} jobs (Max allowed: {getMaxJobsAllowed()})
                      </Typography>
                    </Grid>
                    
                    {/* Resume Customization */}
                    <Grid item xs={12}>
                      <Typography variant="body1" sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                        Resume Customization (Coming Soon)
                        <Tooltip title="Automatically customizes your resume for each job description to highlight relevant skills and experience, increasing your chances of getting an interview.">
                          <InfoOutlined fontSize="small" sx={{ ml: 1, color: 'primary.main' }} />
                        </Tooltip>
                      </Typography>
                      
                      <RadioGroup
                        row
                        value={resumeOptimizationState}
                        onChange={(e) => setResumeOptimizationState(e.target.value === 'true')}
                      >
                        <FormControlLabel 
                          value={true} 
                          control={<Radio color="primary" />} 
                          disabled
                          label="Enable" 
                          />
                        <FormControlLabel 
                          value={false} 
                          control={<Radio color="primary" />} 
                          label="Disable" 
                          disabled
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>

        {/* Buttons container with card-like styling */}
        <Paper 
          elevation={2} 
          sx={{ 
            mt: 4, 
            p: { xs: 2, sm: 3, md: 4 },
            borderRadius: 2, 
            bgcolor: "#f8f9fa",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 2, sm: 3 },
            boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
          }}
        >

          {/* Moved Ready to Go alert here */}
          {questionnaireData.length > 0 && (
            <Alert severity="success" sx={{ width: '100%', mb: 2 }}>
              <AlertTitle>Ready to Go!</AlertTitle>
              Your profile is complete and ready for auto-applying to jobs. Make sure to check the job search criteria and the questionnaire before starting the auto-apply process.
            </Alert>
          )}
          
          {questionnaireData.length === 0 && resumeList.length > 0 && (
            <Alert severity="warning" sx={{ width: '100%', mb: 2 }}>
              <AlertTitle>Action Required</AlertTitle>
              Please complete the questionnaire for best results
            </Alert>
          )}

          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, width: '100%', justifyContent: 'center' }}>
            {/* Complete Questionnaire button */}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpenQuestionnaire(true)}
              disabled={questionnaireData.length === 0}
              startIcon={<Assignment />}
              sx={{ 
                py: 1.5, 
                px: 4, 
                borderRadius: 2, 
                fontSize: '0.9rem',
                width: { xs: '100%', sm: 'auto' },
                minWidth: '220px'
              }}
            >
              Complete Questionnaire
            </Button>

            {/* Start Auto Apply button */}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={checkSubmitDisabled()}
                      startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <Send />}
                      sx={{
                        py: 1.5,
                        px: 4,
                        borderRadius: 2,
                        boxShadow: 2,
                        fontSize: '0.9rem',
                        width: { xs: '100%', sm: 'auto' },
                        minWidth: '220px',
                        bgcolor: '#5fcf80',
                        '&:hover': {
                          bgcolor: '#4caf50',
                        },
                      }}
                    >
                      {loading ? "Processing..." : "Start Auto Apply"}
                    </Button>
                  </Box>
        </Paper>

      </Paper>

      {/* Questionnaire Dialog */}
      <Dialog
        open={openQuestionnaire}
        onClose={() => setOpenQuestionnaire(false)}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            borderRadius: 2,
            overflow: 'hidden'
          }
        }}
      >
        <DialogTitle sx={{ 
          bgcolor: 'primary.main', 
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          <Assignment />
          Complete Your Profile Questionnaire
        </DialogTitle>
        
        <DialogContent dividers>
          <Box sx={{ p: 2 }}>
            <Stepper activeStep={step} alternativeLabel>
              {questionnaireData.map((section, index) => (
                <Step key={section.group_id} completed={index < step}>
                  <StepLabel
                    StepIconProps={{
                      icon: (
                        <StepIconRoot ownerState={{ active: step === index }}>
                          {index < step ? <Check /> : sectionIcons[section.group] || <Assignment />}
                        </StepIconRoot>
                      ),
                    }}
                  >
                    {section.group}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            
            {questionnaireData[step] && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom sx={{ 
                  color: 'primary.main',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}>
                  {sectionIcons[questionnaireData[step].group] || <Assignment />}
                  {questionnaireData[step].group}
                </Typography>
                
                <Divider sx={{ mb: 3 }} />
                
                {renderQuestions(step, questionnaireData[step].questions)}
              </Box>
            )}
          </Box>
        </DialogContent>
        
        <DialogActions sx={{ p: 2, bgcolor: 'background.paper' }}>
          <Button
            variant="outlined"
            onClick={() => setStep(Math.max(0, step - 1))}
            disabled={step === 0}
            startIcon={<ArrowBack />}
          >
            Back
          </Button>
          
          <Button
            variant="contained"
            onClick={handleNextStep}
            endIcon={step === questionnaireData.length - 1 ? <Check /> : <ArrowForward />}
          >
            {step === questionnaireData.length - 1 ? "Complete" : "Next"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Resume Confirmation Dialog */}
      <Dialog
        open={showResumeConfirmation}
        onClose={handleResumeCancel}
        aria-labelledby="resume-confirmation-dialog"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="resume-confirmation-dialog">
          Confirm Resume Selection
        </DialogTitle>
        <DialogContent>
          <Box sx={{ my: 2 }}>
            <Typography variant="body1" gutterBottom>
              You've selected the following resume:
            </Typography>
            <Box sx={{ 
              p: 2, 
              bgcolor: 'rgba(0, 0, 0, 0.04)', 
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center'
            }}>
              <Description sx={{ mr: 1, color: 'primary.main' }} />
              <Typography variant="body2" fontWeight="medium">
                {pendingResumeFile?.name || "Unknown file"}
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
              Our system will analyze this resume to create a customized questionnaire for you. Would you like to proceed with this resume?
            </Typography>
          </Box>
          
          {resumeButtonLoading && (
            <Box sx={{ mt: 2, p: 2, bgcolor: 'primary.light', color: 'primary.contrastText', borderRadius: 1 }}>
              <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                <CircularProgress size={16} thickness={4} sx={{ mr: 1, color: 'inherit' }} />
                Generating a customized questionnaire based on your resume information. This helps us match you with the most relevant job opportunities.
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResumeCancel} color="inherit" disabled={resumeButtonLoading}>
            Cancel
          </Button>
          <Button 
            onClick={handleResumeConfirm} 
            variant="contained" 
            color="primary"
            disabled={resumeButtonLoading}
            startIcon={resumeButtonLoading ? <CircularProgress size={20} color="inherit" /> : <Check />}
            sx={{ whiteSpace: 'nowrap' }}
          >
            {resumeButtonLoading ? "Generating Questionnaire..." : "Yes, Use This Resume"}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AutoApply;
