import * as React from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { Card, CardContent, Grid } from "@mui/material"
import LooksOneIcon from '@mui/icons-material/LooksOne'
import LooksTwoIcon from '@mui/icons-material/LooksTwo'
import Looks3Icon from '@mui/icons-material/Looks3'

const steps = [
  {
    title: "Fill out your profile once",
    description: "Create your profile with your career preferences and requirements.",
    icon: <LooksOneIcon sx={{ fontSize: 40, color: "#5fcf80" }} />
  },
  {
    title: "AI Agent searches for opportunities",
    description: "Our AI Agent searches LinkedIn and matches you to jobs above a certain score threshold (posted only within the past week).",
    icon: <LooksTwoIcon sx={{ fontSize: 40, color: "#5fcf80" }} />
  },
  {
    title: "Customized applications",
    description: "It then submits a customized resume for each job application, actually helping you land your next role (unlike other AI auto-apply tools out there)!",
    icon: <Looks3Icon sx={{ fontSize: 40, color: "#5fcf80" }} />
  }
]

export default function HowToUse() {
  return (
    <Box sx={{ maxWidth: "80rem", margin: "auto", padding: "2rem" }}>
      <Grid container spacing={4} justifyContent="center">
        {steps.map((step, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 2,
                '&:hover': {
                  boxShadow: 6,
                  transform: 'translateY(-4px)',
                  transition: 'all 0.3s ease-in-out'
                }
              }}
            >
              <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                <Box sx={{ mb: 2 }}>
                  {step.icon}
                </Box>
                <Typography variant="h5" component="h3" gutterBottom sx={{ color: "#5fcf80", fontWeight: 'bold' }}>
                  {step.title}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {step.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Typography
        variant="body1"
        sx={{
          textAlign: "center",
          mt: 4,
          color: "text.secondary",
          fontStyle: "italic"
        }}
      >
        We are starting with LinkedIn, but will integrate other job boards soon. We also have an awesome people search feature for recruiters!
      </Typography>
    </Box>
  )
}
