// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add this CSS file to your project */
@media (hover: hover) and (pointer: fine) {
    .user-card:hover {
      background-color: #EEFBF3; /* Hover state color */
    }
  }
  
  .user-card {
    background-color: #F8F8F8; /* Default state color */
    width: 290px;
    transition: background-color 0.3s;
  }
  `, "",{"version":3,"sources":["webpack://./src/css/UserCardSmall.css"],"names":[],"mappings":"AAAA,sCAAsC;AACtC;IACI;MACE,yBAAyB,EAAE,sBAAsB;IACnD;EACF;;EAEA;IACE,yBAAyB,EAAE,wBAAwB;IACnD,YAAY;IACZ,iCAAiC;EACnC","sourcesContent":["/* Add this CSS file to your project */\n@media (hover: hover) and (pointer: fine) {\n    .user-card:hover {\n      background-color: #EEFBF3; /* Hover state color */\n    }\n  }\n  \n  .user-card {\n    background-color: #F8F8F8; /* Default state color */\n    width: 290px;\n    transition: background-color 0.3s;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
