import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
    Box,
    Button,
    TextField,
    Typography,
    Paper,
    Container,
    Divider,
    CircularProgress,
    Alert,
    MenuItem,
    FormControl,
    InputLabel,
    Select
} from '@mui/material';
import { baseUrl } from '../services/api';
import logo from '../assets/logo.png';
import appsumoLogo from '../assets/appsumo-logo.png';

// Sample industry options - replace with your actual options
const industries = [
    'Technology',
    'Healthcare',
    'Finance',
    'Education',
    'Manufacturing',
    'Retail',
    'Other'
];

// Sample country options - you might want to use a more comprehensive list
const countries = [
    'United States',
    'Canada',
    'United Kingdom',
    'Australia',
    'Germany',
    'France',
    'Other'
];

const AppSumoRedeem = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [appsumoCode, setAppsumoCode] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [name, setName] = useState('');
    const [country, setCountry] = useState('');
    const [industry, setIndustry] = useState('');
    const [gender, setGender] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const validateForm = () => {
        // Reset error message
        setError('');

        // Check if AppSumo code is provided
        if (!appsumoCode.trim()) {
            setError('Please enter your AppSumo code');
            return false;
        }

        // Check if name is provided
        if (!name.trim()) {
            setError('Please enter your name');
            return false;
        }

        // Check if email is provided and valid
        const emailPattern = /\S+@\S+\.\S+/;
        if (!email.trim() || !emailPattern.test(email)) {
            setError('Please enter a valid email address');
            return false;
        }

        // Check password length
        if (password.length < 8) {
            setError('Password must be at least 8 characters long');
            return false;
        }

        // Check if passwords match
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return false;
        }

        // Check if country is selected
        if (!country) {
            setError('Please select your country');
            return false;
        }

        // Check if industry is selected
        if (!industry) {
            setError('Please select your industry');
            return false;
        }

        // Check if gender is selected
        if (!gender) {
            setError('Please select your gender');
            return false;
        }

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        setIsLoading(true);

        try {
            const payload = {
                appsumo_code: appsumoCode,
                email: email,
                password: password,
                name: name,
                country: country,
                industry: industry,
                gender: gender,
                image: "" // Default empty string for image
            };

            const response = await axios.post(`${baseUrl}/auth/appsumo-redeem-new`, payload);

            if (response.data.success) {
                setSuccess(response.data.message || 'Account created and code successfully redeemed! You now have lifetime access to the recruiter features.');
                toast.success('AppSumo code redeemed successfully!');

                // If the user is logged in automatically, store their token and redirect
                if (response.data.token) {
                    sessionStorage.setItem("authToken", response.data.token);

                    if (response.data.user) {
                        localStorage.setItem("userType", "recruiter");
                        localStorage.setItem("userId", response.data.user.id);
                        localStorage.setItem("userName", response.data.user.name);
                        localStorage.setItem("userObject", JSON.stringify(response.data));
                        localStorage.setItem("isPaid", true);
                    }

                    // Redirect after a short delay to show success message
                    setTimeout(() => {
                        navigate('/recruiter/peoplesearch');
                    }, 3000);
                } else {
                    // If not auto-logged in, direct to sign in page after success message
                    setTimeout(() => {
                        navigate('/signin');
                    }, 3000);
                }
            } else {
                setError(response.data.message || 'Failed to redeem code. Please check your information and try again.');
            }
        } catch (error) {
            console.error('AppSumo redemption error:', error);
            if (error.response && error.response.data && error.response.data.message) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred while redeeming your code. Please try again later.');
            }
            toast.error('Failed to redeem AppSumo code');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                bgcolor: '#f9f9f9'
            }}
        >
            {/* Header */}
            <Box
                component="header"
                sx={{
                    p: 2,
                    bgcolor: 'white',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Link to="/">
                    <img src={logo} alt="CareerGPT Logo" style={{ height: '40px' }} />
                </Link>
            </Box>

            {/* Main content */}
            <Container
                maxWidth="md"
                sx={{
                    flex: 1,
                    py: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        p: 4,
                        width: '100%',
                        maxWidth: 600,
                        borderRadius: 2
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 3 }}>
                        <Typography variant="h4" sx={{ fontWeight: 700, color: '#333', mr: 2 }}>
                            AppSumo
                        </Typography>
                        <img
                            src={appsumoLogo}
                            alt="AppSumo Logo"
                            style={{ height: '40px' }}
                            onError={(e) => {
                                e.target.style.display = 'none';
                            }}
                        />
                    </Box>

                    <Typography variant="h5" align="center" sx={{ mb: 1, color: '#5fcf80', fontWeight: 500 }}>
                        Redeem Your Lifetime Access
                    </Typography>

                    <Typography variant="body2" align="center" sx={{ mb: 3, color: 'text.secondary' }}>
                        Create your recruiter account and activate your lifetime access with your AppSumo code
                    </Typography>

                    {error && (
                        <Alert severity="error" sx={{ mb: 3 }}>
                            {error}
                        </Alert>
                    )}

                    {success && (
                        <Alert severity="success" sx={{ mb: 3 }}>
                            {success}
                        </Alert>
                    )}

                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="appsumoCode"
                            label="AppSumo Code"
                            name="appsumoCode"
                            value={appsumoCode}
                            onChange={(e) => setAppsumoCode(e.target.value)}
                            autoFocus
                        />

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Full Name"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />

                        <FormControl fullWidth margin="normal" required>
                            <InputLabel id="country-label">Country</InputLabel>
                            <Select
                                labelId="country-label"
                                id="country"
                                value={country}
                                label="Country"
                                onChange={(e) => setCountry(e.target.value)}
                            >
                                {countries.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth margin="normal" required>
                            <InputLabel id="industry-label">Industry</InputLabel>
                            <Select
                                labelId="industry-label"
                                id="industry"
                                value={industry}
                                label="Industry"
                                onChange={(e) => setIndustry(e.target.value)}
                            >
                                {industries.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth margin="normal" required>
                            <InputLabel id="gender-label">Gender</InputLabel>
                            <Select
                                labelId="gender-label"
                                id="gender"
                                value={gender}
                                label="Gender"
                                onChange={(e) => setGender(e.target.value)}
                            >
                                <MenuItem value="male">Male</MenuItem>
                                <MenuItem value="female">Female</MenuItem>
                                <MenuItem value="other">Other</MenuItem>
                                <MenuItem value="prefer_not_to_say">Prefer not to say</MenuItem>
                            </Select>
                        </FormControl>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={isLoading}
                            sx={{
                                mt: 3,
                                mb: 2,
                                py: 1.5,
                                bgcolor: '#5fcf80',
                                '&:hover': {
                                    bgcolor: '#56ba73',
                                },
                                color: 'white',
                                fontWeight: 600
                            }}
                        >
                            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Create Account & Redeem Code'}
                        </Button>
                    </Box>
                </Paper>
            </Container>

            {/* Footer */}
            <Box
                component="footer"
                sx={{
                    py: 3,
                    px: 2,
                    mt: 'auto',
                    backgroundColor: 'white',
                    textAlign: 'center'
                }}
            >
                <Typography variant="body2" color="text.secondary">
                    © {new Date().getFullYear()} CareerGPT. All rights reserved.
                </Typography>
            </Box>
        </Box>
    );
};

export default AppSumoRedeem; 