import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Typography,
    CircularProgress,
    Paper,
    Chip,
    Grid,
    Card,
    CardContent,
    ListItem,
    ListItemIcon,
    ListItemText,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Divider,
    CardMedia,
    IconButton,
    Tabs,
    Tab
} from '@mui/material';
import {
    ArrowBack,
    Add,
    CheckCircle,
    WorkOutline,
    LocationOn,
    FindInPage,
    BusinessCenter,
    Business,
    PlayArrow,
    ExpandMore,
    Refresh,
    FilterAlt,
    FormatListNumbered,
    AutoFixHigh,
    DeviceHub,
    WorkspacePremium
} from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';

// Custom TabPanel component for the job details tabs
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`job-tabpanel-${index}`}
        aria-labelledby={`job-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
}

// Custom component for the match score tab
const MatchScoreTab = ({ score, threshold }) => {
    // Get the appropriate color based on comparison with threshold
    const getScoreColor = (score, threshold) => {
        if (!score) return "#ed6c02"; // Default to orange if no score
        const numScore = parseInt(score);
        const numThreshold = parseInt(threshold || 85); // Default to 85% if not provided
        return numScore >= numThreshold ? "#4caf50" : "#ed6c02"; // Green if >= threshold, orange if below
    };
    
    const scoreColor = getScoreColor(score, threshold);
    
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            Match 
            <Box 
                component="span" 
                sx={{ 
                    ml: 1, 
                    color: 'white', 
                    bgcolor: scoreColor, 
                    borderRadius: '12px', 
                    px: 1, 
                    py: 0.2,
                    fontSize: '0.8rem',
                    fontWeight: 'bold'
                }}
            >
                {score || 0}%
            </Box>
        </Box>
    );
};

const LiveView = ({
    gif_url,
    sessionId,
    frontendUrl,
    getFrontendUrl,
    onNewJobClick,
    onViewAllJobsClick,
    jobDetails,
    onRefresh
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [url, setUrl] = useState('');
    const [error, setError] = useState(null);
    const [jobApplications, setJobApplications] = useState([]);
    const [expandedJob, setExpandedJob] = useState(null);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    // Determine if job is completed
    const isJobCompleted = jobDetails?.status === true;

    // Process job details and extract job applications
    useEffect(() => {
        if (jobDetails) {
            console.log('LiveView received jobDetails:', jobDetails);

            // Extract jobs from jobDetails if available using various possible paths
            let jobsArray = [];

            if (jobDetails.jobs && Array.isArray(jobDetails.jobs)) {
                jobsArray = jobDetails.jobs;
            } else if (jobDetails.individual_jobs && Array.isArray(jobDetails.individual_jobs)) {
                jobsArray = jobDetails.individual_jobs;
            } else if (jobDetails.applications && Array.isArray(jobDetails.applications)) {
                jobsArray = jobDetails.applications;
            } else if (jobDetails.jobApplications && Array.isArray(jobDetails.jobApplications)) {
                jobsArray = jobDetails.jobApplications;
            }

            // For completed jobs with no data, create sample job applications to match screenshot
            // if (jobsArray.length === 0 && isJobCompleted) {
            //     jobsArray = [
            //         {
            //             title: "Python / Django Engineer",
            //             company_name: "Jobot",
            //             location: "Newark, NJ (Remote)",
            //             status: "Skipped",
            //             description: "No description provided in the context."
            //         },
            //         {
            //             title: "Python Programmer (W2)",
            //             company_name: "PTR Global",
            //             location: "Jersey City, NJ",
            //             status: "Skipped",
            //             description: "Position: Python Developer Location: Jersey City, NJ / Brooklyn, NY / Manhattan, NY / Houston, TX (Hybrid/On-Site) Job Type: 6-13+ Months Contract to Hire (Full Time)..."
            //         },
            //         {
            //             title: "Python Developer",
            //             company_name: "GYPR Pvt Ltd",
            //             location: "Jersey City, NJ",
            //             status: "Skipped",
            //             description: "**Position: Python Developer** **Location: Jersey City, NJ / Brooklyn, NY / Manhattan, NY / Houston, TX (Hybrid/On-Site)** **Job Type: 6-13+ Months Contract."
            //         },
            //         {
            //             title: "Java Specialist",
            //             company_name: "Magnus Technology Solutions",
            //             location: "Jersey City, NJ",
            //             status: "Not Applied",
            //             description: "Magnus Technology Solutions is a leading services firm that provides consulting, outsourcing, and staffing services to a broad range of clients. The company is a..."
            //         }
            //     ];
            // }

            setJobApplications(jobsArray);
        }
    }, [jobDetails, isJobCompleted]);

    // Load live view URL if needed (only for in-progress jobs)
    useEffect(() => {
        if (isJobCompleted) {
            setIsLoading(false);
            return;
        }

        if (!sessionId) {
            setIsLoading(false);
            return;
        }

        try {
            setIsLoading(true);
            const loadLiveView = async () => {
                // Use existing URL if available or fetch new one
                const viewUrl = frontendUrl || await getFrontendUrl(sessionId);
                if (viewUrl) {
                    setUrl(viewUrl);
                    setError(null);
                } else {
                    setError('Unable to load live view');
                }
                setIsLoading(false);
            };

            loadLiveView();
        } catch (err) {
            console.error('Error loading live view:', err);
            setError('Failed to load live view');
            setIsLoading(false);
        }
    }, [sessionId, frontendUrl, getFrontendUrl, isJobCompleted]);

    // Function to get the appropriate job run GIF based on status
    const getJobRunGif = () => {
        if (isJobCompleted) {
            return gif_url;
        } else {
            return url;
        }
    };

    // Handle accordion expansion
    const handleAccordionChange = (jobId) => {
        setExpandedJob(expandedJob === jobId ? null : jobId);
    };

    // Function to handle general refresh (for the refresh button near Auto Apply Job Details)
    const handleGeneralRefresh = async () => {
        if (isRefreshing) return;
        setIsRefreshing(true);
        
        try {
            // First refresh the general reports list
            if (onRefresh) {
                await onRefresh();
            }
            
            // If not completed and we have a session ID, also refresh the frontend URL
            if (!isJobCompleted && sessionId) {
                try {
                    const viewUrl = await getFrontendUrl(sessionId);
                    if (viewUrl) {
                        setUrl(viewUrl);
                        setError(null);
                    }
                } catch (err) {
                    console.error("Error refreshing frontend URL:", err);
                }
            }
        } catch (err) {
            console.error("Error during refresh:", err);
        } finally {
            setIsRefreshing(false);
        }
    };

    // Function to handle only frontend URL refresh (for the Refresh Live View button)
    const handleRefresh = async () => {
        if (isRefreshing) return;
        setIsRefreshing(true);
        
        try {
            // Only refresh the frontend URL for the current session
            if (!isJobCompleted && sessionId) {
                try {
                    const viewUrl = await getFrontendUrl(sessionId);
                    if (viewUrl) {
                        setUrl(viewUrl);
                        setError(null);
                    }
                } catch (err) {
                    console.error("Error refreshing frontend URL:", err);
                }
            }
        } catch (err) {
            console.error("Error during refresh:", err);
        } finally {
            setIsRefreshing(false);
        }
    };

    const handleTabChange = (event, newValue) => {
        setCurrentTabIndex(newValue);
    };

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
            {/* Loading overlay */}
            {isRefreshing && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        zIndex: 1200,
                        backdropFilter: 'blur(2px)',
                    }}
                >
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center',
                        p: 3,
                        borderRadius: 2,
                        bgcolor: 'white',
                        boxShadow: 3
                    }}>
                        <CircularProgress size={50} color="primary" sx={{ mb: 2 }} />
                        <Typography variant="h6" color="primary" fontWeight="500">
                            Refreshing Data...
                        </Typography>
                    </Box>
                </Box>
            )}

            {/* Header with buttons */}
            <Paper
                elevation={3}
                sx={{
                    p: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#f8f8f8',
                    borderRadius: 0,
                    mb: 2
                }}
            >
                <Button
                    variant="outlined"
                    startIcon={<ArrowBack />}
                    onClick={onViewAllJobsClick}
                    sx={{
                        borderRadius: '20px',
                        textTransform: 'none',
                        fontWeight: 500,
                        color: '#1976d2'
                    }}
                >
                    VIEW ALL JOBS
                </Button>

                <Typography variant="h6" sx={{ fontWeight: 600, color: '#333' }}>
                    Live Job Application Process
                </Typography>

                <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={onNewJobClick}
                    sx={{
                        borderRadius: '20px',
                        backgroundColor: '#5fcf80',
                        '&:hover': {
                            backgroundColor: '#56ba73',
                        },
                        textTransform: 'none',
                        fontWeight: 500
                    }}
                >
                    CREATE NEW JOB
                </Button>
            </Paper>

            {/* Job Details in a single row */}
            <Paper 
                elevation={1} 
                sx={{
                        backgroundColor: '#f5f9ff',
                        borderRadius: '8px',
                    overflow: 'hidden',
                    mb: 2
                }}
            >
                <Box sx={{ 
                    p: 3,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start' 
                }}>
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                            <BusinessCenter sx={{ mr: 1 }} />
                            <Typography variant="h6" sx={{
                                fontWeight: 600,
                                color: '#333',
                                mr: 2
                            }}>
                                Auto Apply Job Details
                            </Typography>
                            
                            <Chip
                                label={isJobCompleted ? "Completed" : "In Progress"}
                                size="small"
                                sx={{
                                    backgroundColor: isJobCompleted ? '#4caf50' : '#ff9800',
                                    color: 'white',
                                    fontWeight: 500
                                }}
                            />
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            flexWrap: 'wrap',
                            gap: 4
                        }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '200px' }}>
                                <WorkOutline sx={{ color: '#666', mr: 2 }} />
                                <Box>
                                    <Typography variant="body2" color="text.secondary">Search Query</Typography>
                                    <Typography variant="body1" fontWeight={500}>{jobDetails?.search_query || 'Software Engineer'}</Typography>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '200px' }}>
                                <LocationOn sx={{ color: '#666', mr: 2 }} />
                                <Box>
                                    <Typography variant="body2" color="text.secondary">Location</Typography>
                                    <Typography variant="body1" fontWeight={500}>{jobDetails?.search_location || 'New York'}</Typography>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '200px' }}>
                                <DeviceHub sx={{ color: '#666', mr: 2 }} />
                                <Box>
                                    <Typography variant="body2" color="text.secondary">Location Type</Typography>
                                    <Typography variant="body1" fontWeight={500}>{jobDetails?.location_type?.join(', ') || 'N/A'}</Typography>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '200px' }}>
                                <FilterAlt sx={{ color: '#666', mr: 2 }} />
                                <Box>
                                    <Typography variant="body2" color="text.secondary">Job Threshold</Typography>
                                    <Typography variant="body1" fontWeight={500}>{jobDetails?.job_apply_threshold ? `${jobDetails.job_apply_threshold}%` : 'N/A'}</Typography>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '200px' }}>
                                <FormatListNumbered sx={{ color: '#666', mr: 2 }} />
                                <Box>
                                    <Typography variant="body2" color="text.secondary">Number of Jobs to apply</Typography>
                                    <Typography variant="body1" fontWeight={500}>{jobDetails?.num_jobs_to_apply ?? jobDetails?.jobs_to_apply ?? 'N/A'}</Typography>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '200px' }}>
                                <AutoFixHigh sx={{ color: '#666', mr: 2 }} />
                                <Box>
                                    <Typography variant="body2" color="text.secondary">Resume Optimization</Typography>
                                    <Typography variant="body1" fontWeight={500}>{jobDetails?.resume_optimization ? 'Enabled' : 'Disabled'}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    {/* Refresh button - updated to circular icon button */}
                    <IconButton
                        onClick={handleGeneralRefresh}
                        disabled={isRefreshing}
                        aria-label="refresh"
                        sx={{
                            color: '#1976d2',
                            border: '2px solid #1976d2',
                            borderRadius: '50%',
                            p: 1,
                            '&:hover': {
                                backgroundColor: 'rgba(25, 118, 210, 0.04)',
                            },
                            mt: 1 // Add a small margin to align with the heading
                        }}
                    >
                        {isRefreshing ? 
                            <CircularProgress size={24} color="primary" /> : 
                            <Refresh />
                        }
                    </IconButton>
                </Box>
            </Paper>

            {/* Job Applications Table */}
            <Paper elevation={1} sx={{ borderRadius: '8px', overflow: 'hidden', mb: 2 }}>
                                <Box sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    position: 'relative',
                                    pt: 2,
                                    pb: 1
                                }}>
                                    <Typography variant="h6" sx={{ fontWeight: 600, color: '#333' }}>
                                        Job Applications
                                    </Typography>
                                    <Box sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        height: '4px',
                                        backgroundColor: '#f44336'
                                    }} />
                                </Box>

                                {jobApplications && jobApplications.length > 0 ? (
                    <TableContainer 
                        component={Box} 
                        sx={{ 
                            p: 2,
                            maxHeight: '400px',
                            overflow: 'auto'
                        }}
                    >
                        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="job applications table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 600, backgroundColor: '#f5f5f5' }}>Status</TableCell>
                                    <TableCell sx={{ fontWeight: 600, backgroundColor: '#f5f5f5' }}>Title</TableCell>
                                    <TableCell sx={{ fontWeight: 600, backgroundColor: '#f5f5f5' }}>Company</TableCell>
                                    <TableCell sx={{ fontWeight: 600, backgroundColor: '#f5f5f5' }}>Location</TableCell>
                                    <TableCell sx={{ fontWeight: 600, backgroundColor: '#f5f5f5' }}>Match Score</TableCell>
                                    <TableCell sx={{ fontWeight: 600, backgroundColor: '#f5f5f5' }}>Should Apply</TableCell>
                                    <TableCell sx={{ fontWeight: 600, backgroundColor: '#f5f5f5' }}>Details</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                            {jobApplications.map((app, index) => {
                                                // Determine status for styling
                                    // const isApplied = app.applied || app.status === 'applied' || app.status === 'Applied';
                                    const statusColor = app.applied ? '#4caf50' : '#d32f2f';
                                    const statusLabel = app.applied ? 'Applied' : 'Not Applied';

                                                return (
                                        <React.Fragment key={index}>
                                            <TableRow 
                                                            sx={{
                                                    '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
                                                    borderLeft: `4px solid ${statusColor}`
                                                            }}
                                                        >
                                                <TableCell>
                                                                <Chip
                                                                    label={statusLabel}
                                                                    size="small"
                                                                    sx={{
                                                                        backgroundColor: statusColor,
                                                                        color: 'white',
                                                                        fontWeight: 500,
                                                                        height: '24px'
                                                                    }}
                                                                />
                                                </TableCell>
                                                <TableCell sx={{ fontWeight: 500 }}>
                                                    {app.title || app.job_title || 'Unknown Position'}
                                                </TableCell>
                                                <TableCell>
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Business fontSize="small" sx={{ color: 'text.secondary', mr: 1 }} />
                                                        {app.company_name || app.company || 'Unknown Company'}
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <LocationOn fontSize="small" sx={{ color: 'text.secondary', mr: 1 }} />
                                                        {app.location || app.job_location || 'Unknown Location'}
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        {app.match_score ? `${app.match_score}%` : 'N/A'}
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        {app.should_apply ? 'True' : 'False'}
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        size="small"
                                                        onClick={() => handleAccordionChange(index)}
                                                        endIcon={<ExpandMore />}
                                                        sx={{ textTransform: 'none' }}
                                                    >
                                                        {expandedJob === index ? 'Hide Details' : 'View Details'}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                            {expandedJob === index && (
                                                <TableRow>
                                                    <TableCell colSpan={7} sx={{ p: 0, borderBottom: 'none' }}>
                                                         <Box sx={{
                                                             backgroundColor: '#f5f5f5',
                                                             borderTop: '1px dashed #ddd'
                                                         }}>
                                                          <Tabs
                                                              value={currentTabIndex}
                                                              onChange={handleTabChange}
                                                              aria-label="job-details-tabs"
                                                              sx={{
                                                                  bgcolor: '#f5f5f5',
                                                                  '& .MuiTab-root': {
                                                                      textTransform: 'none',
                                                                      fontWeight: 500,
                                                                      fontSize: '0.9rem',
                                                                      minWidth: 120
                                                                  },
                                                                  '& .Mui-selected': {
                                                                      color: '#1976d2',
                                                                      fontWeight: 600
                                                                  },
                                                                  '& .MuiTabs-indicator': {
                                                                      backgroundColor: '#1976d2'
                                                                  },
                                                                  borderBottom: '1px solid #ddd'
                                                              }}
                                                          >
                                                              <Tab label="Job Description" />
                                                              <Tab 
                                                                  label={
                                                                      <MatchScoreTab 
                                                                          score={app.match_score} 
                                                                          threshold={jobDetails?.job_apply_threshold} 
                                                                      />
                                                                  }
                                                              />
                                                          </Tabs>
                                                          <TabPanel value={currentTabIndex} index={0} sx={{ p: 0 }}>
                                                              <Box sx={{ 
                                                                  p: 2, 
                                                                  backgroundColor: 'white', 
                                                                  borderRadius: '4px',
                                                                  border: '1px solid #e0e0e0',
                                                                  maxHeight: '300px',
                                                                  overflow: 'auto',
                                                                  m: 2
                                                              }}>
                                                                  {app.description ? (
                                                                      <ReactMarkdown>
                                                                          {app.description}
                                                                      </ReactMarkdown>
                                                                  ) : (
                                                                      <Typography variant="body2" color="text.secondary">
                                                                          No description available
                                                                      </Typography>
                                                                  )}
                                                              </Box>
                                                          </TabPanel>
                                                          <TabPanel value={currentTabIndex} index={1}>
                                                              <Box sx={{ 
                                                                  p: 2, 
                                                                  backgroundColor: 'white', 
                                                                  borderRadius: '4px',
                                                                  border: '1px solid #e0e0e0',
                                                                  maxHeight: '300px',
                                                                  overflow: 'auto',
                                                                  m: 2
                                                              }}>
                                                                  <Typography variant="subtitle2" gutterBottom>
                                                                      Match Details:
                                                                  </Typography>
                                                                  {app.match_description ? (
                                                                      <ReactMarkdown>
                                                                          {app.match_description}
                                                                      </ReactMarkdown>
                                                                  ) : (
                                                                      <Typography variant="body2" color="text.secondary">
                                                                          No match description available
                                                                      </Typography>
                                                                  )}
                                                              </Box>
                                                          </TabPanel>
                                                         </Box>
                                                      </TableCell>
                                                  </TableRow>
                                            )}
                                        </React.Fragment>
                                                );
                                            })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                                ) : (
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: 'calc(100% - 56px)',
                                        p: 3
                                    }}>
                                        <Typography variant="body1" color="text.secondary">
                                            No job application data available.
                                        </Typography>
                                    </Box>
                                )}
            </Paper>

            {/* Job Run Visualization with full width */}
            <Paper 
                elevation={1} 
                sx={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    width: '100%'
                }}
            >
                <Box sx={{ p: 3 }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 3
                    }}>
                        <Typography variant="h6" sx={{
                            fontWeight: 600,
                            display: 'flex',
                            alignItems: 'center',
                            color: '#333'
                        }}>
                            <PlayArrow sx={{ mr: 0.5, color: '#4caf50' }} />
                            {isJobCompleted ? 'Job Run Visualization' : 'Job Live View'}
                        </Typography>
                        
                        {/* Refresh button - only show for in-progress jobs */}
                        {!isJobCompleted && (
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={handleRefresh}
                                disabled={isRefreshing}
                                startIcon={isRefreshing ? <CircularProgress size={16} /> : <Refresh />}
                                sx={{ 
                                    borderRadius: '20px',
                                    textTransform: 'none',
                                    fontWeight: 500,
                                    fontSize: '0.85rem'
                                }}
                            >
                                {isRefreshing ? 'Refreshing...' : 'Refresh Live View'}
                            </Button>
                        )}
                    </Box>

                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        bgcolor: 'white',
                        borderRadius: '8px',
                        border: '1px solid #e0e0e0'
                    }}>
                        {
                            isJobCompleted ?
                            <img
                                src={getJobRunGif()}
                                alt="Job Application Run"
                                style={{
                                    minHeight: '35vh',
                                    maxWidth: '100%',
                                    objectFit: 'contain'
                                }}
                                onError={(e) => {
                                    console.log(e)
                                    e.target.src = 'https://media.giphy.com/media/WoWm8YzFQJg5i/giphy.gif';
                                    e.target.alt = 'Job application process';
                                }}
                            />
                            :
                            <CardMedia
                                component={"iframe"}
                                image={getJobRunGif()}
                                title='job-viewer'
                                sx={{
                                    minHeight: '60vh',
                                    maxWidth: '100%',
                                    objectFit: 'contain',
                                    height: '100%',
                                    width: '100%',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                                }}
                                />
                        }
                    </Box>
                </Box>
                    </Paper>
        </Box>
    );
};

export default LiveView; 
