import React, { useState } from "react";
import AuthButton from "../components/AuthButton";
import OtpInput from "react-otp-input";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getOtp, resendOtp } from "../services/candidateApi";
import { toast } from "react-toastify";

const ResetPasswordOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");

  const { data: otpData, isPending: loadingOTP } = useQuery({
    queryKey: ["otp", id],
    queryFn: () => getOtp(id),
  });

  const queryClient = useQueryClient();

  const { mutate: resendOTP, isPending } = useMutation({
    mutationFn: ({ id }) => resendOtp(id),
    onSuccess: (data) => {
      if (data) {
        toast.success("OTP has been resent");
        queryClient.invalidateQueries({
          queryKey: ["otp", id],
        });
      } else {
        toast.error("Failed to resend OTP");
      }
    },
    onError: (error) => {
      toast.error("Error resending OTP: " + (error.message || "Unknown error"));
    },
  });

  function isExpired(dateTimeString) {
    try {
      if (otpData && otpData.serverTime) {
        const serverTime = new Date(otpData.serverTime);
        const expireTime = new Date(dateTimeString);
        
        const timeDiff = expireTime.getTime() - serverTime.getTime();
        
        // Add 5-minute tolerance to account for network delays
        const toleranceMs = 5 * 60 * 1000;
        return (timeDiff + toleranceMs) <= 0;
      }
      
      // If server time is not available, return false to allow verification attempt
      return false;
    } catch (error) {
      // On error, return false to allow verification attempt
      return false;
    }
  }

  const handleResetOtp = () => {
    if (!otpData) {
      setError("Unable to get OTP data. Please try again");
      return;
    }
    
    if (!otpData.otp || !otpData.otpExpireDate) {
      setError("OTP data is incomplete. Please resend OTP");
      return;
    }
    
    if (otp.length != 6) {
      setError("Please enter a valid OTP code");
      return;
    }
    
    if (otpData.otp === otp) {
      navigate(`/reset-password-confirmation/${id}`, {
        state: { 
          verified: true,
          email: location.state?.email || ""
        }
      });
    } else {
      setError("Invalid OTP code");
      setOtp("");
    }
  };

  const handleResendOtp = () => {
    resendOTP({ id });
  };

  return (
    <div className="p-lg-3 ">
      <h3>Enter Your OTP Code</h3>
      <p className="fs-xs">
        Please enter the OTP code sent to your email address
      </p>

      <div style={{ color: "#F04438" }} className=" fs-xs">
        {error && error}
      </div>

      <div
        style={{
          width: "100%",
        }}
        className="d-flex justify-content-center align-items-center mt-3 mb-3"
      >
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
          placeholder="_"
          shouldAutoFocus={true}
          inputStyle={{
            width: "2rem",
          }}
        />
      </div>

      <div className="text-center">
        <AuthButton
          label="Resend OTP"
          onClick={handleResendOtp}
          className={`mb-3 p-2 w-100 bg-danger rounded-4 btn-lg fs-6 fw-medium`}
          style={{
            color: "#ffffff",
            border: "none",
          }}
        />
      </div>

      <div className="text-center">
        <AuthButton
          label="Confirm"
          onClick={handleResetOtp}
          className={`mb-3 p-2 w-100 rounded-4 btn-lg fs-6 fw-medium`}
          style={{
            background: otp.length != 6 ? "#F8F8F8" : "#29B475",
            color: otp.length != 6 ? "#000000" : "#ffffff",
            border: "none",
          }}
        />
      </div>
    </div>
  );
};

export default ResetPasswordOtp;
