import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getMe } from "../services/candidateApi";
import { getOneUserRating } from "../services/userApi";

export const useOneUserRating = (id) => {
  const { data: rating, isPending } = useQuery({
    queryKey: ["overallRating", id],
    queryFn: () => getOneUserRating(id),
  });

  return { rating, isPending };
};
