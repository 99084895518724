// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-section {
  width: 100%;
}

.home-banner {
  height: 330px;
  background-position: center; /* Centers the image */
  background-size: cover; /* Covers the entire area, may crop top/bottom or sides */
  background-repeat: no-repeat;
  border-radius: 15px;
  overflow: hidden;
  margin: 15px;
}

.main-layout {
  background-color: #cee9fc49;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  /* overflow: hidden; */
}
.rightMargin {
  margin-right: 50px;
}
.card-style {
  background-color: white;
  color: black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin: 10px;
}
.welcome-message {
  color: gray !important;
}
@media (max-width: 768px) {
  .home-banner {
    height: 170px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 15px;
    overflow: hidden;
    margin: 15px;
  }
  .rightMargin {
    margin-right: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/RecruiterLayout.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,2BAA2B,EAAE,sBAAsB;EACnD,sBAAsB,EAAE,yDAAyD;EACjF,4BAA4B;EAC5B,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,2BAA2B;EAC3B,6BAA6B;EAC7B,4BAA4B;EAC5B,sBAAsB;AACxB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,uBAAuB;EACvB,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,YAAY;AACd;AACA;EACE,sBAAsB;AACxB;AACA;EACE;IACE,aAAa;IACb,2BAA2B;IAC3B,wBAAwB;IACxB,4BAA4B;IAC5B,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;EACd;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".home-section {\n  width: 100%;\n}\n\n.home-banner {\n  height: 330px;\n  background-position: center; /* Centers the image */\n  background-size: cover; /* Covers the entire area, may crop top/bottom or sides */\n  background-repeat: no-repeat;\n  border-radius: 15px;\n  overflow: hidden;\n  margin: 15px;\n}\n\n.main-layout {\n  background-color: #cee9fc49;\n  border-top-right-radius: 15px;\n  border-top-left-radius: 15px;\n  /* overflow: hidden; */\n}\n.rightMargin {\n  margin-right: 50px;\n}\n.card-style {\n  background-color: white;\n  color: black;\n  border-radius: 10px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100px;\n  margin: 10px;\n}\n.welcome-message {\n  color: gray !important;\n}\n@media (max-width: 768px) {\n  .home-banner {\n    height: 170px;\n    background-position: center;\n    background-size: contain;\n    background-repeat: no-repeat;\n    border-radius: 15px;\n    overflow: hidden;\n    margin: 15px;\n  }\n  .rightMargin {\n    margin-right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
