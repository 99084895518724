import {
   Avatar,
   Box,
   Card,
   CardActions,
   CardContent,
   CardHeader,
   Collapse,
   IconButton,
   Rating,
   Stack,
   Tooltip,
   Typography,
} from "@mui/material";
import { useSessionStorage } from "@uidotdev/usehooks";
import axios from "axios";
import { useState } from "react";
import showdown from "showdown";

import ContactsIcon from "@mui/icons-material/Contacts";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PlaceIcon from "@mui/icons-material/Place";
import PolylineIcon from "@mui/icons-material/Polyline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import ContactInfoDialog from "./ContactInfoDialog";
import { baseUrl } from "../../services/api";
import { toast } from "react-toastify";

//import type { LinkedinProfileType } from "../types/linkedin_profile"

export default function PeopleCard({
   full_name,
   location,
   last_role,
   connections,
   linkedin_profile_url,
   profile_picture_url,
   public_profile_id,
   score,
   short_explanation = "No short explanation provided",
   long_explanation = "No long explanation provided",
}) {
   //: LinkedinProfileType
   const [contactInfo, setContactInfo] = useState({});
   const [isContactInfoLoading, setIsContactInfoLoading] = useState(false);
   const [isContactInfoDialogOpen, setIsContactInfoDialogOpen] =
      useState(false);
   const [linkedinCredentials] = useSessionStorage("linkedinCredentials", {});

   const converter = new showdown.Converter();

   function fetchContactInfo() {
      setIsContactInfoDialogOpen(true);
      setIsContactInfoLoading(true);

      // Show warning toast about LinkedIn's contact info limitations
      toast.info("LinkedIn limits viewing contact information. Excessive use may lead to temporary restrictions on your account.", {
         autoClose: 5000,
         position: "top-center"
      });

      axios({
         method: "POST",
         url: `${baseUrl}/linkedin/find_contact_info`,
         data: {
            public_profile_id,
            auth_body: linkedinCredentials,
         },
         headers: { "Content-Type": "application/json" },
      })
         .then((res) => {
            setContactInfo(res.data);
         })
         .catch((err) => {
            toast.error("Failed to fetch contact info, please try again later");
            setContactInfo({});
         })
         .finally(() => {
            setIsContactInfoLoading(false);
            console.log(contactInfo);
         });
   }
   const [expanded, setExpanded] = useState(true);

   const handleExpandClick = () => {
      setExpanded(!expanded);
   };
   return (
      <>
         <ContactInfoDialog
            open={isContactInfoDialogOpen}
            isLoading={isContactInfoLoading}
            contactInfo={contactInfo}
            onClose={() => {
               setIsContactInfoDialogOpen(false);
            }}
         />
         <Card sx={{ width: "100%" }}>
            <CardHeader
               sx={{
                  height: "auto",
               }}
               avatar={
                  <Avatar
                     alt={full_name}
                     src={profile_picture_url}
                     sx={{ width: 50, height: 50 }}
                  />
               }
               title={full_name}
               action={
                  <>
                     <Tooltip title="Linkedin Profile">
                        <IconButton
                           aria-label="Linkedin profile"
                           onClick={() => window.open(linkedin_profile_url)}
                        >
                           <LinkedInIcon sx={{ color: "#5fc780" }} />
                        </IconButton>
                     </Tooltip>
                     {/* Contact Info button temporarily disabled
                     <Tooltip title="View contact info (Email, Phone, etc.) - Subject to LinkedIn's viewing limits">
                        <IconButton
                           sx={{ color: "#5fc780" }}
                           aria-label="Contact Info"
                           onClick={fetchContactInfo}
                        >
                           <ContactsIcon />
                        </IconButton>
                     </Tooltip>
                     */}
                  </>
               }
            ></CardHeader>
            <CardContent>
               <Stack spacing={1}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                     <Rating
                        name="half-rating-read"
                        value={score / 2 || 0}
                        precision={0.5}
                        readOnly
                     />
                     <Typography variant="h6">{score}/10</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                     <PlaceIcon />
                     <Typography variant="h6">{location}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                     <PolylineIcon />
                     <Typography variant="h6">
                        {connections} connections
                     </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                     <WorkHistoryIcon />
                     <Typography variant="h6">{last_role}</Typography>
                  </Box>
               </Stack>
            </CardContent>
            <CardActions disableSpacing>
               <Tooltip
                  title={
                     expanded
                        ? "Show short explanation"
                        : "Show long explanation"
                  }
               >
                  <IconButton
                     onClick={handleExpandClick}
                     aria-expanded={expanded}
                     aria-label="show more"
                  >
                     <ExpandMoreIcon
                        sx={{
                           transform: expanded
                              ? "rotate(-180deg)"
                              : "rotate(0deg)",
                           transition: "transform 0.3s",
                        }}
                     />
                  </IconButton>
               </Tooltip>

               {!expanded && <Typography>{short_explanation}</Typography>}
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
               <CardContent>
                  <div
                     dangerouslySetInnerHTML={{
                        __html: converter.makeHtml(long_explanation),
                     }}
                  ></div>
               </CardContent>
            </Collapse>
         </Card>
      </>
   );
}
