import React, { useEffect, useState } from 'react';
import { deleteUserResume, updateUserActiveResume } from '../../services/autoApply';
import { toast } from 'react-toastify';
import { Box, Typography, Radio, IconButton, Paper, List, ListItem, ListItemText } from '@mui/material';
import { Delete, Download } from '@mui/icons-material';

const ResumeDownload = ({ resumes }) => {
  const [selectedResume, setSelectedResume] = useState();
  const [resumesList, setResumesList] = useState(resumes || []);

  useEffect(() => {
    setSelectedResume(resumes.find((resume) => resume.active) || resumes[0])
    setResumesList(resumes || []);
  }, [resumes])
  
  // Handle radio button change.
  const handleSelectionChange = async (resume) => {
    const updated_resumes = await updateUserActiveResume(resume["id"])
    if (updated_resumes) {
      setSelectedResume(resume);
    }
  };
  
  // Handle radio button change.
  const handleDeleteResume = async (resumeId, fileName) => {
    if (window.confirm(`Are you sure you want to delete the resume: ${fileName}?`)) {
      const updated_resumes = await deleteUserResume(resumeId)
      if (updated_resumes) {
        setResumesList(updated_resumes)
        setSelectedResume(updated_resumes.find((resume) => resume.active) || resumes[0])
      }
    }
  };

  return (
    <List sx={{ 
      width: '100%', 
      p: 0,
      '& .MuiListItem-root': {
        py: 0.5 // Reduce padding to make the list more compact
      }
    }}>
      {resumesList.length === 0 ? (
        <ListItem>
          <ListItemText 
            primary="No resumes available" 
            sx={{ textAlign: 'center', color: 'text.secondary' }} 
          />
        </ListItem>
      ) : (
        resumesList.map((resume, index) => (
          <ListItem 
            key={index} 
            sx={{ 
              borderBottom: index < resumesList.length - 1 ? '1px solid rgba(0,0,0,0.08)' : 'none',
              py: 0.5
            }}
          >
            <Radio
              checked={selectedResume?.file_name === resume.file_name}
              onChange={() => handleSelectionChange(resume)}
              size="small"
              color="primary"
            />
            <ListItemText 
              primary={resume.file_name} 
              sx={{ 
                ml: 1, 
                mr: 1,
                color: 'text.primary',
                '& .MuiTypography-root': {
                  fontSize: '0.9rem',
                  fontWeight: selectedResume?.file_name === resume.file_name ? 500 : 400,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap'
                }
              }} 
            />
            <Box sx={{ display: 'flex', gap: 1, ml: 'auto', flexShrink: 0 }}>
              <IconButton
                component="a"
                href={resume.url}
                download
                target="_blank"
                rel="noopener noreferrer"
                size="small"
                color="primary"
                sx={{ 
                  bgcolor: 'rgba(25, 118, 210, 0.08)',
                  '&:hover': {
                    bgcolor: 'rgba(25, 118, 210, 0.15)',
                  }
                }}
              >
                <Download fontSize="small" />
              </IconButton>
              <IconButton
                onClick={() => handleDeleteResume(resume.id, resume.file_name)}
                size="small"
                color="error"
                sx={{ 
                  bgcolor: 'rgba(211, 47, 47, 0.08)',
                  '&:hover': {
                    bgcolor: 'rgba(211, 47, 47, 0.15)',
                  }
                }}
              >
                <Delete fontSize="small" />
              </IconButton>
            </Box>
          </ListItem>
        ))
      )}
    </List>
  );
};

export default ResumeDownload;
