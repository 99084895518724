import axios from "axios";
import { baseUrl } from "./api";
import { toast } from "react-toastify";
import { encryptAndEncodePassword, getToken } from "../util/utilityFunction";

// 获取用户的问卷进度
export const getAutoApplyProfile = async () => {
   const token = await getToken();
   if (!token) return null;

   try {
      const response = await axios.get(`${baseUrl}/auto-apply/progress`, {
         headers: {
            Authorization: `Bearer ${token}`,
         },
      });

      return response.data;
   } catch (error) {
      toast.error("Error fetching profile: " + error.message);
      return null;
   }
};

// get questions
export const getQuestions = async (file) => {
   const token = await getToken();
   if (!token) return null;

   try {
      const formData = new FormData();
      formData.append("cvs", file);
      const response = await axios.post(
         `${baseUrl}/job/create_questionnaire`,
         formData,
         {
            headers: {
               Authorization: `Bearer ${token}`,
               "Content-Type": "multipart/form-data",
            },
         }
      );
      console.log("Upload success:", response.data);
      return response.data;
      // return dummydata
   } catch (error) {
      console.log("Upload Failed:", error.message);
      toast.error("Error fetching profile: " + error.message);
      return null;
   }
};
//   const token = await getToken();
//   if (!token) return null;

//   try {
//     const formData = new FormData();
//     formData.append("cvs", file);
//     const response = await axios.post(
//       `${baseUrl}/job/create_questionnaire`,
//       formData,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "multipart/form-data",
//         },
//       }
//     );
//     console.log("Upload success:", response.data);
//     return response.data;
//     // return dummydata
//   } catch (error) {
//     console.log("Upload Failed:",error.message);
//     toast.error("Error fetching profile: " + error.message);
//     return null;
//   }
// };

export const getUserResumes = async () => {
   const token = await getToken();
   if (!token) return null;

   try {
      const response = await axios.get(`${baseUrl}/job/get_user_resumes`, {
         headers: {
            Authorization: `Bearer ${token}`,
         },
      });
      console.log("Upload success:", response.data);
      return response.data;
      // return dummydata
   } catch (error) {
      console.log("Upload Failed:", error.message);
      toast.error("Error fetching profile: " + error.message);
      return null;
   }
};

export const getUserQuestionnaire = async () => {
   const token = await getToken();
   if (!token) return null;

   try {
      const response = await axios.get(
         `${baseUrl}/job/get_user_questionnaire`,
         {
            headers: {
               Authorization: `Bearer ${token}`,
            },
         }
      );
      console.log("fetched user questionnaire successfully:", response.data);
      return response.data;
      // return dummydata
   } catch (error) {
      console.log("Upload Failed:", error.message);
      toast.error("Error fetching profile: " + error.message);
      return null;
   }
};

export const updateUserActiveResume = async (resume_id) => {
   const token = await getToken();
   if (!token) return null;

   try {
      const response = await axios.put(
         `${baseUrl}/job/set_active_resume/${resume_id}`,
         {},
         {
            headers: {
               Authorization: `Bearer ${token}`,
            },
         }
      );
      console.log("Updated user active resume:", response.data);
      return response.data;
      // return dummydata
   } catch (error) {
      console.log("Failed to update active resume for user:", error.message);
      toast.error("Failed to update active resume for user");
      return null;
   }
};

export const deleteUserResume = async (resume_id) => {
   const token = await getToken();
   if (!token) return null;

   try {
      const response = await axios.delete(
         `${baseUrl}/job/user_resume/${resume_id}`,
         {
            headers: {
               Authorization: `Bearer ${token}`,
            },
         }
      );
      console.log("Deleted user resume:", response.data);
      return response.data;
      // return dummydata
   } catch (error) {
      // console.log("Failed to update active resume for user:", error.message);
      toast.error("Failed to delete resume for user");
      return null;
   }
};

export const updateUserQuestionnaire = async (questionnaireData) => {
   const token = await getToken();
   if (!token) return null;

   try {
      const response = await axios.put(
         `${baseUrl}/job/update_user_questionnaire`,
         { updated_data: questionnaireData },
         {
            headers: {
               Authorization: `Bearer ${token}`,
            },
         }
      );
      // console.log("Updated user questionnaire successfully:", response.data);
      toast.success("Update user questionnaire successfully");
      return response.data;
      // return dummydata
   } catch (error) {
      console.log("Failed to update user questionnaire:", error.message);
      toast.error("Failed to update user questionnaire");
      return null;
   }
};

// 保存问卷进度
export const saveAutoApplyProfile = async (formData) => {
   const token = await getToken();
   if (!token) return null;

   try {
      const response = await axios.post(
         `${baseUrl}/auto-apply/progress`,
         {
            step: formData.step,
            answers: formData.answers,
            lastUpdated: new Date().toISOString(),
         },
         {
            headers: {
               Authorization: `Bearer ${token}`,
            },
         }
      );

      if (response.statusText === "OK") {
         toast.success("Progress saved successfully");
         return response.data;
      }
      return null;
   } catch (error) {
      toast.error("Error saving progress: " + error.message);
      return null;
   }
};

// 提交整个问卷
export const submitAutoApplyForm = async (formData) => {
   const token = await getToken();
   if (!token) return null;

   try {
      const response = await axios.post(
         `${baseUrl}/auto-apply/submit`,
         formData,
         {
            headers: {
               Authorization: `Bearer ${token}`,
            },
         }
      );

      if (response.statusText === "OK") {
         toast.success("Form submitted successfully");
         return response.data;
      }
      return null;
   } catch (error) {
      toast.error("Error submitting form: " + error.message);
      return null;
   }
};

//start pub sub
export const startAutoApply = async (query, location, password, location_type, resume_Optimization_State, threshold, numJobsToApply) => {
  const token = await getToken();
  if (!token) return null;

  const formattedLocArr = location_type.map((item) =>
    item.replace(/-/g, "").toUpperCase()
  );

  const encryptedPass = await encryptAndEncodePassword(password)
  // console.log("HAHAHHAHA", encryptedPass)

  // Ensure threshold is within 0-85 range
  const validatedThreshold = Math.min(Math.max(0, threshold), 85);
  
  // Ensure numJobsToApply is capped at 5 jobs
  // If numJobsToApply > 5, simply cap it at 5 (no modulo)
  let validatedJobsToApply = numJobsToApply;
  if (validatedJobsToApply > 5) {
    validatedJobsToApply = 5;
  }
  // Allow 0 as a valid value (no minimum enforcement)

  const requestData = {
    linkedin_password: encryptedPass,
    search_query: query,
    search_location: location,
    location_type: formattedLocArr,
    resume_optimization: resume_Optimization_State,
    job_apply_threshold: validatedThreshold,
    jobs_to_apply: validatedJobsToApply
  };
  // console.log("Console request data is: ",requestData)

   try {
      const response = await axios.post(
         `${baseUrl}/job/create_auto_apply_job`,
         requestData,
         {
            headers: {
               Authorization: `Bearer ${token}`,
            },
         }
      );

      if (response.statusText === "OK") {
         toast.success("Auto apply process started");
         if (response.data && response.data.session_id) {
            return {
               session_id: response.data.session_id,
               ...response.data
            };
         }
         return response.data;
      }
      return null;
   } catch (error) {
      toast.error("Error starting auto apply: " + error.message);
      return null;
   }
};

export const getAutoApplyReports = async () => {
   const token = await getToken();
   if (!token) return null;

   try {
      const response = await axios.get(`${baseUrl}/job/get_auto_apply_jobs`, {
         headers: {
            Authorization: `Bearer ${token}`,
         },
      });

      // Group jobs by report_id
      const jobsByReport = {};
      
      // Process all jobs to organize them by report_id
      response.data.forEach(item => {
         const reportId = item.report_id;
         
         if (!jobsByReport[reportId]) {
            const { auto_apply_parameters, applications = [] } = item;
            
            // Initialize the report entry
            jobsByReport[reportId] = {
               ...item,
               ...auto_apply_parameters,
               status: item.status ?? false,
               applications: [],
               jobs_applied: 0,
               jobs_skipped: 0,
               error_count: 0
            };
         }
         
         // If this entry has job application data, add it to the applications array
         if (item.job_application) {
            const application = {
               job_title: item.job_application.title || 'Unknown Job',
               company_name: item.job_application.company || 'Unknown Company',
               status: item.job_application.status || 'unknown',
               timestamp: item.job_application.timestamp || item.created_at,
               notes: item.job_application.notes || '',
               url: item.job_application.url || ''
            };
            
            jobsByReport[reportId].applications.push(application);
            
            // Update counters
            if (application.status === 'applied') {
               jobsByReport[reportId].jobs_applied++;
            } else if (application.status === 'skipped') {
               jobsByReport[reportId].jobs_skipped++;
            } else if (application.status === 'error') {
               jobsByReport[reportId].error_count++;
            }
         }
      });
      
      // Convert the object to an array and sort by date (newest first)
      const uniqueReports = Object.values(jobsByReport).sort((a, b) => 
         new Date(b.report_creation_date) - new Date(a.report_creation_date)
      );
      
      return uniqueReports;
   } catch (error) {
      toast.error("Error fetching reports");
      return null;
   }
};

export const getJobLiveView = async (sessionId) => {
   const token = await getToken();
   if (!token) return null;

   try {
      const response = await axios(`${baseUrl}/job/job_live_url/${sessionId}`, {
         method: "GET",
         headers: {
            Authorization: `Bearer ${token}`,
         },
      });
      return response.data.frontend_url;
   } catch (error) {
      toast.error("Error fetching live url");
      return null;
   }
};

// Report an auto-apply job as incorrect
export const reportAutoApplyJobIncorrect = async (reportId) => {
   const token = await getToken();
   if (!token) {
      toast.error("Authentication required");
      return { success: false, message: "Authentication required" };
   }

   try {
      const response = await axios.get(
         `${baseUrl}/job/report_auto_apply_job/${reportId}`,
         {
            headers: {
               Authorization: `Bearer ${token}`,
            },
         }
      );
      
      // console.log("Job reported as incorrect:", response.data);
      toast.success(response.data || "Job successfully reported as incorrect");
      
      return {
         success: true,
         message: response.data || "Job successfully reported as incorrect"
      };
   } catch (error) {
      // console.error("Failed to report job as incorrect:", error);
      const errorMessage = error.response?.data?.message || error.message || "Failed to report job";
      toast.error(errorMessage);
      
      return {
         success: false,
         message: errorMessage
      };
   }
};
