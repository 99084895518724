import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getNotification } from "../services/notificationApi";
import NotificationBtn from "./NotificationBtn";
import Spinner from "./Spinner";
import { useOutsideClick } from "../hooks/useOutSideClick";
import Style from "./../css/candidateNavBar.module.css";
import getSocketInit from "../socket";
import { useGetNotification } from "../hooks/useGetNotification";
import { useSocket } from "../context/SocketContext";
import { useUpdateNotificationStatus } from "../hooks/useUpdateNotificationStatus";

const NotificationComponent = ({
  setIsNotificationSmallOpen,
  isNotificationSmallOpen,
  setHasNotification,
  notification,
}) => {
  const { socket } = useSocket();
  console.log("why");

  // const { notificationData: notification, isNotification } = useSocket();
  const { changeNotificationStatus, isPending } = useUpdateNotificationStatus();
  // const ref = useRef();

  const notificationClear = useCallback(() => {
    const getIsClickedId =
      notification &&
      notification
        ?.filter((notify) => !notify.isClick)
        .map((notify) => notify.id);

    if (getIsClickedId?.length > 0) {
      // socket.emit("notificationClicked", getIsClickedId);

      changeNotificationStatus();
    }
  }, [notification]);

  const closeNotificationSmall = useCallback(() => {
    if (isNotificationSmallOpen) {
      setIsNotificationSmallOpen(false);
      notificationClear();
      setHasNotification(false);
    }
  }, [setIsNotificationSmallOpen, isNotificationSmallOpen]);

  // useEffect(() => {
  //   console.log("Notification");

  //   const getIsClickedId =
  //     notification &&
  //     notification
  //       ?.filter((notify) => !notify.isClick)
  //       .map((notify) => notify.id);

  //   if (getIsClickedId?.length > 0) {
  //     // socket.emit("notificationClicked", getIsClickedId);
  //     console.log("Notification clicked", getIsClickedId);
  //     changeNotificationStatus();
  //   }
  // }, []);

  const notificationRef = useOutsideClick(closeNotificationSmall, true, "nope");
  // const notificationRef = isNotificationSmallOpen ? outsideRef : ref;

  const renderNotifications = useCallback(() => {
    if (!notification) {
      return null;
    }

    return notification.map((notif) => (
      <NotificationBtn
        key={notif.id}
        topic={notif.topic}
        description={notif.description}
        createdAt={notif.createdAt}
      />
    ));
  }, [notification]);

  // if (isNotification) {
  //   return;
  // }

  if (!notification) {
    return;
  }

  const getIsClickedId =
    notification &&
    notification
      ?.filter((notify) => !notify.isClick)
      .map((notify) => notify.id);

  return (
    <div className="">
      <div
        style={{
          width: "400px",
        }}
        className="position-absolute top-10 d-none d-sm-block  z-20 end-0 "
        ref={notificationRef}
      >
        <div
          style={{
            backgroundColor: "#f3f4f6",
          }}
          className="p-3 shadow-sm overflow-hidden h-100  w-sm-750 w-100 rounded-2 "
        >
          <div className="fw-bold mb-2 ">
            Notification
            <span className="text-primary">
              ({getIsClickedId?.length ? getIsClickedId.length : 0})
            </span>
          </div>

          <div
            style={{
              maxHeight: "300px",
            }}
            className=" overflow-auto"
          >
            {renderNotifications()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationComponent;
