import React from "react";
import styles from "./../css/SubscriptionOver.module.css";

const SubscriptionOver = ({ show, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <div className={styles.overlay}>
      <div className={styles.messageBox}>
        <h5 className={styles.title}>Subscription Plan</h5>
        <p>
          Your free trial has ended. Please choose a subscription plan to
          continue using our services.
        </p>
        <div className={styles.planList}>
          <button className={styles.planItem}>Basic Plan - $9.99/month</button>
          <button className={styles.planItem}>Pro Plan - $19.99/month</button>
          <button className={styles.planItem}>
            Enterprise Plan - $49.99/month
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionOver;
