import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const HotjarTracker = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const location = useLocation();

  // Check if current page is a public auth page (sign in, sign up, etc.)
  const isAuthPage = () => {
    const publicPaths = ['/signin', '/signup', '/continue-with-email', '/forgotpassword', '/reset-password'];
    return publicPaths.some(path => location.pathname.includes(path));
  };

  // Initialize Hotjar when authenticated
  const initHotjar = () => {
    // Define Hotjar script
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:5318208,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    
    setIsInitialized(true);
    
    // Wait for Hotjar to initialize and then trigger the chatbot
    setTimeout(() => {
      if (window.hj) {
        // Trigger the chatbot to appear for authenticated users
        window.hj('trigger', 'show_chatbot');
        
        // You can also set user attributes if needed
        const userName = localStorage.getItem("userName");
        if (userName) {
          window.hj('identify', localStorage.getItem("userId"), {
            name: userName,
            userType: localStorage.getItem("userType") || 'unknown'
          });
        }
      }
    }, 2000); // Give Hotjar some time to initialize
  };

  // Check authentication on mount and when it changes
  useEffect(() => {
    const checkAndInitialize = () => {
      const isAuthenticated = localStorage.getItem("userId");
      // Only initialize if authenticated AND not on an auth page
      if (isAuthenticated && !isInitialized && !isAuthPage()) {
        initHotjar();
      }
    };

    // Check immediately
    checkAndInitialize();

    // Set up an interval to periodically check (useful for single-page apps)
    const interval = setInterval(checkAndInitialize, 5000);

    // Also listen for localStorage changes
    const handleStorageChange = (e) => {
      if (e.key === 'userId' && e.newValue) {
        checkAndInitialize();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Clean up
    return () => {
      clearInterval(interval);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [isInitialized, location.pathname]);

  // Listen for navigation events that might indicate login
  useEffect(() => {
    const checkAfterNavigation = () => {
      setTimeout(() => {
        const isAuthenticated = localStorage.getItem("userId");
        if (isAuthenticated && !isInitialized && !isAuthPage()) {
          initHotjar();
        }
      }, 1000);
    };

    window.addEventListener('popstate', checkAfterNavigation);
    
    return () => {
      window.removeEventListener('popstate', checkAfterNavigation);
    };
  }, [isInitialized, location.pathname]);

  return null; // This component doesn't render anything
};

export default HotjarTracker; 