import { Children } from "react";
import { useGetMe } from "../hooks/useGetMe";
import { useLocation, Navigate, Outlet } from "react-router-dom";

function RouteProtecter({ authorizedUserType, children }) {
   const { myData, isPending } = useGetMe();

   // Add more detailed logging
   // console.log("RouteProtecter - Current Path:", window.location.pathname);
   // console.log("RouteProtecter - myData:", myData);
   // console.log("RouteProtecter - authorizedUserType:", authorizedUserType);
   // console.log("RouteProtecter - localStorage userType:", localStorage.getItem("userType"));
   // console.log("RouteProtecter - sessionStorage token:", sessionStorage.getItem("authToken"));

   if (isPending) {
      // console.log("RouteProtecter - Still pending...");
      return null;
   }

   if (!myData?.userType) {
      const localUserType = localStorage.getItem("userType");
      const authToken = sessionStorage.getItem("authToken");

      // console.log("RouteProtecter - No myData userType, checking local:", localUserType);

      if (localUserType && authToken) {
         // If we have local data but no myData yet, wait
         return null;
      }
      return <Navigate to="/" />;
   }

   if (myData.userType === authorizedUserType) {
      return <>{children}</>;
   } else {
      const defaultRoute =
         myData.userType === "candidate" ? "/candidate/auto-apply" :
            myData.userType === "recruiter" ? "/recruiter/peoplesearch" :
               "/coach/connect-to-professionals";

      // console.log("RouteProtecter - Redirecting to:", defaultRoute);
      return <Navigate to={defaultRoute} replace />;
   }
}

export default RouteProtecter;
