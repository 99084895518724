import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid2,
  Stack,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import heroBg from "../assets/hero-new-bg.jpg";
import Carousel from "../components/Carousel.tsx";
import CustomizedCard from "../components/CustomizedCard.tsx";
import HowToUse from "../components/HowToUse.tsx";
import Header from "../components/Header.tsx";
import HeroSection from "../components/HeroSection.tsx";
import {
  GlassdoorLogo,
  LinkedinLogo,
  MoreLogo,
  RedditLogo,
} from "../components/Logo.tsx";
import { NavLink } from "react-router-dom";
import winterWymanLogo from "../assets/Winter-Wyman-logo-blue.png";
import steppingStoneLogo from "../assets/stepping-stone-logo.png";
import secondStartLogo from "../assets/secondstart.png";
import AllanZheng from "../assets/AllanZheng.jpg";
import MarkDonne from "../assets/MarkDonne.jpg";
import PrivacyPolicy from './Legal/PrivacyPolicy.tsx';
import TermsOfService from './Legal/TermsOfService.tsx';
import agenticVideo from '../assets/LinkedIn_Agentic_Slow.mp4';
import SectionHeading from "../components/SectionHeading.tsx";

const data_sources = [
  { name: "LinkedIn", logo: <LinkedinLogo /> },
  { name: "Reddit", logo: <RedditLogo /> },
  { name: "Glassdoor", logo: <GlassdoorLogo /> },
  { name: "More", logo: <MoreLogo /> },
];

const partners = [
  {
    name: "WinterWyman",
    site: "https://theplanetgroup.com/winter-wyman-is-now-planet-professional-and-planet-technology/",
    logo: winterWymanLogo,
    description:
      "Once MVP is developed, the demo of product will be provided to staff at Winter Wyman - aiming to increase outreach response rates for staff members",
  },
  {
    name: "Stepping Stone Advocacy",
    site: "https://training.steppingstoneadvocacy.com/?affiliates=2",
    logo: steppingStoneLogo,
    description:
      "Stepping Stone Advocacy provides training and support for Nurses looking to pivot to new careers, away from the bedside.",
  },
  {
    name: "Second Start",
    site: "https://second-start.org/",
    logo: secondStartLogo,
    description:
      "Second Start offers educational programs and services to help individuals achieve their goals.",
  },
];

const HomePage = () => {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsOfService, setShowTermsOfService] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTV = useMediaQuery('(min-width: 1920px)'); // TV detection

  return (
    <div>
      {/* Original content commented out for reference
      <div className="carousel-container">
        <Carousel>
          <Carousel.Item>
            <img src={carouselImage} alt="Carousel" />
            <div className="carousel-text">
              <h3>Sourcing on LinkedIn has low lead conversion rates, so why try our people search feature?</h3>
              <p>Each lead that is returned according to your natural language search has a score for how likely the lead will respond to outreach, based on factors like activity level on LinkedIn, openness to new opportunities, and overall fit.</p>
            </div>
          </Carousel.Item>
          // ... other carousel items ...
        </Carousel>
      </div>

      <div className="our-solution">
        <h2>Our Solution</h2>
        <div className="solution-cards">
          // ... solution cards ...
        </div>
      </div>
      */}
      <Header />
      <section>
        <Box
          sx={{
            position: "relative",
            minHeight: "20vh",
            py: 8,
            backgroundImage: `url(${heroBg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1,
            },
          }}
        >
          <HeroSection />
        </Box>
      </section>
      <Divider />
      <section>
        <SectionHeading
          title="Why CareerGPT?"
          subtitle="We use agentic AI to automate entire workflows for you, from applying to jobs to sourcing candidates."
        />

        <Box
          sx={{
            width: '100%',
            maxWidth: '1000px',
            height: { xs: '300px', sm: '400px', md: '500px' },
            margin: '2rem auto',
            padding: '0',
            borderRadius: '12px',
            overflow: 'hidden',
            position: 'relative',
            backgroundColor: '#000',
            boxShadow: 'none'
          }}
        >
          {/* Use the isMobile variable to conditionally render different video styles */}
          {isMobile ? (
            // Mobile-specific video container
            <Box
              sx={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden'
              }}
            >
              <Box
                component="video"
                autoPlay
                muted
                loop
                playsInline
                src={agenticVideo}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center'
                }}
              />
            </Box>
          ) : (
            // Desktop video container
            <Box
              sx={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden'
              }}
            >
              <Box
                component="video"
                autoPlay
                muted
                loop
                playsInline
                src={agenticVideo}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center'
                }}
              />
            </Box>
          )}
        </Box>
        {/* <Grid2 container sx={{ gap: 5, justifyContent: "center" }}>
          <CustomizedCard
            icon="search"
            text="Easy auto-apply on LinkedIn"
          />
          <CustomizedCard
            icon="analytics"
            text="LLM-powered lead scoring"
          />
          <CustomizedCard
            icon="explore"
            text="A one-stop shop for all your needs"
          />
        </Grid2> */}
      </section>
      <Divider sx={{ my: 5 }} />

      <SectionHeading title="How Does CareerGPT Work?" />
      <HowToUse />
      <Box sx={{ display: "flex", justifyContent: "center", py: 5 }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#5fcf80",
            "&:hover": {
              backgroundColor: "#56ba73",
            },
            borderRadius: "20px",
            margin: "auto",
          }}
          component="a"
          href="/signup"
          target="_blank"
          rel="noopener noreferrer"
        >
          Try it now!
        </Button>
      </Box>

      <SectionHeading title="Powered by Advanced AI and Data Sources" />

      <Grid2
        container
        sx={{ gap: 5, justifyContent: "center", alignItems: "center" }}
      >
        {data_sources.map(source => (
          <Grid2 size={{ xs: 6, sm: 4, md: 2 }} key={source.name}>
            <Box
              sx={{
                marginX: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
                justifyContent: "center",
                "&:hover .data-source-name": { opacity: 1 },
                "&:hover .data-source": { transform: "translateY(0)" },
              }}
            >
              {React.cloneElement(source.logo, {
                sx: {
                  width: source.name === "Glassdoor" ? 120 : 140,
                  height: "auto",
                  transform: "translateY(10px)",
                  transition: "all 0.5s",
                },
                className: "data-source",
              })}
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  opacity: 0,
                  transition: "all 0.5s",
                }}
                className="data-source-name"
              >
                {source.name}
              </Typography>
            </Box>
          </Grid2>
        ))}
      </Grid2>

      <SectionHeading title="Design Partners Of" />

      <Grid2
        container
        sx={{
          gap: 5,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {partners.map(partner => (
          <Grid2 size={{ xs: 12, sm: 6, md: 4 }} key={partner.name}>
            <Box
              sx={{
                width: "100%",
                marginX: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                "&:hover .design-partner": { transform: "translateY(0)" },
                "&:hover .design-partner-name": { opacity: 1 },
              }}
            >
              <NavLink to={partner.site} target="_blank">
                <Box
                  component="img"
                  src={partner.logo}
                  className="design-partner"
                  alt={partner.name}
                  sx={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "200px",
                    transform: "translateY(10px)",
                    transition: "all 0.5s",
                  }}
                />
              </NavLink>

              <Typography
                variant="h6"
                className="design-partner-name"
                sx={{ my: 2, opacity: 0, transition: "all 0.5s" }}
              >
                {partner.name}
              </Typography>
              <Typography>{partner.description}</Typography>
            </Box>
          </Grid2>
        ))}
      </Grid2>

      <Grid2
        container
        sx={{ gap: 5, justifyContent: "center", marginTop: 5 }}
      >
        <CustomizedCard icon="time" title="60%" text="Time saved" />
        <CustomizedCard icon="money" title="75%" text="Cost saved" />
        <CustomizedCard icon="growth" title="40%" text="Efficiency" />
      </Grid2>

      <Divider sx={{ my: 5 }} />
      <SectionHeading title="Meet Our Team" />
      <Box
        sx={{
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '2rem 0',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {/* First row */}
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 3 }}>
            {/* Allan Zheng */}
            <Box sx={{ flex: 1 }}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '1.5rem',
                  borderRadius: '16px',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 12px 30px rgba(0,0,0,0.12)',
                  },
                  backgroundColor: '#f8f9fa',
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    width: '180px',
                    height: '180px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                    mb: 2,
                    border: '4px solid #5fcf80',
                  }}
                  src={AllanZheng}
                  alt="Allan Zheng"
                />
                <Typography variant="h5" fontWeight="600" textAlign="center" gutterBottom>
                  Allan Zheng
                </Typography>
                <Typography variant="subtitle1" color="primary" textAlign="center" gutterBottom>
                  Founder & Developer
                </Typography>
                <Typography variant="body2" color="text.secondary" textAlign="center" sx={{ fontSize: '0.85rem', flexGrow: 1 }}>
                  Georgia Tech alum who turns complex problems into elegant solutions—mostly on purpose.
                </Typography>
              </Card>
            </Box>

            {/* Daryl */}
            <Box sx={{ flex: 1 }}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '1.5rem',
                  borderRadius: '16px',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 12px 30px rgba(0,0,0,0.12)',
                  },
                  backgroundColor: '#f8f9fa',
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    width: '180px',
                    height: '180px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                    mb: 2,
                    border: '4px solid #5fcf80',
                  }}
                  src={require('../assets/daryl.avif')}
                  alt="Daryl"
                />
                <Typography variant="h5" fontWeight="600" textAlign="center" gutterBottom>
                  Daryl
                </Typography>
                <Typography variant="subtitle1" color="primary" textAlign="center" gutterBottom>
                  Engineering Lead
                </Typography>
                <Typography variant="body2" color="text.secondary" textAlign="center" sx={{ fontSize: '0.85rem', flexGrow: 1 }}>
                  Turning complex problems into elegant solutions, taming databases, and occasionally creating happy accidents in the cloud.
                </Typography>
              </Card>
            </Box>

            {/* Om */}
            <Box sx={{ flex: 1 }}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '1.5rem',
                  borderRadius: '16px',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 12px 30px rgba(0,0,0,0.12)',
                  },
                  backgroundColor: '#f8f9fa',
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    width: '180px',
                    height: '180px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                    mb: 2,
                    border: '4px solid #5fcf80',
                  }}
                  src={require('../assets/om.avif')}
                  alt="Om"
                />
                <Typography variant="h5" fontWeight="600" textAlign="center" gutterBottom>
                  Om
                </Typography>
                <Typography variant="subtitle1" color="primary" textAlign="center" gutterBottom>
                  AI Specialist
                </Typography>
                <Typography variant="body2" color="text.secondary" textAlign="center" sx={{ fontSize: '0.85rem', flexGrow: 1 }}>
                  Turning caffeine into algorithms, client puzzles into AI adventures, and deadlines into 'model deployed!'
                </Typography>
              </Card>
            </Box>
          </Box>

          {/* Second row */}
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 3 }}>
            {/* Zerui */}
            <Box sx={{ flex: 1 }}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '1.5rem',
                  borderRadius: '16px',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 12px 30px rgba(0,0,0,0.12)',
                  },
                  backgroundColor: '#f8f9fa',
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    width: '180px',
                    height: '180px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                    mb: 2,
                    border: '4px solid #5fcf80',
                  }}
                  src={require('../assets/zerui.avif')}
                  alt="Zerui"
                />
                <Typography variant="h5" fontWeight="600" textAlign="center" gutterBottom>
                  Zerui
                </Typography>
                <Typography variant="subtitle1" color="primary" textAlign="center" gutterBottom>
                  AI & Full-Stack Developer
                </Typography>
                <Typography variant="body2" color="text.secondary" textAlign="center" sx={{ fontSize: '0.85rem', flexGrow: 1 }}>
                  Leveraging mathematical foundations to refine code with precision. The go-to firefighter during crunch times.
                </Typography>
              </Card>
            </Box>

            {/* Anirudha */}
            <Box sx={{ flex: 1 }}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '1.5rem',
                  borderRadius: '16px',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 12px 30px rgba(0,0,0,0.12)',
                  },
                  backgroundColor: '#f8f9fa',
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    width: '180px',
                    height: '180px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                    mb: 2,
                    border: '4px solid #5fcf80',
                  }}
                  src={require('../assets/anirudha.avif')}
                  alt="Anirudha"
                />
                <Typography variant="h5" fontWeight="600" textAlign="center" gutterBottom>
                  Anirudha
                </Typography>
                <Typography variant="subtitle2" color="primary" textAlign="center" gutterBottom>
                  Software Engineer
                </Typography>
                <Typography variant="body2" color="text.secondary" textAlign="center" sx={{ fontSize: '0.85rem', flexGrow: 1 }}>
                  Bridging the gap between product and engineering, one line of code at a time. Breaking servers since 1997.
                </Typography>
              </Card>
            </Box>

            {/* Wenting */}
            <Box sx={{ flex: 1 }}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '1.5rem',
                  borderRadius: '16px',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 12px 30px rgba(0,0,0,0.12)',
                  },
                  backgroundColor: '#f8f9fa',
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    width: '180px',
                    height: '180px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                    mb: 2,
                    border: '4px solid #5fcf80',
                  }}
                  src={require('../assets/wenting.jpg')}
                  alt="Wenting"
                />
                <Typography variant="h5" fontWeight="600" textAlign="center" gutterBottom>
                  Wenting
                </Typography>
                <Typography variant="subtitle1" color="primary" textAlign="center" gutterBottom>
                  Experiential Frontend Engineer
                </Typography>
                <Typography variant="body2" color="text.secondary" textAlign="center" sx={{ fontSize: '0.85rem', flexGrow: 1 }}>
                  Designing intuitive interfaces, streamlining data presentation, and turning raw data into visual magic.
                </Typography>
              </Card>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 4, mb: 2 }}>
        <Link to="/privacy-policy" style={{ textDecoration: 'none' }}>
          <Button variant="text" color="primary">
            Privacy Policy
          </Button>
        </Link>
        <Link to="/terms-of-service" style={{ textDecoration: 'none' }}>
          <Button variant="text" color="primary">
            Terms of Service
          </Button>
        </Link>
      </Box>

      <Dialog open={showPrivacyPolicy} onClose={() => setShowPrivacyPolicy(false)} maxWidth="md" fullWidth>
        <DialogContent>
          <PrivacyPolicy />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPrivacyPolicy(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showTermsOfService} onClose={() => setShowTermsOfService(false)} maxWidth="md" fullWidth>
        <DialogContent>
          <TermsOfService />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowTermsOfService(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HomePage;
