import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const renderTooltip = (props, text) => (
  <Tooltip id="button-tooltip rounded-pill" {...props}>
    {text}
  </Tooltip>
);

const MobileSideBarButton = ({
  renderTooltipTest,
  to,
  text,
  iconeName,
  iconeFolder,
  handleSidebar,
  isActive
}) => {
  const isMobile = window.innerWidth <= 768;
  const tooltipPlacement = isMobile ? "bottom" : "right";

  return (
    <div className="col-12 mt-2">
      <OverlayTrigger
        placement={tooltipPlacement}
        overlay={(props) => renderTooltip(props, renderTooltipTest)}
      >
        <Link
          onClick={handleSidebar}
          to={to}
          className={isActive ? "focus-bar-item-active" : "focus-bar-item"}
        >
          <div className="d-flex gap-2 justify-content-left align-items-center">
            <img
              src={`/icons/${iconeFolder}/${iconeName}`}
              alt={text}
              style={{ height: "24px", width: "24px" }}
            />
            <div
              style={{
                fontSize: "16px",
              }}
              className="d-block d-lg-block d-md-none"
            >
              {text}
            </div>
          </div>
        </Link>
      </OverlayTrigger>
    </div>
  );
};

export default MobileSideBarButton
