import { useQuery } from "@tanstack/react-query";
import { getRecruiterMe } from "../services/recruiterApi";

export const useGetRecruiterMe = () => {
  const { data: myData, isPending } = useQuery({
    queryKey: ["getRecruiterMe"],
    queryFn: getRecruiterMe,
    enabled: localStorage.getItem("userType") === "recruiter"
  });

  return { myData, isPending };
}; 