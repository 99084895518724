// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review-carousel .review-content {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 1;
  transform: translateX(0);
}

.review-carousel .review-content.leave {
  opacity: 0;
  transform: translateX(-100%);
}

.review-carousel .review-content.enter {
  opacity: 0;
  transform: translateX(100%);
}

.review-carousel .review-content.enter-active {
  opacity: 1;
  transform: translateX(0);
}
`, "",{"version":3,"sources":["webpack://./src/css/ReviewCarousel.css"],"names":[],"mappings":"AAAA;EACE,gEAAgE;EAChE,UAAU;EACV,wBAAwB;AAC1B;;AAEA;EACE,UAAU;EACV,4BAA4B;AAC9B;;AAEA;EACE,UAAU;EACV,2BAA2B;AAC7B;;AAEA;EACE,UAAU;EACV,wBAAwB;AAC1B","sourcesContent":[".review-carousel .review-content {\n  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;\n  opacity: 1;\n  transform: translateX(0);\n}\n\n.review-carousel .review-content.leave {\n  opacity: 0;\n  transform: translateX(-100%);\n}\n\n.review-carousel .review-content.enter {\n  opacity: 0;\n  transform: translateX(100%);\n}\n\n.review-carousel .review-content.enter-active {\n  opacity: 1;\n  transform: translateX(0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
