import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import logo from "./../assets/logo.png";
// import imagebg from "../../assets/signinbg.png";
import imagebg1 from "./../assets/BGimage1.png";
import imagebg2 from "./../assets/BGimage2.png";
import imagebg3 from "./../assets/BGimage3.png";

// import imageRating from "../../src/image.png";
import Carousel from "react-bootstrap/Carousel";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";

const AuthLayout = ({ children }) => {
  const navigate = useNavigate();
  const onClick = useCallback(() => {
    navigate("/");
  }, [navigate]);
  const [isXL, setIsXL] = useState(window.innerWidth > 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsXL(window.innerWidth <= 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="container-fluid overflow-hidden bg-white" style={{ height: "100vh" }}>
      <div className="row h-100">
        {/* Left column - Login form */}
        <div className="col-lg-4 col-sm-12 h-100">
          <div
            className="container-fluid bg-white d-flex flex-column"
            style={{
              height: "100vh",
              overflowY: "auto",
            }}
          >
            <div className="flex-grow-0 logo mt-3" onClick={onClick}>
              <img src={logo} alt="logo" style={{ height: "50px" }} />
            </div>
            <div className="flex-grow-1 container d-flex mt-2 flex-row justify-content-center align-items-center">
              {children}
            </div>
            <div
              style={{
                fontSize: "10px",
                color: "#333",
                marginTop: "15px",
              }}
              className="flex-grow-3"
            >
              <p>© {new Date().getFullYear()} CareerGPT. All rights reserved</p>
            </div>
          </div>
        </div>
        
        {/* Right column - Image carousel */}
        <div className="col-8 h-100 d-none d-lg-block">
          <div className="h-100 p-2">
            <div className="carousel-container" style={{ 
              height: '100%', 
              borderRadius: '16px', 
              overflow: 'hidden',
              boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
            }}>
              <Carousel
                className="overflow-hidden rounded-4 center"
                prevIcon={<IoIosArrowDropleftCircle color="black" size={35} />}
                nextIcon={<IoIosArrowDroprightCircle color="black" size={35} />}
                variant="dark"
                interval={3000}
                fade={true}
                indicators={true}
              >
                <Carousel.Item className="h-100">
                  <div
                    style={{
                      backgroundImage: `url(${imagebg1})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      width: '100%',
                      height: '100vh'
                    }}
                  ></div>
                </Carousel.Item>
                <Carousel.Item className="h-100">
                  <div
                    style={{
                      backgroundImage: `url(${imagebg2})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      width: '100%',
                      height: '100vh'
                    }}
                  ></div>
                </Carousel.Item>
                <Carousel.Item className="h-100">
                  <div
                    style={{
                      backgroundImage: `url(${imagebg3})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      width: '100%',
                      height: '100vh'
                    }}
                  ></div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AuthLayout;