import React from "react";
import { useSessionStorage } from "@uidotdev/usehooks";
import { useEffect, useState, useRef } from "react";

import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
   Box,
   Button,
   IconButton,
   InputLabel,
   NativeSelect,
   TextField,
   Tooltip,
   Typography,
   Link,
   Paper,
   Alert,
} from "@mui/material";
// import type {
//    GridColDef,
//    GridRowSelectionModel,
//    GridSortModel,
// } from "@mui/x-data-grid";
import HistoryIcon from "@mui/icons-material/History";
import { DataGrid } from "@mui/x-data-grid";

import PeopleCard from "../components/PeopleSearch/PeopleCard";
import { Search } from "@mui/icons-material";
import { useAppDispatch } from "../app/hooks.ts";
import { openDialog } from "../features/authDialog/authDialogSlice.ts";
import { baseUrl } from "../services/api";
import Spinner from "../ui/Spinner";
import axios from "axios";
import { set } from "lodash";
import { useGetRecruiterMe } from "../hooks/useGetRecruiterMe";
import { useSearchToken } from "../hooks/useSearchToken";
import { getLinkedinProfiles } from "../services/peopleSearch.js";

const columns =
   //: GridColDef[]
   [
      { field: "public_profile_id", headerName: "ID", hideable: true },
      { field: "score", headerName: "Score", hideable: true },
      {
         field: "Results",
         hideable: false,
         resizable: false,
         flex: 1,
         sortable: false,

         renderCell: (props) => <PeopleCard {...props.row} />,
      },
   ];
// interface Result {
//    public_profile_id: string;
//    score: number;
//    [key: string]: any;
// }

const sortModel = {
   default: [],
   score_desc: [{ field: "score", sort: "desc" }],
   score_asc: [{ field: "score", sort: "asc" }],
};

export default function Query() {
   const location = useLocation();
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const { myData, isPending: isUserDataLoading } = useGetRecruiterMe();
   const { consumeSearchToken, isPending: isTokenConsuming } = useSearchToken();

   const [lastQuery, setLastQuery] = useSessionStorage("lastQuery", "");
   const [query, setQuery] = useState(
      new URLSearchParams(location.search).get("query") || lastQuery
   );

   const [sortBy, setSortBy] = useState(sortModel["score_desc"]);
   const [selectedRows, setSelectedRows] = useState([]);

   const [isLoading, setIsLoading] = useState(false);
   const [offset, setOffset] = useState(0);
   const [totalResults, setTotalResults] = useState(undefined);
   //TODO: Implement concatenatedQueries
   //const [concatenatedQueries, setConcatenatedQueries] = useState("")

   const [resultsCache, setResultsCache] = useSessionStorage(
      "resultsCache",
      {}
   );
   const [results, setResults] = useState(resultsCache);

   const [sessionID, setSessionID] = useSessionStorage("sessionID", "");

   const linkedinCredentials = useSessionStorage("linkedinCredentials", {});

   // Add a new state for tracking backend progress
   const [searchProgress, setSearchProgress] = useState([]);
   const [searchStatus, setSearchStatus] = useState('');

   // Get remaining search tokens from user profile data
   // Server is the source of truth for token counts
   const remainingSearchTokens = !isUserDataLoading && myData ?
      myData.remainingSearchTokens : 0;

   // Check if user has a paid plan
   const hasActivePaidPlan = !isUserDataLoading && myData &&
      (myData.subscriptionPlan === "pro" || myData.isPaid === true || myData.appsumo_lifetime_access === true);

   // Check for LinkedIn credentials as soon as the component mounts
   useEffect(() => {
      // Only open dialog if there are no LinkedIn credentials
      if (!linkedinCredentials[0]?.li_at_cookie) {
         // If no LinkedIn credentials exist, open the dialog immediately
         dispatch(openDialog());
      }
   }, []); // Empty dependency array means this runs once on component mount

   function clearUrl() {
      const url = new URL(window.location.href);
      url.searchParams.delete("query");
      window.history.replaceState({}, document.title, url);
   }

   const handleLogout = async () => {
      const token = sessionStorage.getItem("authToken");

      axios
         .post(
            `${baseUrl}/auth/logout`,
            {},
            {
               headers: {
                  Authorization: `Bearer ${token}`, // Retrieve the token from session storage
               },
            }
         )
         .then((response) => {
            // Remove token from session storage
            sessionStorage.removeItem("authToken");
            sessionStorage.removeItem("linkedinCredentials");
            sessionStorage.removeItem("resultsCache");
            sessionStorage.removeItem("sessionID");

            // Clear user data from local storage
            localStorage.removeItem("userType");
            localStorage.removeItem("userId");
            localStorage.removeItem("userName");
            localStorage.removeItem("userObject");
            localStorage.removeItem("isPaid");

            if (window.socket) {
               window.socket.disconnect();
            }

            // Navigate to the sign-in page using direct URL change
            window.location.href = "/signin";
         })
         .catch((error) => {
            console.error("Logout error:", error);
            toast.error("Logout failed");
         });
   };

   /**
    * Handles the search functionality by making an API call and updating the results.
    */
   async function handleSearch() {
      // Strict validation before proceeding
      if (!query || query.trim() === "") {
         console.log("Search not executed: Empty query");
         return;
      }

      if (!linkedinCredentials[0]?.li_at_cookie) {
         console.log("Search not executed: Missing LinkedIn credentials");
         return;
      }

      if (!sessionID) {
         console.log("Search not executed: No session ID available");
         return;
      }

      // Check if tokens are available for non-paid users
      if (!hasActivePaidPlan && remainingSearchTokens <= 0) {
         toast.error("You've used all your search tokens. Please upgrade to continue searching.");
         setTimeout(() => navigate("/recruiter/subscription-plan"), 2000);
         return;
      }

      console.log("Executing search with query:", query);

      setIsLoading(true);
      // Save the current query as the last query
      setLastQuery(query);
      // Reset progress states
      setSearchProgress([]);
      setSearchStatus('Initializing search on LinkedIn...');

      try {
         // Use the current offset value from state
         // The API considers offset=0 as a new search (consumes a token)
         // and offset>0 as "more results" (doesn't consume a token)
         const response = await getLinkedinProfiles(query, sessionID, offset, linkedinCredentials[0])

         // Check for user if recrutier or cookie session expired (400)
         if (response.status === 401) {
            toast.error("User session has expired, please login in.")
            setTimeout(() => handleLogout(), 2000);
         }
         // Check for user if recrutier or cookie session expired (400)
         if (response.status === 400) {
            const data = await response.json();
            if (data.detail.includes("Cookie session expired")) {
               toast.error(data.detail || "Cookie session expired.");
               sessionStorage.removeItem("linkedinCredentials")
               dispatch(openDialog());
            } else {
               toast.error(data.detail || "User must be a recruiter.");
            }
            setIsLoading(false);
            return;
         }

         // Check for token depletion response (429)
         if (response.status === 429) {
            const data = await response.json();
            toast.error(data.detail || "You've used all your search tokens. Please upgrade to continue searching.");
            setTimeout(() => navigate("/recruiter/subscription-plan"), 2000);
            setIsLoading(false);
            return;
         }

         if (!response.ok) {
            throw new Error(`Server responded with status: ${response.status}`);
         }

         if (!response.body) throw new Error("No response body");
         const reader = response.body?.getReader();
         if (reader) {
            const decoder = new TextDecoder();
            let done = false;

            setSearchStatus('Connecting to LinkedIn...');

            // Track progress information
            let profileCount = 0;

            while (!done) {
               const { value, done: readerDone } = await reader.read();
               done = readerDone;

               if (value) {
                  const chunk = decoder.decode(value, { stream: true });
                  console.log("Chunk received:", chunk);
                  try {
                     for (const smallChunk of chunk.split("\n")) {
                        if (smallChunk !== "") {
                           const data = JSON.parse(smallChunk);

                           // Update total results if we have it
                           if (!totalResults) {
                              setTotalResults(data.total_results);
                              setSearchStatus(`Found ${data.total_results} potential matches, processing results...`);
                           }

                           // Handle search progress messages
                           if (data.status_message) {
                              setSearchProgress(prev => [...prev, data.status_message]);
                              setSearchStatus(data.status_message);
                           }

                           // Handle profile data
                           if (data.profiles && data.profiles.length > 0 && Object.values(results).length < 100) {
                              profileCount += data.profiles.length;
                              setSearchStatus(`Processing ${profileCount} profiles out of ${data.total_results || 'many'}...`);

                              setResults((results) => ({
                                 ...results,
                                 [data.profiles[0].public_profile_id]: data.profiles[0],
                              }));
                           }
                        }
                     }
                  } catch (error) {
                     console.error("Error parsing stream chunk:", error);
                     setSearchProgress(prev => [...prev, `Error processing results: ${error.message}`]);
                  }
               }
            }

            // Successfully completed search
            setSearchStatus('Search completed successfully!');
            // Add a small delay before changing the flag to keep the success message visible
            setTimeout(() => {
               setIsLoading(false);
            }, 1000);

            // Increase the offset for next "Get More Results" request
            setOffset(offset + 10);
         }
      } catch (error) {
         console.error("Error:", error);
         toast.error("Error loading search results");
         setSearchStatus(`Error: ${error.message}`);
         setIsLoading(false);
      }
   }

   async function handleConnection() {
      if (selectedRows.length === 0) {
         toast.error("Please select at least one user");
         return;
      }

      //setIsLoading(true);
      selectedRows.forEach(async (row) => {
         try {
            const response = await axios.post(
               `${baseUrl}/linkedin/add_connection`,
               {
                  profile_public_id: row,
                  auth_body: linkedinCredentials[0],
               }
            );
            toast.success(
               `Connection request to ${results[row]} sent successfully`
            );
         } catch (error) {
            toast.error(
               `An error occurred while connecting to ${results[row].full_name}`
            );
         }
      });

      // axios
      //    .all()
      //    .then((response) => {
      //       toast.success("Connection request sent successfully");
      //    })
      //    .catch((error) => {
      //       console.log(error);
      //       toast.error("An error occurred while connecting to the users");
      //    })
      //    .finally(() => {
      //       setIsLoading(false);
      //       setSelectedRows([]);
      //    });
      console.log("users selected", selectedRows);

   }

   useEffect(() => {
      //console.log("result cache change")
      setResultsCache(results);
   }, [results, setResultsCache]);

   // Add a ref to track initial mounting
   const hasInitialized = React.useRef(false);

   // Add a dedicated effect to check for LinkedIn credentials on mount
   useEffect(() => {
      // Scroll to the top of the page when component mounts
      window.scrollTo(0, 0);
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

      // Skip everything if we've already initialized once
      if (hasInitialized.current) {
         return;
      }

      // Generate session ID if needed (but don't trigger search yet)
      if (sessionID === "") {
         setSessionID(
            `${Date.now().toString(36)}${Math.random()
               .toString(36)
               .substring(2)}`
         );
         return; // Exit early - we'll handle the search in the next effect after sessionID is set
      }

      // Automatically open the dialog if no LinkedIn cookie is set
      if (!linkedinCredentials[0]?.li_at_cookie) {
         dispatch(openDialog());
         return; // Don't proceed with search if we don't have credentials
      }

      // Only perform search if we have the necessary data and we're initializing
      if (sessionID && linkedinCredentials[0]?.li_at_cookie) {
         // Only search if there's a valid query from URL params
         const urlQuery = new URLSearchParams(location.search).get("query");
         if (urlQuery && urlQuery.trim() !== "") {
            setQuery(urlQuery); // Set the query in the state
            setLastQuery(urlQuery); // Save as last query for future "Get More Results"
            // Note: We don't call handleSearch() here - we'll do it in the cleanup
            hasInitialized.current = true;
         } else if (lastQuery && lastQuery.trim() !== "" &&
            Object.keys(resultsCache).length === 0) {
            // Only auto-search with lastQuery if we don't have cached results
            hasInitialized.current = true;
         } else {
            // No valid search to perform, but we've still initialized
            hasInitialized.current = true;
         }
      }

      // Clear URL parameters after handling
      clearUrl();
   }, []);

   // Handle initial search once dependencies are ready
   const initialSearchRef = React.useRef(false);
   useEffect(() => {
      // Skip if we've already performed the initial search
      if (initialSearchRef.current) {
         return;
      }

      // Only proceed if we have all necessary dependencies
      if (!sessionID || !linkedinCredentials[0]?.li_at_cookie) {
         return;
      }

      // Check if we have a valid query to search for
      const urlQuery = new URLSearchParams(location.search).get("query");
      // if (urlQuery && urlQuery.trim() !== "") {
      //    // Execute the search
      //    initialSearchRef.current = true;
      //    handleSearch();
      // } else if (query && query.trim() !== "" && Object.keys(resultsCache).length === 0) {
      //    // Use the existing query if available and no cached results
      //    initialSearchRef.current = true;
      //    handleSearch();
      // }
   }, [sessionID, linkedinCredentials, query]);

   const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
         event.preventDefault();
         if (!query || query.trim() === "") {
            toast.error("Please enter a search query");
            return;
         }

         if (!linkedinCredentials[0]?.li_at_cookie) {
            toast.error("Please connect your LinkedIn account first");
            dispatch(openDialog());
            return;
         }

         // Reset everything for a fresh search
         setResults({});
         setResultsCache({});
         setOffset(0); // Reset offset to 0 for a new search
         setTotalResults(undefined);
         initialSearchRef.current = true; // Mark that we've done an initial search
         handleSearch();
      }
   };

   return (
      <div>
         <Box
            sx={{
               display: "flex",
               flexDirection: "column",
               alignItems: "center",
               justifyContent: "center",
               marginTop: "8rem",
            }}
         >
            {/* Search tokens info for non-paid users */}
            {!hasActivePaidPlan && remainingSearchTokens > 0 && (
               <Alert
                  severity="info"
                  sx={{
                     width: '100%',
                     maxWidth: '900px',
                     mb: 3,
                  }}
               >
                  You have {remainingSearchTokens} LinkedIn search {remainingSearchTokens === 1 ? 'token' : 'tokens'} remaining.
                  <Link
                     href="/recruiter/subscription-plan"
                     sx={{ ml: 1, fontWeight: 'bold' }}
                  >
                     Upgrade to PRO
                  </Link> for unlimited searches.
               </Alert>
            )}

            {/* No tokens left warning */}
            {!hasActivePaidPlan && remainingSearchTokens <= 0 && (
               <Alert
                  severity="warning"
                  sx={{
                     width: '100%',
                     maxWidth: '900px',
                     mb: 3,
                  }}
               >
                  You've used all your LinkedIn search tokens.
                  <Button
                     variant="contained"
                     color="primary"
                     size="small"
                     sx={{ ml: 2 }}
                     onClick={() => navigate("/recruiter/subscription-plan")}
                  >
                     Upgrade Now
                  </Button>
               </Alert>
            )}

            {/* LinkedIn limitation notice banner */}
            <Paper
               elevation={0}
               sx={{
                  width: '100%',
                  maxWidth: '900px',
                  mb: 3,
                  p: 2,
                  bgcolor: '#f2f9f5',
                  border: '1px solid #dceee5',
                  borderRadius: '8px'
               }}
            >
               <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 'bold', color: '#1f692e' }}>
                  LinkedIn Usage Guidelines
               </Typography>
               <Typography variant="body2" sx={{ color: '#333', fontSize: '0.9rem', mb: 1 }}>
                  LinkedIn applies these usage limits based on your account type:
               </Typography>
               <ul style={{ margin: '4px 0', paddingLeft: '20px' }}>
                  <li style={{ fontSize: '0.85rem', marginBottom: '6px' }}>
                     <strong>Search Limits:</strong> Free users: 500 profile views per day. Paid users (Recruiter/Sales Navigator): 2,000 profile views per day.
                  </li>
                  <li style={{ fontSize: '0.85rem', marginBottom: '6px' }}>
                     <strong>Connection Requests:</strong> Free accounts: recommended 80 per week. Paid accounts: maximum of 100 per week.
                  </li>
               </ul>
               <Typography variant="caption" sx={{ color: '#666' }}>
                  Using this tool responsibly helps maintain your LinkedIn account in good standing. 
                  <Link href="https://www.leadloft.com/blog/linkedin-limits" target="_blank" sx={{ ml: 1 }}>
                     Source: LeadLoft LinkedIn Limits 2025
                  </Link>
               </Typography>
            </Paper>

            <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 600, color: "#333", display: "flex", alignItems: "center", justifyContent: "center" }}>
               <Box
                  component="img"
                  src={require("../assets/linkedin.png")}
                  alt="LinkedIn Logo"
                  sx={{
                     height: "30px",
                     marginRight: "10px"
                  }}
               />
               LinkedIn People Search
            </Typography>

            {linkedinCredentials[0]?.li_at_cookie && (
               <>
                  <Typography variant="body1" color="text.secondary" sx={{ mb: 1, textAlign: "center", maxWidth: "800px" }}>
                     Search for professionals on LinkedIn by entering your search query below.
                  </Typography>
                  <Typography variant="caption" sx={{ mb: 3, textAlign: "center", maxWidth: "800px", display: "block", color: "warning.main" }}>
                     <strong>Note:</strong> LinkedIn limits the number of searches per session. Excessive searches may trigger LinkedIn's "Commercial Use Limit" which can temporarily restrict your search capabilities.
                  </Typography>
               </>
            )}

            <Box
               sx={{
                  marginTop: "1rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "70%",
               }}
            >
               <TextField
                  id="outlined-search"
                  type="search"
                  multiline
                  placeholder="Input the candidate you are looking for e.g. 'Python developer in New York'"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  onKeyPress={handleKeyPress}
                  sx={{
                     width: "100%",
                     "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                           borderColor: "#5fcf80",
                        },
                        "&:hover fieldset": {
                           borderColor: "#5fcf80",
                        },
                        "&.Mui-focused fieldset": {
                           borderColor: "#5fcf80",
                        },
                     },
                     "& .MuiInputLabel-root": {
                        color: "#397c4d",
                        "&.Mui-focused": {
                           color: "#397c4d", // Ensure label color stays the same when focused
                        },
                     },
                     "& .MuiInputBase-input": {
                        color: "#397c4d",
                     }, // Ensure label color stays the same when focused
                  }}
               />
               <Tooltip title="Search for the candidate">
                  <IconButton
                     disabled={isLoading || query === "" || !linkedinCredentials[0]?.li_at_cookie}
                     onClick={() => {
                        if (!query || query.trim() === "") {
                           toast.error("Please enter a search query");
                           return;
                        }

                        if (!linkedinCredentials[0]?.li_at_cookie) {
                           toast.error("Please connect your LinkedIn account first");
                           dispatch(openDialog());
                           return;
                        }

                        // Reset everything for a fresh search
                        setResults({});
                        setResultsCache({});
                        setOffset(0); // Reset offset to 0 for a new search
                        setTotalResults(undefined);
                        initialSearchRef.current = true; // Mark that we've done an initial search
                        handleSearch();
                     }}
                  >
                     <Search sx={{ color: "#56ba73" }} />
                  </IconButton>
               </Tooltip>

               <Tooltip title="Reuse the last query">
                  <IconButton
                     disabled={isLoading}
                     onClick={() => setQuery(lastQuery)}
                  >
                     <HistoryIcon sx={{ color: "#56ba73" }} />
                  </IconButton>
               </Tooltip>
            </Box>

            {isLoading && Object.keys(results).length === 0 ? (
               <Box sx={{ marginTop: "2rem", position: "relative", width: "100%", maxWidth: "700px" }}>
                  <Paper elevation={2} sx={{ p: 3, width: "100%", textAlign: "center" }}>
                     <Spinner />
                     <Typography variant="h6" sx={{ mt: 2, color: '#56ba73' }}>
                        {searchStatus}
                     </Typography>

                     {/* Progress log */}
                     {searchProgress.length > 0 && (
                        <Box sx={{ mt: 3, textAlign: "left", maxHeight: "200px", overflowY: "auto", p: 2, bgcolor: "#f5f5f5", borderRadius: 1 }}>
                           <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 'bold' }}>Search Progress:</Typography>
                           {searchProgress.map((message, idx) => (
                              <Typography key={idx} variant="body2" sx={{ mb: 0.5, color: '#555', fontSize: '0.85rem' }}>
                                 • {message}
                              </Typography>
                           ))}
                        </Box>
                     )}
                  </Paper>
               </Box>
            ) : (
               <Box
                  sx={{
                     justifySelf: "center",
                     width: "100%",
                     display: "flex",
                     flexDirection: "column",
                     alignItems: "center",
                  }}
               >
                  <Box
                     sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: { sm: "70%", md: "50%" },
                        marginY: "1rem",
                     }}
                  >
                     <Box>
                        <InputLabel
                           variant="standard"
                           htmlFor="uncontrolled-native"
                        >
                           OrderBy
                        </InputLabel>
                        <NativeSelect
                           value={
                              sortBy.length > 0
                                 ? `${sortBy[0].field}_${sortBy[0].sort}`
                                 : "default"
                           }
                           inputProps={{
                              name: "orderby",
                              id: "uncontrolled-native",
                           }}
                           onChange={(e) =>
                              setSortBy(sortModel[e.target.value])
                           }
                        >
                           <option value="score_desc">Score Desc</option>
                           <option value="score_asc">Score Asc</option>
                           <option value="default">Default</option>
                        </NativeSelect>
                     </Box>
                     <Tooltip title={
                        isLoading ? "Loading..." :
                           !linkedinCredentials[0]?.li_at_cookie ? "Connect your LinkedIn account first" :
                              query !== lastQuery ? "Click the search button first before getting more results" :
                                 100 <= Object.keys(results).length ? "Maximum of 100 results reached" :
                                    !hasActivePaidPlan && remainingSearchTokens <= 0 ? "You've used all your search tokens. Please upgrade to continue." :
                                       "Load additional results (Note: LinkedIn limits the number of searches per day)"
                     }>
                        <span> {/* Tooltip needs a wrapper span in case the button is disabled */}
                           <Button
                              sx={{
                                 backgroundColor: "#5fcf80",
                                 "&:hover": {
                                    backgroundColor: "#56ba73",
                                 },
                                 borderRadius: "20px",
                              }}
                              variant="contained"
                              disabled={
                                 isLoading ||
                                 query !== lastQuery ||
                                 100 <= Object.keys(results).length ||
                                 !linkedinCredentials[0]?.li_at_cookie ||
                                 (!hasActivePaidPlan && remainingSearchTokens <= 0)
                              }
                              onClick={() => {
                                 if (!linkedinCredentials[0]?.li_at_cookie) {
                                    toast.error("Please connect your LinkedIn account first");
                                    dispatch(openDialog());
                                    return;
                                 }

                                 if (!query || query.trim() === "") {
                                    toast.error("Please enter a search query");
                                    return;
                                 }

                                 // Check if tokens are available for non-paid users
                                 if (!hasActivePaidPlan && remainingSearchTokens <= 0) {
                                    toast.error("You've used all your search tokens. Please upgrade to continue searching.");
                                    setTimeout(() => navigate("/recruiter/subscription-plan"), 2000);
                                    return;
                                 }

                                 // We're getting more results for the same query
                                 handleSearch();
                              }}
                           >
                              Get More Results
                           </Button>
                        </span>
                     </Tooltip>

                     <Tooltip title={
                        selectedRows.length === 0 ? "Select at least one user to connect with" : "Send connection requests to selected users"
                     }>
                        <span>
                           <Button
                              sx={{
                                 backgroundColor: "#5fcf80",
                                 "&:hover": {
                                    backgroundColor: "#56ba73",
                                 },
                                 borderRadius: "20px",
                              }}
                              variant="contained"
                              disabled={selectedRows.length === 0}
                              onClick={() => {
                                 // Show connection limit warning first
                                 toast.info("LinkedIn limits users to approximately 100 connection requests per week. Excessive requests may trigger restrictions on your account.", {
                                    autoClose: 7000,
                                    position: "top-center"
                                 });
                                 setTimeout(() => handleConnection(), 1000);
                              }}
                           >
                              Connect on Linkedin
                           </Button>
                        </span>
                     </Tooltip>
                  </Box>
                  <DataGrid
                     sx={{
                        width: { xs: "100%", sm: "80%" },
                        maxWidth: "900px",
                        justifySelf: "start",
                        height: 500,
                        '& .MuiDataGrid-main': {
                           width: '100%',
                        },
                        '& .MuiDataGrid-cell': {
                           padding: '16px',
                        },
                        '& .MuiDataGrid-virtualScroller': {
                           overflowX: 'hidden',
                        }
                     }}
                     rows={Object.values(results)}
                     columns={columns}
                     getRowHeight={() => "auto"}
                     sortModel={sortBy}
                     onSortModelChange={(model) => setSortBy(model)}
                     getRowId={(result) => result.public_profile_id}
                     rowSelectionModel={selectedRows}
                     onRowSelectionModelChange={(newSelection) => {
                        setSelectedRows(newSelection);
                     }}
                     checkboxSelection
                     disableRowSelectionOnClick
                     disableColumnFilter
                     disableColumnMenu
                     initialState={{
                        columns: {
                           columnVisibilityModel: {
                              public_profile_id: false,
                              score: false,
                           },
                        },
                        pagination: {
                           paginationModel: {
                              pageSize: 5,
                           },
                        },
                     }}
                  />{" "}
               </Box>
            )}
         </Box>
      </div>
   );
}
