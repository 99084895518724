import React from "react";
import { createChat } from "../services/candidateApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { updateUserFirstSignIn } from "../services/userApi";

export const useUpdateFirstSignIn = () => {
  const queryClient = useQueryClient();
  const { mutate: updateFirstSignIn, isPending } = useMutation({
    mutationFn: () => updateUserFirstSignIn(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getMe"],
      });
    },
  });

  return { updateFirstSignIn, isPending };
};
