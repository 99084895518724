// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_button__OgbcK {
  font-size: 14px;
  color: #3b3d3c;
  background-color: #cccbcb;
  border: 2px solid rgb(114, 114, 114);
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.Button_button__OgbcK:hover {
  background-color: #e0e0e0;
  color: #2a2a2a;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.Button_button__OgbcK:active {
  background-color: #d0d0d0;
  color: #1a1a1a;
}
`, "",{"version":3,"sources":["webpack://./src/css/Button.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,cAAc;EACd,yBAAyB;EACzB,oCAAoC;EACpC,iBAAiB;EACjB,kBAAkB;EAClB,6EAA6E;EAC7E,0BAA0B;EAC1B,uBAAuB;EACvB,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,wCAAwC;AAC1C;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":[".button {\n  font-size: 14px;\n  color: #3b3d3c;\n  background-color: #cccbcb;\n  border: 2px solid rgb(114, 114, 114);\n  padding: 8px 16px;\n  border-radius: 4px;\n  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;\n  -webkit-border-radius: 4px;\n  -moz-border-radius: 4px;\n  -ms-border-radius: 4px;\n  -o-border-radius: 4px;\n}\n\n.button:hover {\n  background-color: #e0e0e0;\n  color: #2a2a2a;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.button:active {\n  background-color: #d0d0d0;\n  color: #1a1a1a;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Button_button__OgbcK`
};
export default ___CSS_LOADER_EXPORT___;
