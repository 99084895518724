import React from 'react';
import { Typography, Container, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link, Divider } from '@mui/material';

const PrivacyPolicy: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h1" component="h1" gutterBottom>
          Privacy Policy
        </Typography>

        <Typography variant="body1" paragraph>
          Effective date: January 22, 2025
        </Typography>

        <Typography variant="body1" paragraph>
          At CareerGPT, we take your privacy seriously. Please read this Privacy Policy to learn how we treat your personal data. By using or accessing our Services in any manner, you acknowledge that you accept the practices and policies outlined below, and you hereby consent that we will collect, use and disclose your information as described in this Privacy Policy.
        </Typography>

        <Typography variant="body1" paragraph>
          Remember that your use of CareerGPT'S Services is at all times subject to our Terms of Use, which incorporates this Privacy Policy. Any terms we use in this Policy without defining them have the definitions given to them in the Terms of Use.
        </Typography>

        <Typography variant="body1" paragraph>
          As we continually work to improve our Services, we may need to change this Privacy Policy from time to time. We will alert you of material changes by placing a notice on the CareerGPT website, by sending you an email and/or by some other means. Please note that if you've opted not to receive legal notice emails from us (or you haven't provided us with your email address), those legal notices will still govern your use of the Services, and you are still responsible for reading and understanding them. If you use the Services after any changes to the Privacy Policy have been posted, that means you agree to all of the changes.
        </Typography>

        <Divider sx={{ my: 4 }} />

        <Typography variant="h2" gutterBottom>
          Table of Contents
        </Typography>
        <Box sx={{ ml: 2, mb: 4 }}>
          <Typography component="div">
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li>• What this Privacy Policy Covers</li>
              <li>• Personal Data</li>
              <li>• Categories of Personal Data We Collect</li>
              <li>• Our Commercial or Business Purposes</li>
              <li>• Other Permitted Purposes for Processing Personal Data</li>
              <li>• Categories of Sources of Personal Data</li>
              <li>• How We Disclose Your Personal Data</li>
              <li>• Tracking Tools and Opt-Out</li>
              <li>• Data Security</li>
              <li>• Personal Data of Children</li>
              <li>• Other State Law Privacy Rights</li>
              <li>• European Union Data Subject Rights</li>
              <li>• Contact Information</li>
            </ul>
          </Typography>
        </Box>

        <Divider sx={{ my: 4 }} />

        <Typography variant="h2" gutterBottom>
          What this Privacy Policy Covers
        </Typography>
        <Typography variant="body1" paragraph>
          This Privacy Policy covers how we treat Personal Data that we gather when you access or use our Services. "Personal Data" means any information that identifies or relates to a particular individual and also includes information referred to as "personally identifiable information" or "personal information" under applicable data privacy laws, rules or regulations. This Privacy Policy does not cover the practices of companies we don't own or control or people we don't manage.
        </Typography>

        <Typography variant="h2" gutterBottom>
          Categories of Personal Data We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          This chart details the categories of Personal Data that we collect and have collected over the past 12 months:
        </Typography>

        <TableContainer component={Paper} sx={{ my: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Category of Personal Data</TableCell>
                <TableCell>Examples of Personal Data</TableCell>
                <TableCell>Business Purpose</TableCell>
                <TableCell>Third Parties</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
  <TableRow>
    <TableCell>Profile or Contact Data</TableCell>
    <TableCell>
      • Providing, Customizing and Improving the Services
      • Marketing the Services
      • Corresponding with You
    </TableCell>
    <TableCell>Service Providers</TableCell>
  </TableRow>
  <TableRow>
    <TableCell>Device/IP Data</TableCell>
    <TableCell>
      • Service Improvement
      • Analytics
      • Security
    </TableCell>
    <TableCell>Service Providers</TableCell>
  </TableRow>
  <TableRow>
    <TableCell>Social Network Data</TableCell>
    <TableCell>
      • Profile Enhancement
      • Service Integration
    </TableCell>
    <TableCell>Business Partners</TableCell>
  </TableRow>
  <TableRow>
    <TableCell>Professional Data</TableCell>
    <TableCell>
      • Service Customization
      • Career Services
    </TableCell>
    <TableCell>Business Partners</TableCell>
  </TableRow>
  <TableRow>
    <TableCell>Geolocation Data</TableCell>
    <TableCell>
      • Service Customization
      • Analytics
    </TableCell>
    <TableCell>Service Providers</TableCell>
  </TableRow>
  <TableRow>
    <TableCell>Sensory Data</TableCell>
    <TableCell>
      • Providing, Customizing and Improving the Services
      • Marketing the Services
      • Corresponding with You
    </TableCell>
    <TableCell>Business Partners</TableCell>
  </TableRow>
  <TableRow>
    <TableCell>Inferences Drawn From Other Personal Data</TableCell>
    <TableCell>
      • Providing, Customizing and Improving the Services
      • Marketing the Services
      • Corresponding with You
    </TableCell>
    <TableCell>Business Partners</TableCell>
  </TableRow>
</TableBody>

          </Table>
        </TableContainer>

        <Typography variant="h2" gutterBottom>
  Our Commercial or Business Purposes for Collecting Personal Data
</Typography>

<Typography variant="h3" gutterBottom>
  Providing, Customizing and Improving the Services
</Typography>
<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="body1" component="div">
    • Creating and managing your account or other user profiles
    • Providing you with the products, services or information you request
    • Meeting or fulfilling the reason you provided the information to us
    • Providing support and assistance for the Services
    • Improving the Services, including testing, research, internal analytics and product development
    • Personalizing the Services, website content and communications based on your preferences
    • Doing fraud protection, security and debugging
    • Carrying out other business purposes stated when collecting your Personal Data
    • Automated processing done in furtherance of making a decision that produces legal or similarly significant effects concerning you
  </Typography>
</Box>

<Typography variant="h3" gutterBottom>
  Marketing the Services
</Typography>
<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="body1" component="div">
    • Marketing and selling the Services
    • Showing you advertisements, including interest-based or online behavioral advertising
    • Sending promotional communications
    • Measuring advertising effectiveness
  </Typography>
</Box>

<Typography variant="h3" gutterBottom>
  Corresponding with You
</Typography>
<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="body1" component="div">
    • Responding to correspondence that we receive from you
    • Contacting you when necessary or requested
    • Sending you information about CareerGPT or the Services
    • Providing customer support and assistance
  </Typography>
</Box>

<Typography variant="h3" gutterBottom>
  Other Permitted Purposes for Processing Personal Data
</Typography>
<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="body1" paragraph>
    In addition to the above, we may collect, use, and disclose Personal Data for the following purposes:
  </Typography>
  <Typography variant="body1" component="div">
    • Fulfilling our legal obligations under applicable law, regulation, court order or other legal process
    • Preventing, detecting and investigating security incidents and potentially illegal or prohibited activities
    • Protecting the rights, property or safety of you, CareerGPT or another party
    • Enforcing any agreements with you
    • Responding to claims that any posting or other content violates third-party rights
    • Resolving disputes
    • Mergers, acquisitions, or other business transfers
  </Typography>
</Box>

<Typography variant="body1" paragraph sx={{ mt: 2 }}>
  We will not collect additional categories of Personal Data or use the Personal Data we collected for materially different, unrelated, or incompatible purposes without providing you notice or obtaining your consent.
</Typography>

<Typography variant="h2" gutterBottom>
  Categories of Sources of Personal Data
</Typography>

<Typography variant="body1" paragraph>
  We collect Personal Data about you from the following categories of sources:
</Typography>

<Typography variant="h3" gutterBottom>
  Direct Collection From You
</Typography>
<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="body1" component="div">
    • When you provide information directly to us
    • When you create an account or use our interactive tools and Services
    • When you voluntarily provide information in free-form text boxes through the Services
    • When you respond to surveys or questionnaires
    • When you send us an email or otherwise contact us
  </Typography>
</Box>

<Typography variant="h3" gutterBottom>
  Automatic Collection
</Typography>
<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="body1" component="div">
    • Through Cookies and tracking technologies
    • When you use the Services and information is collected automatically
    • If you download our applications or software, we may receive information transmitted from your computing device
    • Information regarding when you're logged on and available to receive updates or alert notices
    • Usage patterns and preferences
  </Typography>
</Box>

<Typography variant="h3" gutterBottom>
  Public Sources
</Typography>
<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="body1" component="div">
    • From public social media posts
    • From publicly available records
    • From public professional profiles
  </Typography>
</Box>

<Typography variant="h3" gutterBottom>
  Third-Party Sources
</Typography>
<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="body1" component="div">
    • Third-Party Account Credentials
    • If you provide your third-party account credentials (such as social network accounts)
    • When you sign in through a third-party site or service
    • Content and information from integrated third-party services
  </Typography>
</Box>

<Typography variant="body1" paragraph sx={{ mt: 2 }}>
  When you provide third-party account credentials or sign in through a third-party site or service, some content and/or information from those accounts may be transmitted into your account with us. This transmission of data is governed by the privacy settings and policies of the third-party services.
</Typography>

<Typography variant="h2" gutterBottom>
  How We Disclose Your Personal Data
</Typography>

<Typography variant="body1" paragraph>
  We disclose your Personal Data to the categories of service providers and other parties listed in this section. Depending on state laws that may be applicable to you, some of these disclosures may constitute a "sale" of your Personal Data.
</Typography>

<Typography variant="h3" gutterBottom>
  Service Providers
</Typography>
<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="body1" paragraph>
    These parties help us provide the Services or perform business functions on our behalf. They include:
  </Typography>
  <Typography variant="body1" component="div">
    • Hosting, technology and communication providers
    • Analytics providers for web traffic or usage of the site
    • Security and fraud prevention consultants
    • Support and customer service vendors
  </Typography>
</Box>

<Typography variant="h3" gutterBottom>
  Advertising Partners
</Typography>
<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="body1" paragraph>
    These parties help us market our services and provide you with other offers that may be of interest to you. They include:
  </Typography>
  <Typography variant="body1" component="div">
    • Ad networks
    • Data brokers
    • Marketing providers
  </Typography>
</Box>

<Typography variant="h3" gutterBottom>
  Business Partners
</Typography>
<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="body1" paragraph>
    These parties partner with us in offering various services. They include:
  </Typography>
  <Typography variant="body1" component="div">
    • Companies that we partner with to offer joint promotional offers or opportunities
    • Integration partners
    • Social media services
  </Typography>
</Box>

<Typography variant="h3" gutterBottom>
  Legal Obligations
</Typography>
<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="body1" paragraph>
    We may disclose any Personal Data that we collect with third parties in conjunction with any of the activities set forth under "Other Permitted Purposes for Processing Personal Data" section above.
  </Typography>
</Box>

<Typography variant="h3" gutterBottom>
  Business Transfers
</Typography>
<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="body1" paragraph>
    All of your Personal Data that we collect may be transferred to a third party if we undergo a merger, acquisition, bankruptcy or other transaction in which that third party assumes control of our business (in whole or in part).
  </Typography>
</Box>

<Typography variant="h3" gutterBottom>
  Data that is Not Personal Data
</Typography>
<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="body1" paragraph>
    We may create aggregated, de-identified or anonymized data from the Personal Data we collect, including by removing information that makes the data personally identifiable to a particular user. We may use such aggregated, de-identified or anonymized data and disclose it with third parties for our lawful business purposes, including to analyze, build and improve the Services and promote our business, provided that we will not disclose such data in a manner that could identify you.
  </Typography>
</Box>

<Typography variant="h2" gutterBottom>
  Tracking Tools and Opt-Out
</Typography>

<Typography variant="body1" paragraph>
  The Services use cookies and similar technologies such as pixel tags, web beacons, clear GIFs and JavaScript (collectively, "Cookies") to enable our servers to recognize your web browser, tell us how and when you visit and use our Services, analyze trends, learn about our user base and operate and improve our Services. Cookies are small pieces of data– usually text files – placed on your computer, tablet, phone or similar device when you use that device to access our Services.
</Typography>

<Typography variant="body1" paragraph>
  Please note that because of our use of Cookies, the Services do not support "Do Not Track" requests sent from a browser at this time.
</Typography>

<Typography variant="h3" gutterBottom>
  Types of Cookies We Use
</Typography>

<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="h4" gutterBottom>
    Essential Cookies
  </Typography>
  <Typography variant="body1" paragraph>
    Essential Cookies are required for providing you with features or services that you have requested. For example, certain Cookies enable you to log into secure areas of our Services. Disabling these Cookies may make certain features and services unavailable.
  </Typography>

  <Typography variant="h4" gutterBottom>
    Performance/Analytical Cookies
  </Typography>
  <Typography variant="body1" paragraph>
    Performance/Analytical Cookies allow us to understand how visitors use our Services by collecting information about:
  </Typography>
  <Box sx={{ ml: 2, mb: 2 }}>
    <Typography variant="body1" component="div">
      • The number of visitors to the Services
      • What pages visitors view on our Services
      • How long visitors are viewing pages on the Services
      • Performance of our advertising campaigns
    </Typography>
  </Box>
  <Typography variant="body1" paragraph>
    For example, Google LLC ("Google") uses cookies in connection with its Google Analytics services. Google's ability to use and disclose information collected by Google Analytics about your visits to the Services is subject to the Google Analytics Terms of Use and the Google Privacy Policy. You have the option to opt-out of Google's use of Cookies by visiting the Google advertising opt-out page at www.google.com/privacy_ads.html or the Google Analytics Opt-out Browser Add-on at https://tools.google.com/dlpage/gaoptout/.
  </Typography>
</Box>

<Typography variant="h3" gutterBottom>
  Your Cookie Choices
</Typography>
<Typography variant="body1" paragraph>
  You can decide whether or not to accept Cookies through your internet browser's settings. Most browsers have an option for turning off the Cookie feature, which will prevent your browser from accepting new Cookies, as well as allow you to decide on acceptance of each new Cookie in a variety of ways. You can also delete all Cookies that are already on your device.
</Typography>

<Typography variant="body1" paragraph>
  If you do disable Cookies, however, you may have to manually adjust some preferences every time you visit our website and some of the Services and functionalities may not work.
</Typography>

<Typography variant="body1" paragraph>
  To find out more information about Cookies generally, including information about how to manage and delete Cookies, please visit:
</Typography>
<Box sx={{ ml: 2, mb: 2 }}>
  <Typography variant="body1" component="div">
    • http://www.allaboutcookies.org/
    • https://ico.org.uk/for-the-public/online/cookies/ (if you are located in the European Union)
  </Typography>
</Box>
<Typography variant="h2" gutterBottom>
  Data Security and Data Retention
</Typography>

<Typography variant="body1" paragraph>
  We seek to protect your Personal Data from unauthorized access, use and disclosure using appropriate physical, technical, organizational and administrative security measures based on the type of Personal Data and how we are processing that data.
</Typography>

<Typography variant="h3" gutterBottom>
  Security Measures
</Typography>
<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="body1" component="div">
    • Physical security controls
    • Encryption of personal data
    • Firewalls and access controls
    • Secure user authentication protocols
    • Regular security assessments
    • Restricted staff access to personal information
  </Typography>
</Box>

<Typography variant="h3" gutterBottom>
  Your Security Responsibilities
</Typography>
<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="body1" component="div">
    You should help protect your data by:
    • Appropriately selecting and protecting your password
    • Limiting access to your computer or device and browser
    • Signing off after finishing accessing your account
    • Ensuring your connection is secure when accessing Services
  </Typography>
</Box>

<Typography variant="h3" gutterBottom>
  Data Retention
</Typography>
<Typography variant="body1" paragraph>
  We retain Personal Data about you for as long as necessary to provide you with our Services or to perform our business or commercial purposes. When establishing a retention period, we consider:
</Typography>

<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="body1" component="div">
    • Who we collected the data from
    • Our need for the Personal Data
    • Why we collected the Personal Data
    • The sensitivity of the Personal Data
    • Legal obligations and requirements
  </Typography>
</Box>

<Typography variant="h3" gutterBottom>
  Retention Examples
</Typography>
<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="body1" component="div">
    • Profile information and credentials: Retained for as long as you have an active account
    • Device/IP data: Retained as needed for system security and efficiency
    • Usage data: Retained for analysis and service improvement
    • Payment information: Retained as required by law and financial regulations
  </Typography>
</Box>

<Typography variant="body1" paragraph>
  Although we work to protect the security of your account and other data that we hold in our records, please be aware that no method of transmitting data over the internet or storing data is completely secure. We cannot guarantee the absolute security of any information transmitted to or from the Services.
</Typography>

<Typography variant="h2" gutterBottom>
  Personal Data of Children
</Typography>

<Typography variant="body1" paragraph>
  As noted in the Terms of Use, we do not knowingly collect or solicit Personal Data from children under 16 years of age; if you are a child under the age of 16, please do not attempt to register for or otherwise use the Services or send us any Personal Data.
</Typography>

<Typography variant="body1" paragraph>
  If we learn we have collected Personal Data from a child under 16 years of age, we will delete that information as quickly as possible. If you believe that a child under 16 years of age may have provided Personal Data to us, please contact us at support@careergpt.io.
</Typography>

<Typography variant="h3" gutterBottom>
  Protection Measures
</Typography>
<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="body1" component="div">
    To protect children's privacy and comply with applicable laws, we:
    • Do not knowingly collect data from children under 16
    • Remove any child's personal information upon discovery
    • Implement age verification measures where appropriate
    • Require parental consent for any permitted collection of children's data
  </Typography>
</Box>

<Typography variant="h3" gutterBottom>
  Reporting Concerns
</Typography>
<Typography variant="body1" paragraph>
  If you have concerns about children's privacy or believe a child may have provided personal information to us, please contact us immediately at support@careergpt.io. We take these matters seriously and will promptly investigate and address any reported issues.
</Typography>

<Typography variant="h2" gutterBottom>
  Other State Law Privacy Rights
</Typography>

<Typography variant="h3" gutterBottom>
  California Resident Rights
</Typography>
<Typography variant="body1" paragraph>
  Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent disclosure of Personal Data to third parties for such third parties' direct marketing purposes. To submit such a request, please contact us at support@careergpt.io.
</Typography>

<Typography variant="body1" paragraph>
  Your browser may offer you a "Do Not Track" option, which allows you to signal to operators of websites and web applications and services that you do not wish such operators to track certain of your online activities over time and across different websites. Our Services do not support Do Not Track requests at this time. To find out more about "Do Not Track," you can visit www.allaboutdnt.com.
</Typography>

<Typography variant="h3" gutterBottom>
  Nevada Resident Rights
</Typography>
<Typography variant="body1" paragraph>
  If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal Data to third parties. You can exercise this right by contacting us at support@careergpt.io with the subject line "Nevada Do Not Sell Request" and providing us with your name and the email address associated with your account.
</Typography>

<Typography variant="h3" gutterBottom>
  European Union and United Kingdom Data Subject Rights
</Typography>
<Typography variant="body1" paragraph>
  If you are a resident of the European Union ("EU"), United Kingdom ("UK"), Lichtenstein, Norway or Iceland, you may have additional rights under the EU or UK General Data Protection Regulation (the "GDPR") with respect to your Personal Data.
</Typography>

<Typography variant="body1" paragraph>
  CareerGPT will be the controller of your Personal Data processed in connection with the Services. If you have any questions about this section or whether any of the following applies to you, please contact us at support@careergpt.io.
</Typography>

<Typography variant="h4" gutterBottom>
  Your Rights Under GDPR
</Typography>
<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="body1" component="div">
    • Access: Request information about and copies of your Personal Data
    • Rectification: Correct inaccurate or incomplete Personal Data
    • Erasure: Request deletion of your Personal Data
    • Withdrawal of Consent: Withdraw previously given consent
    • Portability: Receive your Personal Data in a machine-readable format
    • Objection: Object to certain uses of your Personal Data
    • Restriction of Processing: Limit how we process your Personal Data
    • Right to File Complaint: Lodge complaints with supervisory authorities
  </Typography>
</Box>

<Typography variant="h4" gutterBottom>
  Processing Grounds
</Typography>
<Typography variant="body1" paragraph>
  We will only process your Personal Data if we have a lawful basis for doing so, including:
</Typography>
<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="body1" component="div">
    • Contractual Necessity
    • Legitimate Interests
    • Your Consent
    • Legal Obligations
    • Protection of Vital Interests
    • Public Interest Tasks
  </Typography>
</Box>

<Typography variant="h2" gutterBottom>
  How We Disclose Your Personal Data
</Typography>

<Typography variant="body1" paragraph>
  We disclose your Personal Data to the categories of service providers and other parties listed in this section. Depending on state laws that may be applicable to you, some of these disclosures may constitute a "sale" of your Personal Data.
</Typography>

<Typography variant="h3" gutterBottom>
  EU, UK and Swiss Data Subject Rights
</Typography>

<Typography variant="body1" paragraph>
  You have certain rights with respect to your Personal Data. To submit a request or learn more about these rights, please email us at support@careergpt.io. While we strive to honor all valid requests, in some circumstances we may not be able to fully comply if the request is frivolous, impractical, or jeopardizes others' rights.
</Typography>

<Box sx={{ ml: 2, mb: 3 }}>
  <Typography variant="h4" gutterBottom>
    Your Rights Include:
  </Typography>
  
  <Typography variant="h5" gutterBottom>
    Access
  </Typography>
  <Typography variant="body1" paragraph>
    • Request information about your stored Personal Data
    • Obtain a copy of your Personal Data
    • Access certain data directly through your account
  </Typography>

  <Typography variant="h5" gutterBottom>
    Rectification
  </Typography>
  <Typography variant="body1" paragraph>
    • Request corrections to inaccurate data
    • Supplement incomplete information
    • Update certain information directly through your account
  </Typography>

  <Typography variant="h5" gutterBottom>
    Erasure
  </Typography>
  <Typography variant="body1" paragraph>
    • Request deletion of some or all Personal Data from our systems
    • Subject to legal retention requirements and service necessity
  </Typography>

  <Typography variant="h5" gutterBottom>
    Withdrawal of Consent
  </Typography>
  <Typography variant="body1" paragraph>
    • Withdraw previously given consent at any time
    • May require case-by-case consent for specific services
    • Withdrawal might affect service availability
  </Typography>

  <Typography variant="h5" gutterBottom>
    Data Portability
  </Typography>
  <Typography variant="body1" paragraph>
    • Receive your data in machine-readable format
    • Request data transfer to another controller where feasible
  </Typography>

  <Typography variant="h5" gutterBottom>
    Objection Rights
  </Typography>
  <Typography variant="body1" paragraph>
    • Object to specific data uses
    • Opt-out of direct marketing
    • Contest automated decision-making
  </Typography>

  <Typography variant="h5" gutterBottom>
    Processing Restrictions
  </Typography>
  <Typography variant="body1" paragraph>
    • Request limits on data processing
    • Temporary suspension of certain data uses
  </Typography>

  <Typography variant="h5" gutterBottom>
    Complaint Rights
  </Typography>
  <Typography variant="body1" paragraph>
    • Lodge complaints with supervisory authorities
    • Access authorities list at: https://edpb.europa.eu/about-edpb/board/members_en
  </Typography>
</Box>

<Typography variant="h3" gutterBottom>
  Additional Information
</Typography>

<Typography variant="body1" paragraph>
  To verify requests, we may need additional Personal Data to confirm your identity. We will respond to all valid requests within the timeframes required by applicable law. For complex requests or multiple requests, we may extend our response time, but we will notify you of any delays.
</Typography>

      

        <Typography variant="h2" gutterBottom>
          Contact Information
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or comments about this Privacy Policy, please contact us at:
        </Typography>
        <Box sx={{ ml: 2 }}>
          <Typography variant="body1">
            Website: <Link href="https://www.careergpt.io">www.careergpt.io</Link><br />
            Email: <Link href="mailto:support@careergpt.io">support@careergpt.io</Link><br />
            Address: 1221 College Park Dr Suite 116, Dover, Delaware 19904
          </Typography>
        </Box>
      </Box>



    </Container>
  );
};

export default PrivacyPolicy;















