import { SHA256, AES, lib, pad, enc } from "crypto-js";
import { COOKIE_HASH_KEY } from "../services/api";

export function encryptAndStorePassword(password: string) {
   const secretKey = COOKIE_HASH_KEY as string; // Ideally, derive a key securely

   // Generate a random 16-byte IV for encryption
   const iv = lib.WordArray.random(16);

   // Encrypt the password using AES-CBC mode with IV and PKCS7 padding
   const hashedKey = SHA256(secretKey); // Hash the key
   const encryptedPassword = AES.encrypt(password, hashedKey, {
      iv: iv,
      padding: pad.Pkcs7,
   });

   // Convert the IV and ciphertext to base64 strings separately
   const ivBase64 = iv.toString(enc.Base64);
   const encryptedPasswordBase64 = encryptedPassword.toString();

   // Return both IV and encrypted password as separate values
   return { iv: ivBase64, encryptedValue: encryptedPasswordBase64 };
}
