import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useGetMe } from "../hooks/useGetMe";
import Spinner from "../ui/Spinner";

export default function PublicRoutes() {
   const { myData, isPending } = useGetMe();

   // console.log("PublicRoutes - Current Path:", window.location.pathname);
   // console.log("PublicRoutes - myData:", myData);
   // console.log("PublicRoutes - authToken:", sessionStorage.getItem("authToken"));
   // console.log("PublicRoutes - localStorage userType:", localStorage.getItem("userType"));

   if (isPending) {
      // console.log("PublicRoutes - Still pending...");
      return <Spinner />;
   }

   const authToken = sessionStorage.getItem("authToken");
   const localUserType = localStorage.getItem("userType");

   // If we're already on a protected route, don't interfere
   if (window.location.pathname.match(/\/(candidate|recruiter|coach)\//)) {
      // console.log("PublicRoutes - Already on protected route, allowing...");
      return <Outlet />;
   }

   // If we have auth data, redirect to appropriate route
   if (myData?.userType || (authToken && localUserType)) {
      const userType = myData?.userType || localUserType;
      const route = userType === "candidate" ? "/candidate/auto-apply" :
         userType === "recruiter" ? "/recruiter/peoplesearch" :
            "/coach/connect-to-professionals";

      // console.log("PublicRoutes - Redirecting to:", route);
      return <Navigate to={route} replace />;
   }

   return <Outlet />;
}
