import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSearchToken as useSearchTokenApi } from '../services/recruiterApi';

/**
 * Hook for consuming a search token for recruiters
 * Automatically invalidates the getRecruiterMe query to refresh token count
 */
export const useSearchToken = () => {
  const queryClient = useQueryClient();
  
  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationFn: useSearchTokenApi,
    onSuccess: () => {
      // Invalidate the recruiter data query to refetch the updated token count
      queryClient.invalidateQueries({ queryKey: ['getRecruiterMe'] });
    }
  });

  const consumeSearchToken = async () => {
    try {
      return await mutateAsync();
    } catch (error) {
      console.error('Error consuming search token:', error);
      return null;
    }
  };

  return {
    consumeSearchToken,
    isPending,
    isError,
    error
  };
}; 