import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
   FormControlLabel,
   Checkbox,
   Link,
   Typography,
   Paper,
   Box,
} from "@mui/material";
import { useSessionStorage } from "@uidotdev/usehooks";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../app/hooks.ts";
import { closeDialog, selectIsOpen } from "./authDialogSlice.ts";
import { encryptAndStorePassword } from "../../util/encryptAndStorePassword.ts";
import { useEffect } from "react";

function ToastSuccess() {
   return (
      <Paper sx={{ boxShadow: "none", border: "none" }}>
         <Typography>LinkedIn Cookie Successfully Added</Typography>
         <Typography>You can now use the People Search feature.</Typography>
      </Paper>
   );
}

export default function AuthDialog() {
   const dispatch = useAppDispatch();
   const isOpen = useAppSelector(selectIsOpen);
   const [lc, setLinkedinCredentials] = useSessionStorage(
      "linkedinCredentials",
      {}
   );

   const handleClose = () => {
      dispatch(closeDialog());
   };

   // Add useEffect to scroll dialog content to top when opened
   useEffect(() => {
      if (isOpen) {
         // Small timeout to ensure the dialog is rendered before scrolling
         setTimeout(() => {
            const dialogContent = document.querySelector('.MuiDialogContent-root');
            if (dialogContent) {
               dialogContent.scrollTop = 0;
            }
         }, 100);
      }
   }, [isOpen]);

   function handleLinkedinCookie(cookie) {
      if (!cookie) return false;
      const cookie_val =
         //{ li_at_cookie: { li_at: cookie } };
         { li_at_cookie: encryptAndStorePassword(cookie) };
      setLinkedinCredentials(cookie_val);
      return true;
   }
   return (
      <>
         <Dialog
            open={isOpen}
            onClose={(event, reason) => {
               // Only allow closing if a cookie has been set
               if (Object.keys(lc).length > 0) {
                  handleClose();
               }
            }}
            disableEscapeKeyDown={Object.keys(lc).length === 0}
            PaperProps={{
               component: "form",
               onSubmit: (event) => {
                  event.preventDefault();
                  const formData = new FormData(event.currentTarget);
                  const formJson = Object.fromEntries(formData.entries());
                  const li_at = formJson.li_at;

                  if (handleLinkedinCookie(li_at)) {
                     toast(ToastSuccess, { autoClose: 3000 });
                     handleClose();
                  } else {
                     toast.error("Cookie is invalid");
                  }
               },
               sx: {
                  borderRadius: "12px",
                  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
                  width: "100%",
                  maxWidth: "500px",
               },
            }}
         >
            <DialogTitle>LinkedIn Cookie Required</DialogTitle>
            <DialogContent>
               <Typography variant="body1" color="text.primary" sx={{ mb: 2, fontWeight: 'medium' }}>
                  To use the People Search feature, you need to get your LinkedIn cookie:
               </Typography>

               <Box sx={{ mb: 3, p: 2, bgcolor: '#f5f5f5', borderRadius: 2 }}>
                  <Typography variant="body2" component="ol" sx={{ pl: 2 }}>
                     <li style={{ marginBottom: '16px' }}>
                        <strong>Step 1:</strong> Install the <Link
                           href="https://chromewebstore.google.com/detail/cookie-getter-for-linkedi/immmieibmckdaddedcnmmbececcdkkga?hl=en"
                           target="_blank"
                           rel="noopener noreferrer"
                           sx={{ fontWeight: 'bold', color: '#56BA73' }}
                        >
                           Cookie Getter for LinkedIn
                        </Link> extension and make sure you're logged into LinkedIn
                     </li>
                     <li style={{ marginBottom: '16px' }}>
                        <strong>Step 2:</strong> Click the extension icon and click the blue "Copy all" button
                     </li>
                     <li style={{ marginBottom: '16px' }}>
                        <strong>Step 3:</strong> Paste into a text editor and find the value next to <span style={{ color: '#56BA73', fontWeight: 'bold' }}>li_at</span>
                     </li>
                     <li>
                        <strong>Step 4:</strong> Copy ONLY the <span style={{ color: '#56BA73', fontWeight: 'bold' }}>li_at</span> value and paste it below
                     </li>
                  </Typography>

                  <Box sx={{
                     mt: 3,
                     p: 2,
                     bgcolor: '#e9ecef',
                     borderRadius: 2,
                     border: '1px solid #dee2e6'
                  }}>
                     <Box component="img"
                        src={require("../../assets/Cookies.png")}
                        alt="Example of Cookie Getter extension showing the li_at value"
                        sx={{
                           width: '100%',
                           maxWidth: '400px',
                           border: '1px solid #ddd',
                           borderRadius: 1,
                           mb: 2
                        }}
                     />
                     <Typography variant="body2" sx={{ fontStyle: 'italic', fontWeight: 'medium', textAlign: 'center' }}>
                        Copy ONLY the <span style={{ color: '#56BA73', fontWeight: 'bold' }}>li_at</span> value from the "Value" column (entire text between the quotations)
                     </Typography>
                  </Box>
               </Box>

               <TextField
                  sx={{
                     "& .MuiInputLabel-root": {
                        color: "#56BA73",
                        "&.Mui-focused": {
                           color: "#397c4d",
                        },
                     },
                     "& .MuiInput-underline:before": {
                        borderBottomColor: "#56BA73",
                     },
                     "& .MuiInput-underline:after": {
                        borderBottomColor: "#56BA73",
                     },
                     "& .MuiInputBase-input": { color: "#56BA73" },
                     "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                           borderColor: "#5fcf80",
                        },
                        "&:hover fieldset": {
                           borderColor: "#5fcf80",
                        },
                        "&.Mui-focused fieldset": {
                           borderColor: "#5fcf80",
                        },
                     },
                     mb: 2,
                  }}
                  autoFocus
                  required
                  margin="dense"
                  id="li_at"
                  name="li_at"
                  label="LinkedIn Cookie Value (li_at)"
                  placeholder="Paste your li_at cookie value here"
                  fullWidth
                  variant="standard"
               />

               <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                  Your cookie will be stored securely and used only to access LinkedIn data for the People Search feature.
               </Typography>

               <FormControlLabel
                  required
                  control={<Checkbox />}
                  label="I have read and agree to the"
                  sx={{ mr: 0, mt: 2 }}
               />
               <Link
                  href="/terms-of-service"
                  target="_blank"
                  sx={{
                     textDecoration: "none",
                     display: "inline-block",
                     transform: "translateY(2px)",
                     fontWeight: "600",
                     color: "#5fcf80",
                  }}
               >
                  Terms of Service
               </Link>
            </DialogContent>
            <DialogActions>
               <Button
                  sx={{ color: "#56BA73" }}
                  onClick={handleClose}
                  disabled={Object.keys(lc).length === 0}
               >
                  Cancel
               </Button>
               <Button
                  sx={{
                     bgcolor: "#56BA73",
                     color: "white",
                     "&:hover": {
                        bgcolor: "#397c4d",
                     },
                  }}
                  type="submit"
               >
                  Submit
               </Button>
            </DialogActions>
         </Dialog>
      </>
   );
}
