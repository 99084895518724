// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes candidateNavBar_slideIn__zw-Wm {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}
@keyframes candidateNavBar_slideInFromRight__3BMO0 {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes candidateNavBar_slideOutToRight__zT8uf {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}


@keyframes candidateNavBar_slideOut__ohzEV {
  from {
    left: 0;
  }
  to {
    left: -100%;
  }
}

/* Apply animations to sidebar */
.candidateNavBar_slide-in__9Mhai {
  animation: candidateNavBar_slideIn__zw-Wm 0.5s forwards;
}

.candidateNavBar_slide-out__7KRql {
  animation: candidateNavBar_slideOut__ohzEV 0.5s forwards;
}
`, "",{"version":3,"sources":["webpack://./src/css/candidateNavBar.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,WAAW;EACb;EACA;IACE,OAAO;EACT;AACF;AACA;EACE;IACE,2BAA2B;IAC3B,UAAU;EACZ;EACA;IACE,wBAAwB;IACxB,UAAU;EACZ;AACF;;AAEA;EACE;IACE,wBAAwB;IACxB,UAAU;EACZ;EACA;IACE,2BAA2B;IAC3B,UAAU;EACZ;AACF;;;AAGA;EACE;IACE,OAAO;EACT;EACA;IACE,WAAW;EACb;AACF;;AAEA,gCAAgC;AAChC;EACE,uDAAgC;AAClC;;AAEA;EACE,wDAAiC;AACnC","sourcesContent":["@keyframes slideIn {\n  from {\n    left: -100%;\n  }\n  to {\n    left: 0;\n  }\n}\n@keyframes slideInFromRight {\n  0% {\n    transform: translateX(100%);\n    opacity: 0;\n  }\n  100% {\n    transform: translateX(0);\n    opacity: 1;\n  }\n}\n\n@keyframes slideOutToRight {\n  0% {\n    transform: translateX(0);\n    opacity: 1;\n  }\n  100% {\n    transform: translateX(100%);\n    opacity: 0;\n  }\n}\n\n\n@keyframes slideOut {\n  from {\n    left: 0;\n  }\n  to {\n    left: -100%;\n  }\n}\n\n/* Apply animations to sidebar */\n.slide-in {\n  animation: slideIn 0.5s forwards;\n}\n\n.slide-out {\n  animation: slideOut 0.5s forwards;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slide-in": `candidateNavBar_slide-in__9Mhai`,
	"slideIn": `candidateNavBar_slideIn__zw-Wm`,
	"slide-out": `candidateNavBar_slide-out__7KRql`,
	"slideOut": `candidateNavBar_slideOut__ohzEV`,
	"slideInFromRight": `candidateNavBar_slideInFromRight__3BMO0`,
	"slideOutToRight": `candidateNavBar_slideOutToRight__zT8uf`
};
export default ___CSS_LOADER_EXPORT___;
