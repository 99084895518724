// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubscriptionOver_overlay__qBvLN {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: none;
}

.SubscriptionOver_messageBox__yq5b8 {
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  pointer-events: auto;
}

.SubscriptionOver_title__EIKeh {
  margin-bottom: 20px;
}

.SubscriptionOver_planList__3kJtt {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.SubscriptionOver_planItem__b1oPT {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.SubscriptionOver_planItem__b1oPT:hover {
  background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/css/SubscriptionOver.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,aAAa;EACb,UAAU;EACV,gBAAgB;EAChB,yCAAyC;EACzC,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n  pointer-events: none;\n}\n\n.messageBox {\n  background: white;\n  border-radius: 10px;\n  padding: 20px;\n  width: 90%;\n  max-width: 500px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  text-align: center;\n  pointer-events: auto;\n}\n\n.title {\n  margin-bottom: 20px;\n}\n\n.planList {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.planItem {\n  background-color: #007bff;\n  color: white;\n  border: none;\n  border-radius: 5px;\n  padding: 10px;\n  cursor: pointer;\n  transition: background-color 0.3s;\n}\n\n.planItem:hover {\n  background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `SubscriptionOver_overlay__qBvLN`,
	"messageBox": `SubscriptionOver_messageBox__yq5b8`,
	"title": `SubscriptionOver_title__EIKeh`,
	"planList": `SubscriptionOver_planList__3kJtt`,
	"planItem": `SubscriptionOver_planItem__b1oPT`
};
export default ___CSS_LOADER_EXPORT___;
