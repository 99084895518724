import React, { useEffect, useRef } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io";
import imagebg1 from "./../assets/BGimage1.png";
import imagebg2 from "./../assets/BGimage2.png";
import imagebg3 from "./../assets/BGimage3.png";

function MobileCarousel() {
  const [index, setIndex] = React.useState(0);
  const carouselRef = useRef(null);

  // Auto-scrolling functionality
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handlePrev = () => {
    setIndex(index === 0 ? 2 : index - 1);
  };

  const handleNext = () => {
    setIndex(index === 2 ? 0 : index + 1);
  };

  return (
    <div className="mt-5 w-100 d-block d-sm-none">
      <div style={{
        borderRadius: '12px',
        overflow: 'hidden',
        boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        backgroundColor: '#f8f8f8',
        height: '250px',
        position: 'relative'
      }}>
        <div
          style={{
            position: 'absolute',
            left: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 10,
            cursor: 'pointer',
            fontSize: '40px',
            color: 'white',
            textShadow: '0 0 8px rgba(0,0,0,0.7)',
            background: 'rgba(0,0,0,0.2)',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClick={handlePrev}
          aria-label="Previous slide"
        >
          <IoIosArrowDropleftCircle />
        </div>

        <div
          style={{
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 10,
            cursor: 'pointer',
            fontSize: '40px',
            color: 'white',
            textShadow: '0 0 8px rgba(0,0,0,0.7)',
            background: 'rgba(0,0,0,0.2)',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClick={handleNext}
          aria-label="Next slide"
        >
          <IoIosArrowDroprightCircle />
        </div>

        <Carousel
          ref={carouselRef}
          style={{ height: '100%' }}
          indicators={true}
          controls={false}
          interval={null} // Disable built-in interval as we're using our custom one
          activeIndex={index}
          onSelect={handleSelect}
        >
          <Carousel.Item>
            <div style={{
              height: '250px',
              backgroundImage: `url(${imagebg1})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center'
            }}></div>
          </Carousel.Item>
          <Carousel.Item>
            <div style={{
              height: '250px',
              backgroundImage: `url(${imagebg2})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center'
            }}></div>
          </Carousel.Item>
          <Carousel.Item>
            <div style={{
              height: '250px',
              backgroundImage: `url(${imagebg3})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center'
            }}></div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}

export default MobileCarousel;
