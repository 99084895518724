// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-upload-container {
    /* padding-left: 20px; */
    background-color: #f8f8f8;
    border-radius: 10px;
    width: 50%;
    /* margin-bottom: 10px; */
}

.upload-btn-chat {

    background: none;
    color: inherit;
    border: none;
    padding: 0;
    padding-right: 20px;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/components/FileUpload.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,yBAAyB;IACzB,mBAAmB;IACnB,UAAU;IACV,yBAAyB;AAC7B;;AAEA;;IAEI,gBAAgB;IAChB,cAAc;IACd,YAAY;IACZ,UAAU;IACV,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".file-upload-container {\n    /* padding-left: 20px; */\n    background-color: #f8f8f8;\n    border-radius: 10px;\n    width: 50%;\n    /* margin-bottom: 10px; */\n}\n\n.upload-btn-chat {\n\n    background: none;\n    color: inherit;\n    border: none;\n    padding: 0;\n    padding-right: 20px;\n    font: inherit;\n    cursor: pointer;\n    outline: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
