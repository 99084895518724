// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-title.text-center.mb-4 {
    font-family: "Roboto, sans-serif" !important;
    font-weight: 900 !important;
    font-size: 20px !important;
    transition: font-size 0.3s ease !important;
}

.card-title.text-center.mb-4:hover {
    font-size: 22px !important;
}
.input-password{
    position: relative;
}
.input-group-text {
    position: absolute;
    right: 2px;
    z-index: 10000;
    background: white;
    border: 0;
    top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/css/SignIn.css"],"names":[],"mappings":"AAEA;IACI,4CAA4C;IAC5C,2BAA2B;IAC3B,0BAA0B;IAC1B,0CAA0C;AAC9C;;AAEA;IACI,0BAA0B;AAC9B;AACA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,cAAc;IACd,iBAAiB;IACjB,SAAS;IACT,QAAQ;AACZ","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');\n\n.card-title.text-center.mb-4 {\n    font-family: \"Roboto, sans-serif\" !important;\n    font-weight: 900 !important;\n    font-size: 20px !important;\n    transition: font-size 0.3s ease !important;\n}\n\n.card-title.text-center.mb-4:hover {\n    font-size: 22px !important;\n}\n.input-password{\n    position: relative;\n}\n.input-group-text {\n    position: absolute;\n    right: 2px;\n    z-index: 10000;\n    background: white;\n    border: 0;\n    top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
