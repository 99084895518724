// import * as React from 'react';
import React, { useState, useEffect, Fragment } from "react";
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { WorkOutline } from "@mui/icons-material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const names = [
  "Remote", "Hybrid", "On-site"
];

function getStyles(name, locationType, theme) {
  return {
    fontWeight: locationType.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

export default function MultipleSelectChip({locationType, setLocationType}) {
  const theme = useTheme();
  useEffect(() => {
    console.log("personName changed to:", locationType);
  }, [locationType]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setLocationType(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    console.log("personName is ",locationType)
  };

  return (
    <Fragment>
      <InputLabel id="demo-multiple-chip-label">Location Type</InputLabel>
      <Select
        label="Location Type"
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={locationType}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Location Type" />}
        InputProps={{
          startAdornment: <WorkOutline color="action" sx={{ mr: 1 }} />,
        }}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {names.map((name) => (
          <MenuItem
            key={name}
            value={name}
            style={getStyles(name, locationType, theme)}
          >
            {name}
          </MenuItem>
        ))}
      </Select>
    </Fragment>
  );
}
