// export const serverHost = "http://localhost:8000";
// export const serverHost = "http://127.0.0.1:8000";
export const serverHost = "https://app.careergpt.io";

export const baseUrl = serverHost + "/api/v1";

export const FAST_API_PYTHON_SERVER_URI = serverHost;

export const COOKIE_HASH_KEY = "CareerGPT";

export const ANCHOR_API_ID = "sk-ec99cfffda22d2048c821eb7703b7ec2";

//test commit
